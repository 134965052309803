import styled from '@emotion/styled'
import { Room } from '@material-ui/icons'

export const LocationMarkerStyled = styled(Room)`
  font-size: 40px;
  transform: translate(-50%, -50%);
  &:hover {
    cursor: pointer;
    stroke: white !important;
    stroke-width: 1.5px !important;
    stroke-linejoin: round !important;
  }
`

export const LocationMarkerInfoContainer = styled.div`
  background-color: white;
  padding: 5px;
  width: 80px;
  border-radius: 12px;
  text-align: center;
  top: 20px;
  left: -40px;
  position: absolute;
  z-index: 100;
`
