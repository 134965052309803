import React, { useCallback, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/'
import { UnsplashImageCredit } from 'components'
import { useModal } from 'contexts/ModalContext'
import { motion } from 'framer-motion'
import { useQuery } from 'hooks/useQuery'
import { CreateTripCard } from 'pages/CreateTrip/layouts/CreateTripCard'
import { useAppDispatch } from 'store/hooks'
import { setGeoLocation } from 'store/slices/geoLocation'
import { pageVariants } from 'styles/sharedMotion'
import { isScreenSize } from 'utils/screenSzie'
import { fetchUnsplashImageData } from 'utils/unsplash'

import { style, Container } from './createTrip.style'
import { useHistory } from 'react-router'
const useStyles = makeStyles(() => style())

const CreateTrip = () => {
  const classes = useStyles()
  const query = useQuery()
  const [unsplashImage, setUnsplashImage] = useState<string>('')
  const [unsplashLink, setUnsplashLink] = useState<React.ReactElement>()
  const displayLoginOnAccountActivation = query.get('display_login')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const getUnsplashImage = useCallback(async () => {
    const { imageUrl, linkUrl } = await fetchUnsplashImageData()
    setUnsplashImage(imageUrl)
    setUnsplashLink(linkUrl)
  }, [])

  React.useEffect(() => {
    // dispatch(clearGeoLocation())
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const coordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        dispatch(setGeoLocation(coordinates))
      })
    } else {
      console.log('Geolocation is not available in your browser.')
    }
  }, [dispatch])

  const smallScreen = isScreenSize(650)

  useEffect(() => {
    getUnsplashImage()

    if (displayLoginOnAccountActivation) {
      history.push('signin')
    }
    // exclude handleLayout from deps as it should render once
    // if its in deps and user tries to click forgot pw or sign up in modal
    // then signin will just rerender and nothing will happen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUnsplashImage, displayLoginOnAccountActivation])

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      style={{ width: '100%' }}>
      <Container
        style={
          smallScreen
            ? {
                alignItems: 'start',
                paddingTop: '2rem',
                height: 'calc(100vh - 71px)'
              }
            : { height: 'calc(100vh - 71px)' }
        }
        src={unsplashImage}
        image={!smallScreen ? unsplashImage : undefined}
        classes={{ root: classes.root }}>
        <CreateTripCard />
      </Container>
      {!smallScreen && <UnsplashImageCredit unsplashLink={unsplashLink} />}
    </motion.div>
  )
}

export default CreateTrip
