/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'

import styled from '@emotion/styled'
import { TextField, InputAdornment, withStyles } from '@material-ui/core'
import { MTButton, MTTooltip } from 'components'
import { MTDialog } from 'components/MTDialog'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { useGridOrientation } from 'contexts/GridOrientationContext'
import { CONSTANTS } from 'core/constants'
import { Field } from 'formik'
import { HTTPError } from 'ky'
import {
  DeleteGridItemProps,
  NoteTripGroupItem,
  PollTripGroupItem,
  LocationTripGroupItem,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import { removeUserOwnedPlacesArray } from 'store/slices/locationSearchResults'
import { clear } from 'store/slices/popupEditGridItem'
import { updateTripGroup } from 'store/slices/tripGroupSlice'
import { deleteLocation } from 'store/slices/tripItemLocationsMap'
import { tripItemTypes } from 'utils/itemTypes'
import { sendNotification } from 'utils/toast'

import { remove as deleteTripLocationItem } from 'api/tripLocationItem'
import { remove as deleteTripNoteItem } from 'api/tripNoteItem'
import { remove as deleteTripPollItem } from 'api/tripPollItem'
import { TrashIcon, XIcon } from 'components/MTIcons'

import { ItemPopupFormikValues } from '../../layouts/ItemPopup/ItemPopup'
import { ColorPicker } from '../ColorPicker'

const FieldStyled = styled(Field)`
  width: 100%;
  margin-top: 8px;
  & > div {
    padding-bottom: 5px;
  }
`

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #AFAFAF',
    },
    '& .MuiInputBase-input': {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      display: 'inline-block',
      textOverflow: 'ellipsis',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '1px solid #AFAFAF',
    },
  },
})(TextField)

type Props = {
  handleChange: (key: string) => void
  formikKey: string
  autoFocus: boolean
  title: string
  setFieldValue: (key: string, val: string) => void
  onCloseModal: () => void
  values: ItemPopupFormikValues
  defaultColor: string | boolean
  tripItem: NoteTripGroupItem | PollTripGroupItem | LocationTripGroupItem
  canEdit: boolean
  tripShortUid: string
  onDeleteGridItem: ({ data, key }: DeleteGridItemProps) => void
}

const TitleInput = ({
  handleChange,
  formikKey,
  autoFocus,
  title,
  setFieldValue,
  onCloseModal,
  values,
  defaultColor,
  tripItem,
  canEdit,
  tripShortUid,
  onDeleteGridItem,
}: Props) => {
  const { setOpenDialog } = useDialog()

  const dispatch = useAppDispatch()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const { gridOrientation } = useGridOrientation()
  const { user } = useAppSelector(state => state.user)
  const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
  const coordinates = useAppSelector(state => state.geoLocation)

  /**
   * Delete trip item based on type.
   */
  const onHandleDeleteByType = async () => {
    switch (tripItem.type) {
      case 'location':
        onHandleDeleteLocationItem()
        break
      case 'note':
        onHandleDeleteNoteItem()
        break
      case 'poll':
        onHandleDeletePollItem()
        break
      default:
        break
    }
  }

  /**
   * Open delete modal for grid item
   */
  const onOpenItemDeletePopupModal = () => {
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }
    if (!canEdit)
      return sendNotification(`${CONSTANTS.DELETE_ITEM_DENY}`, 'error')
    setOpenDeleteDialog(true)
  }

  /**
   * Close delete dialogue
   */
  const onHandleCloseItemDeletePopupModal = () => {
    setOpenDeleteDialog(false)
  }

  /**
   * Cancel poll deletion
   */
  const onHandleCancel = () => {
    onHandleCloseItemDeletePopupModal()
  }

  /**
   * Handle location item deletion
   */
  const onHandleDeleteLocationItem = async () => {
    try {
      const res = await deleteTripLocationItem({
        user_uid: user.uid,
        uid: tripItem.uid,
        grid_orientation: gridOrientation,
        short_uid: tripShortUid,
        coordinates,
      })

      let tripGroupOfThatItem: any = {}
      if (tripGroups) {
        for (const tripGroup of tripGroups) {
          if (
            tripGroup &&
            tripGroup.tripItems &&
            tripGroup.tripItems.length > 0
          ) {
            for (const tripGroupItem of tripGroup.tripItems) {
              if (tripGroupItem.uid === tripItem.uid) {
                tripGroupOfThatItem = tripGroup
                break
              }
            }
          }
        }
      }
      if (
        tripGroupOfThatItem &&
        tripGroupOfThatItem.tripItems &&
        tripGroupOfThatItem.tripItems.length > 0
      ) {
        const updatedTripGroupItems = tripGroupOfThatItem.tripItems.filter(
          (groupItem: any) => {
            if (groupItem.uid !== tripItem.uid) {
              return tripItem
            }
          },
        )

        const newUpdatedGroup = {
          uid: tripGroupOfThatItem.uid,
          title: tripGroupOfThatItem.title,
          color: tripGroupOfThatItem.color,
          short_uid: tripGroupOfThatItem.short_uid,
          order: tripGroupOfThatItem.order,
          id: tripGroupOfThatItem.id,
          created_by: tripGroupOfThatItem.created_by,
          tripItems: updatedTripGroupItems,
        }

        await dispatch(updateTripGroup(newUpdatedGroup))
      }

      if (res) {
        sendNotification(
          `${CONSTANTS.TRIP_LOCATION_ITEM_DELETE_SUCCESS}`,
          'success',
        )
        onDeleteGridItem({
          data: res,
          key: tripItemTypes['TRIPS_LOCATIONS_ITEMS'],
        })
        dispatch(clear())
        onHandleCloseItemDeletePopupModal()
        dispatch(deleteLocation({ uid: tripItem.uid }))
        dispatch(
          removeUserOwnedPlacesArray({
            place_id: (tripItem as LocationTripGroupItem)?.google_place_id,
          }),
        )
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Handle poll deletion
   */
  const onHandleDeletePollItem = async () => {
    try {
      const res = await deleteTripPollItem({
        user_uid: user.uid,
        uid: tripItem.uid,
        grid_orientation: gridOrientation,
        short_uid: tripShortUid,
        coordinates,
      })

      let tripGroupOfThatItem: any = {}
      if (tripGroups) {
        for (const tripGroup of tripGroups) {
          if (
            tripGroup &&
            tripGroup.tripItems &&
            tripGroup.tripItems.length > 0
          ) {
            for (const tripGroupItem of tripGroup.tripItems) {
              if (tripGroupItem.uid === tripItem.uid) {
                tripGroupOfThatItem = tripGroup
                break
              }
            }
          }
        }
      }

      if (
        tripGroupOfThatItem &&
        tripGroupOfThatItem.tripItems &&
        tripGroupOfThatItem.tripItems.length > 0
      ) {
        const updatedTripGroupItems = tripGroupOfThatItem.tripItems.filter(
          (groupItem: any) => {
            if (groupItem.uid !== tripItem.uid) {
              return tripItem
            }
          },
        )

        const newUpdatedGroup = {
          uid: tripGroupOfThatItem.uid,
          title: tripGroupOfThatItem.title,
          color: tripGroupOfThatItem.color,
          short_uid: tripGroupOfThatItem.short_uid,
          order: tripGroupOfThatItem.order,
          id: tripGroupOfThatItem.id,
          created_by: tripGroupOfThatItem.created_by,
          tripItems: updatedTripGroupItems,
        }

        await dispatch(updateTripGroup(newUpdatedGroup))
      }

      if (res) {
        sendNotification(
          `${CONSTANTS.TRIP_POLL_ITEM_DELETE_SUCCESS}`,
          'success',
        )
        onDeleteGridItem({ data: res, key: tripItemTypes['TRIPS_POLLS_ITEMS'] })
        dispatch(clear())
        onHandleCloseItemDeletePopupModal()
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Handle note deletion
   */
  const onHandleDeleteNoteItem = async () => {
    try {
      const res = await deleteTripNoteItem({
        user_uid: user.uid,
        uid: tripItem.uid,
        short_uid: tripShortUid,
        grid_orientation: gridOrientation,
        coordinates,
      })

      let tripGroupOfThatItem: any = {}
      if (tripGroups) {
        for (const tripGroup of tripGroups) {
          if (
            tripGroup &&
            tripGroup.tripItems &&
            tripGroup.tripItems.length > 0
          ) {
            for (const tripGroupItem of tripGroup.tripItems) {
              if (tripGroupItem.uid === tripItem.uid) {
                tripGroupOfThatItem = tripGroup
                break
              }
            }
          }
        }
      }

      if (
        tripGroupOfThatItem &&
        tripGroupOfThatItem.tripItems &&
        tripGroupOfThatItem.tripItems.length > 0
      ) {
        const updatedTripGroupItems = tripGroupOfThatItem.tripItems.filter(
          (groupItem: any) => {
            if (groupItem.uid !== tripItem.uid) {
              return tripItem
            }
          },
        )

        const newUpdatedGroup = {
          uid: tripGroupOfThatItem.uid,
          title: tripGroupOfThatItem.title,
          color: tripGroupOfThatItem.color,
          short_uid: tripGroupOfThatItem.short_uid,
          order: tripGroupOfThatItem.order,
          id: tripGroupOfThatItem.id,
          created_by: tripGroupOfThatItem.created_by,
          tripItems: updatedTripGroupItems,
        }

        await dispatch(updateTripGroup(newUpdatedGroup))
      }

      if (res) {
        sendNotification(
          `${CONSTANTS.TRIP_NOTE_ITEM_DELETE_SUCCESS}`,
          'success',
        )
        dispatch(clear())
        onDeleteGridItem({ data: res, key: tripItemTypes['TRIPS_NOTES_ITEMS'] })
        onHandleCloseItemDeletePopupModal()
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  return (
    <FieldStyled
      disabled={!canEdit}
      component={CssTextField}
      autoFocus={autoFocus}
      value={title || ''}
      placeholder="Title"
      onChange={handleChange(`${formikKey}`)}
      inputProps={{
        maxLength: 50,
      }}
      helperText={
        values && values.title && values.title.length === 50
          ? 'Title cannot be longer than 100 characters!'
          : ''
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <ColorPicker
              canEdit={canEdit}
              setFieldValue={setFieldValue}
              defaultColor={defaultColor ? defaultColor : false}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputAdornment position="start">
              <MTTooltip title="Delete item">
                <MTButton
                  customIcon={<TrashIcon />}
                  onHandleClick={onOpenItemDeletePopupModal}
                  title=""
                  customStyles={{
                    padding: '3px',
                    boxShadow: 'none',
                    minWidth: '5px',
                    backgroundColor: 'white',
                    // marginRight: '-24px',
                    marginLeft: '10px',
                  }}
                />
              </MTTooltip>
              <MTDialog
                openDialog={openDeleteDialog}
                onCloseModal={onHandleCloseItemDeletePopupModal}
                onDeleteTripClickHandler={onHandleDeleteByType}
                onCancelClickHandler={onHandleCancel}
                itemToBeDeleted={CONSTANTS.CARD_TO_BE_DELETED}
                type={modalTypes.DELETE_ITEM}
              />
            </InputAdornment>
            <MTTooltip title="Close">
              <MTButton
                customIcon={<XIcon height='16' width='16' />}
                title=""
                customStyles={{
                  padding: '3px',
                  boxShadow: 'none',
                  minWidth: '5px',
                  backgroundColor: 'white',
                  marginLeft: '10px',
                }}
                onHandleClick={onCloseModal}
              />
            </MTTooltip>
          </div>
        ),
      }}
    />
  )
}

export default TitleInput
