export const styles = ({ colors }) => {
  return {
    viewalltripscontainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '2em 0 4em 0',
    },
    allTripsButton: {
      fontSize: '1.7rem',
      color: colors.primaryBlue,
      textTransform: 'capitalize',
    },
  }
}
