/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { FormControlLabel } from '@material-ui/core'
import { Select } from 'antd'

type Props = {
  changeFiltersOrientation: boolean
}

export const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
  flex-wrap: wrap;
  border-radius: 10px;
  padding: 16px;
`

export const FilterOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border: 1px solid #AFAFAF;
  border-radius: 5px;
  background-color: white;
  max-height: 32px;
  margin: 5px
`

export const FilterOptionText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-left: 8px;
  color: #3A3A3A;
`
export const CheckBoxInput = styled.input`
  height: 16px;
  width: 16px;
`

export const TagList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const style = ({ colors }: any) => {
  return {
    clearAllButton: {
      outline: 'none',
      border: 0,
      backgroundColor: 'inherit',
      textDecoration: 'underline',
      margin: '16px 0',
      color: '#0545BA',
      fontSize: '14px',
    },
  }
}
