import { request } from '../utils/request'

export const create = async data => {
    return await request({
        url: '/api/trip/create',
        method: 'post',
        data,
    })
}

export const update = async data => {
    return await request({
        url: `/api/trip/${data.uid}`,
        method: 'put',
        data: { uid: data.uid, ...data },
    })
}

export const find = async data => {
    return await request({
        url: '/api/trip/find',
        method: 'get',
        searchParams: data,
    })
}

export const findCount = async data => {
    return await request({
        url: '/api/trip/countAllTripsCreatedByUser',
        method: 'get',
        searchParams: data,
    })
}

export const findOneByShortUidOrUid = async({ short_uid, user_uid }) => {
    return request({
        url: '/api/trip/getTripForBoard/:short_uid',
        method: 'get',
        searchParams: { short_uid, user_uid },
    })
}

export const remove = async data => {
    return await request({
        url: '/api/trip/:short_uid',
        method: 'delete',
        data,
    })
}

export const getTripGroups = async uid => {
    return await request({
        url: '/api/trip-groups/:trip_uid',
        method: 'get',
        searchParams: { uid },
    })
}