import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { isNull } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { isScreenSize } from 'utils/screenSzie'

import { ModalContainer } from '../../layouts/Auth/layouts/AuthStyles'
import Footer from '../../layouts/Auth/layouts/Profile/SharedFooter'
import Downgrade from './content/Downgrade'
import Purchase from './content/Purchase'

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust as needed */
`

const CenteredTags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const Plan = () => {
  const history = useHistory()
  const { user } = useAppSelector(state => state.user)
  const [redirectTo, setRedirectTo] = useState('')
  const mediumScreen = isScreenSize(850)
  const smallScreen = isScreenSize(600)

  if (isNull(user?.uid)) {
    history.push('/create-trip')
    return null
  }

  useEffect(() => {
    if (window.location.pathname.includes('downgrade')) {
      setRedirectTo('downgrade')
    } else if (window.location.pathname.includes('purchase')) {
      setRedirectTo('purchase')
    }
  }, [])

  let style = {}
  if (redirectTo === 'purchase') {
    style = {
      width: '85%',
    }
  }
  if (redirectTo === 'downgrade') {
    style = {
      width: '60%',
    }
    if (mediumScreen) {
      style = {
        width: '85%',
      }
    }
    if (smallScreen) {
      style = {
        width: '100%',
      }
    }
  }

  return (
    <>
      <CenteredContainer>
        <CenteredTags>
          <ModalContainer style={style}>
            {redirectTo === 'purchase' && <Purchase />}
            {redirectTo === 'downgrade' && <Downgrade />}
            <Button
              style={{ textTransform: 'none', fontSize: '14px' }}
              variant="outlined"
              onClick={() => {
                sessionStorage.setItem('scrollToPayment', 'true')
                history.push('/profile')
              }}>
              <strong>Back to Profile</strong>
            </Button>
          </ModalContainer>
        </CenteredTags>
      </CenteredContainer>
      <Footer />
    </>
  )
}

export default Plan
