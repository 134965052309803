import styled from '@emotion/styled'
import { CardMedia } from '@material-ui/core'

export const Container = styled(CardMedia)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const style = () => {
  return {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }
}
