import React from 'react'

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: '#ECF3FF',
        textAlign: 'left',
        padding: '3% 0% 2% 10%',
      }}>
      <p
        style={{
          fontSize: '14px',
        }}>
        Have a question about the plans? Read our FAQ at{' '}
        <a
          style={{ textDecoration: 'underline', textDecorationColor: 'blue' }}
          target="_blank"
          href="http://www.mitravelapp.com/pricing"
          rel="noreferrer">
          {' '}
          www.mitravelapp.com/pricing
        </a>
      </p>
      <p
        style={{
          fontSize: '14px',
        }}>
        Need support? Contact us at{' '}
        <a
          style={{
            textDecoration: 'underline',
            textDecorationColor: 'blue',
          }}
          target="_blank"
          href="mailto:support@mitravelapp.com"
          rel="noreferrer">
          {' '}
          support@mitravelapp.com
        </a>
      </p>
    </footer>
  )
}

export default Footer
