/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, Fragment, useEffect } from 'react'

import styled from '@emotion/styled'
import { usePause } from 'contexts/PauseContext'
import { CONSTANTS } from 'core/constants'
import { isEmpty } from 'lodash'
import {
  UpdateGridItemProps,
  DeleteGridItemProps,
  StateGrid,
  TripGroup,
  AddGridItemProps,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { Form, FormGroup } from 'reactstrap'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import { addTripGroup } from 'store/slices/tripGroupSlice'
import { sendNotification } from 'utils/toast'

import { create as tripGroupCreate } from 'api/tripGroups'

import { PlanningBoardGridColumn } from '../PlanningBoardGridColumn'

const PlanningBoardGridStyled = styled.div`
  margin-top: 10px;
  height: 95%;
  display: flex;
  grid-column-start: 1;
  grid-row-start: 2;
  grid-column-gap: 10px;
  width: 100%;
`

type GridItemsProps = {
  bucket: string | undefined
  items: StateGrid
  tripShortUid: string
  onDeleteGridItem: ({ data, key }: DeleteGridItemProps) => void
  onUpdateGridItem: ({ data, key }: UpdateGridItemProps) => void
  onAddGridItem: (data: AddGridItemProps) => void
  disableDragOnGridItems: boolean
  groupUpdated: boolean
  tripMembersCount: number
  setGroupUpdated: (state: boolean) => void
  filteredGroups: Array<TripGroup>
}

type PlanningBoardGridProps = {
  mapSize: string
  bucket?: string
  tripShortUid: string
  onDeleteGridItem: ({ data, key }: DeleteGridItemProps) => void
  onUpdateGridItem: ({ data, key }: UpdateGridItemProps) => void
  onAddGridItem: (data: AddGridItemProps) => void
  disableDragOnGridItems: boolean
  setTripItems: (items: any) => void
  tripItems: StateGrid
  onUpdateColumnMap: (a: any, b: number) => void
  groupUpdated: boolean
  tripMembersCount: number
  setGroupUpdated: (state: boolean) => void
  filteredGroups: Array<TripGroup>
}


export const PlanningBoardItemsList = styled.div`
  flex-grow: 1;
  width: 220px;
  min-height: 100px;
`
export const PlanningBoardGridColumnContainer = styled.input`
  background: #e5e5e5;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.2rem;
  width: 213px;
  height: 100%;
  padding: 7px;
  overflow-x: visible;
  display: flex;
  flex-direction: column;
  flex: auto;
  white-space: nowrap;
  margin-top: 4px;
  margin-left: 4px;

  &:: placeholder {
    font: 14px/3 sans-serif;
    color: #8f8f8f;
    font-weight: bold;
  }
`

/**
 * Render grid items into their respective columns.
 * @param {Object} items items object
 */
const GridItems = ({
  items,
  tripMembersCount,
  bucket,
  tripShortUid,
  onDeleteGridItem,
  onUpdateGridItem,
  onAddGridItem,
  disableDragOnGridItems,
  filteredGroups,
}: GridItemsProps) => {
  const { pause, execPause } = usePause()
  const { newlyCreatedItem, newlyCreatedGroup } = useAppSelector(
    state => state.tripGroupSlice,
  )
  const coordinates = useAppSelector(state => state.geoLocation)

  // run this everytime grid orientation changes to hide the grid re-render lag
  execPause(500)
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(state => state.user)

  const createGroup = async (event: any) => {
    event.preventDefault()
    if (!event.target?.title?.value) {
      return
    }
    try {
      const response = await tripGroupCreate({
        title: event.target?.title?.value,
        short_uid: tripShortUid,
        color: 'blue',
        order: 1,
        created_by: user.uid,
        coordinates,
      })
      dispatch(addTripGroup(response.data))
      sendNotification(`${CONSTANTS.TRIP_GROUP_CREATE_SUCCESS}`, 'success')
    } catch (error) {
      console.log('Error: ', error)
    }
  }

  useEffect(() => {
    // HACK
  }, [newlyCreatedItem, newlyCreatedGroup])

  return (
    <Fragment>
      {!isEmpty(items) &&
        !pause &&
        filteredGroups &&
        filteredGroups.length > 0 &&
        filteredGroups.map((group: any, index: number) => {
          return (
            <>
              <PlanningBoardGridColumn
                index={index}
                tripGroup={group}
                isNewlyCreated={
                  newlyCreatedGroup && newlyCreatedGroup.uid === group?.uid
                    ? true
                    : false
                }
                tripMembersCount={tripMembersCount}
                key={group.id}
                column={{ id: index.toString() }}
                tripItems={group.tripItems}
                bucket={bucket}
                tripShortUid={tripShortUid}
                onDeleteGridItem={onDeleteGridItem}
                onUpdateGridItem={onUpdateGridItem}
                onAddGridItem={onAddGridItem}
                disableDragOnGridItems={disableDragOnGridItems}
              />
            </>
          )
        })}
      <Form inline onSubmit={event => createGroup(event)}>
        <FormGroup>
          <div style={{ display: 'flex' }}>
            <PlanningBoardGridColumnContainer
              type="text"
              name="title"
              placeholder="New Group "
            />
            <svg
              style={{
                height: '20px',
                width: '20px',
                position: 'relative',
                top: '8.5px',
                right: '25px',
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              fill="#C1C1C1">
              <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
            </svg>
          </div>

          <input type="submit" hidden />
        </FormGroup>
      </Form>
    </Fragment>
  )
}

/**
 * Memoize grid items to only update the item on that
 * is currently being dragged
 */
const MemoizedGridItems = memo(
  GridItems,
  (props, nextProps) => props.items === nextProps.items,
)

const PlanningBoardGrid = ({
  mapSize,
  bucket,
  groupUpdated,
  tripMembersCount,
  setGroupUpdated,
  tripShortUid,
  onDeleteGridItem,
  onUpdateGridItem,
  onAddGridItem,
  disableDragOnGridItems,
  tripItems,
  filteredGroups,
}: PlanningBoardGridProps) => {
  const { pause } = usePause()
  return (
    <PlanningBoardGridStyled
      style={{
        gridColumnEnd: mapSize === 'S' ? '-1' : 'span 2',
        paddingRight: mapSize === 'L' ? '20px' : '0',
        opacity: pause ? 0 : 1,
        transition: 'all .3s ease',
      }}>
      {!isEmpty(tripItems) && (
        <MemoizedGridItems
          bucket={bucket}
          setGroupUpdated={setGroupUpdated}
          tripMembersCount={tripMembersCount}
          groupUpdated={groupUpdated}
          items={tripItems}
          filteredGroups={filteredGroups}
          tripShortUid={tripShortUid}
          onDeleteGridItem={onDeleteGridItem}
          onUpdateGridItem={onUpdateGridItem}
          onAddGridItem={onAddGridItem}
          disableDragOnGridItems={disableDragOnGridItems}
        />
      )}
    </PlanningBoardGridStyled>
  )
}

export default PlanningBoardGrid
