import React from 'react'

import { cssVariables } from 'theme'

type Props = {
  fill?: string
}
const PenIcon = ({ fill }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4564 4.80739C13.7443 4.51955 13.9026 4.13729 13.9026 3.73066C13.9026 3.32403 13.7443 2.94177 13.4564 2.65393L12.2487 1.44623C11.9609 1.15839 11.5786 1 11.172 1C10.7654 1 10.3831 1.15839 10.096 1.44546L2.27553 9.24168C2.09914 9.41753 2 9.65635 2 9.90542V11.9411C2 12.4587 2.4196 12.8783 2.9372 12.8783H4.97305C5.22111 12.8783 5.45905 12.7799 5.63472 12.6048L13.4564 4.80739ZM10.9071 2.78703C11.0535 2.64109 11.2904 2.6412 11.4367 2.78728L12.1145 3.46431C12.2613 3.61088 12.2611 3.84876 12.1142 3.99514L11.4347 4.67208C11.2883 4.81797 11.0513 4.81779 10.9051 4.67166L10.2277 3.99463C10.081 3.84808 10.0812 3.61033 10.228 3.46397L10.9071 2.78703ZM3.89783 11.3553C3.69079 11.3553 3.52295 11.1875 3.52295 10.9804V10.304C3.52295 10.2044 3.56261 10.1089 3.63317 10.0385L8.61867 5.06859C8.76514 4.92258 9.00218 4.92277 9.14842 5.06901L9.82596 5.74655C9.97252 5.89311 9.97233 6.13079 9.82554 6.27712L4.84115 11.2459C4.77088 11.316 4.6757 11.3553 4.57648 11.3553H3.89783ZM2 15.1627C2 14.7422 2.34092 14.4012 2.76148 14.4012H13.4222C13.8427 14.4012 14.1836 14.7422 14.1836 15.1627V15.1627C14.1836 15.5833 13.8427 15.9242 13.4222 15.9242H2.76148C2.34093 15.9242 2 15.5833 2 15.1627V15.1627Z"
        fill={fill || cssVariables.colors.alternativeDarkGrey2}
      />
    </svg>
  )
}

export default PenIcon
