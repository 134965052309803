import React from 'react'

import { cssVariables } from 'theme'

type IconProp = {
  stroke?: string
}

const DuplicateIcon = ({ stroke }: IconProp) => {
  return (
<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M4.8 5.3V2.1C4.8 1.22 5.52 0.5 6.4 0.5H14.4C14.8243 0.5 15.2313 0.668571 15.5314 0.968629C15.8314 1.26869 16 1.67565 16 2.1V10.1C16 10.5243 15.8314 10.9313 15.5314 11.2314C15.2313 11.5314 14.8243 11.7 14.4 11.7H11.2V14.9C11.2 15.3243 11.0314 15.7313 10.7314 16.0314C10.4313 16.3314 10.0243 16.5 9.6 16.5H1.6C1.17565 16.5 0.768687 16.3314 0.468629 16.0314C0.168571 15.7313 0 15.3243 0 14.9V6.9C0 6.02 0.72 5.3 1.6 5.3H4.8ZM6.4 5.3H9.6C10.0243 5.3 10.4313 5.46857 10.7314 5.76863C11.0314 6.06869 11.2 6.47565 11.2 6.9V10.1H14.4V2.1H6.4V5.3ZM1.6 6.9V14.9H9.6V6.9H1.6Z"
    fill="#686868"
  />
</svg>
  )
}

export default DuplicateIcon
