import React from 'react'

import { Container, ListItem, LastItem } from './previewTags.style'

type Tag = {
  title: string
}

type Props = {
  tags: Array<Tag>
}
/*
 * Preview Tags component, that display tags on the tripItem cards in PB
 */
const PreviewTags = ({ tags }: Props) => {
  const remainingLength = tags.length - 2

  return (
    <Container>
      {tags &&
        tags.length > 0 &&
        tags.map((tag, idx) => {
          if (idx < 2) {
            return (
              <ListItem key={idx}>
                <span>{tag.title}</span>
              </ListItem>
            )
          }
        })}
      {remainingLength >= 1 && <LastItem>+{remainingLength}</LastItem>}
    </Container>
  )
}

export default PreviewTags
