/* eslint-disable no-unused-vars */
import React from 'react'

import { CardContent } from '@material-ui/core'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { isScreenSize } from 'utils/screenSzie'

import { ContainerStyled } from './createTripCard.style'
import CreateTripForm from './CreateTripForm'

export type LatLng = [number, number]

export type GoogleSearchQuery = {
  destination: string
  place_id: string
  lat: number
  lng: number
  sw_bounds: LatLng
  ne_bounds: LatLng
}

const CreateTripCard = () => {
  const smallScreen = isScreenSize(650)

  return !smallScreen ? (
    <ContainerStyled>
      <CardContent>
        <CreateTripForm />
      </CardContent>
    </ContainerStyled>
  ) : (
    <Box
      sx={{
        flexGrow: 1,
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
      }}>
      <Grid container style={{ justifyContent: 'center' }} spacing={12}>
        <Grid item xs={10}>
          <CreateTripForm />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CreateTripCard
