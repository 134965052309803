import styled from '@emotion/styled'
import { Menu, TextField, withStyles } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { Field, Form } from 'formik'

export const ParentContainer = styled.div`
  //position: absolute;
  background-color: white;
  //margin-left: 32px;
  //margin-top: 3rem;
  margin-bottom: 2rem;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0px 5px 11px -1px rgba(0, 0, 0, 0.1);

  @media (min-width: 1650px) {
    width: 80%;
  }
`

export const HeroTop = styled.div`
  // display: flex;
  // flex-direction: row;
`

export const Title = styled(Field)`
  font-size: 2.5em;
  border: 1px solid white;
  width: 100%;
  &:hover {
    border: 1px solid white;
  }
  &:focus {
    border: 1px solid white;
    outline: none;
  }
  &:disabled {
    background-color: white;
  }
  margin-bottom: 1rem;

  input {
    text-overflow: ellipsis;
  }
`

// export const HeroMenuButton = styled(MoreHorizIcon)`
//   float: right;
//   margin-top: 0px;
//   &:hover {
//     cursor: pointer;
//   }
//   height: 2.2rem;
//   width: 2.2rem;
// `

export const HeroMenuButton = styled.div`
  float: right;
  margin-top: -3px;
  &:hover {
    cursor: pointer;
  }
  width: 2.5rem;
`
export const Icon = styled(MoreHorizIcon)`
  font-size: 26px;
  line-height: 1;
`

export const MenuStyled = styled(Menu)`
  font-size: 1.5rem;
  border-radius: 12px;
  margin-top: 15px;
  margin-right: 5px;
`

export const Container = styled.div`
  // display: flex;
  // justify-content: space-between;
`

export const TripDates = styled.div`
  // display: flex;
  // align-items: center;
`

export const DatesForm = styled(Form)`
  display: contents;
  align-items: center;
`

export const DateContainer = styled.div`
  // display: flex;
  // align-items: center;
`

export const Span = styled.span`
  font-size: 2em;
  margin: 0 1rem;
`

export const MembersList = styled.div`
  margin-left: 2em;
  margin-right: 0.5rem;
  float: right;
`

export const CustomTextField = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #AFAFAF',
    },
    '& .MuiInputBase-input': {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '1px solid #AFAFAF',
    },
  },
})(TextField)
