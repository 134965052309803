import React, { memo } from 'react'

import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MTDialog } from 'components/MTDialog'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { CONSTANTS } from 'core/constants'
import { useWindowSize } from 'hooks/useWindowSize'
import mixpanel from 'mixpanel-browser'
import { Toaster } from 'react-hot-toast'
import { hotjar } from 'react-hotjar'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { setGeoLocation } from 'store/slices/geoLocation'

import { AlertCircleIcon } from 'components/MTIcons'
import { Router, routes } from 'router'

import { styles, PageWrapper } from './app.styles'
import { NavBar } from './layouts/NavBar'

const useStyles = makeStyles(() => styles())

const MemoizedRouter = memo(Router)

const App = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { width } = useWindowSize()
  const { openDialog, setOpenDialog } = useDialog()
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)
  const alreadyExisitingTrips = useAppSelector(
    state => state.userPlanSlice.userTripsCount,
  )
  const history = useHistory()
  const isDashboardURL = window.location.pathname === '/'

  // const mixpenel_token = '2ee640ec4b9dbfda317d030b4e4681b1' //prod
  const mixpenel_token = 'dda72b03dc7ed5c62e5ea4054465e249' //stage
  // const mixpenel_token = 'a2b87358c08109c40186861cc2edf1f9' //local

  console.log('Env is', process.env.NODE_ENV)

  mixpanel.init(mixpenel_token, {
    debug: true,
    // track_pageview: true,
    persistence: 'localStorage',
    ignore_dnt: true,
  })
  // mixpanel.track_pageview()

  if (process.env.REACT_APP_NODE_ENV === 'production')
    hotjar.initialize(2625773, 6)

  const determinePaddingWidth = () => {
    let paddingWidth = ''
    if (width && width >= 2000) {
      paddingWidth = '20rem'
    }
    return `0px ${paddingWidth}`
  }

  const queryParams = {
    subscribe: 'true',
  }

  const queryString = new URLSearchParams(queryParams).toString()

  React.useEffect(() => {
    // dispatch(clearGeoLocation())
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const coordinates = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        dispatch(setGeoLocation(coordinates))
      })
    } else {
      console.log('Geolocation is not available in your browser.')
    }
  }, [dispatch])

  const appContent = (
    <>
      <NavBar />
      {userPlan.plan?.name === CONSTANTS.FREE_PLAN &&
        alreadyExisitingTrips > 0 &&
        isDashboardURL && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#FEF0F2',
              textAlign: 'center',
              padding: '10px 0',
              boxSizing: 'border-box',
            }}>
            <AlertCircleIcon stroke="red" />
            <div style={{ fontSize: '14px', margin: '0 10px' }}>
              <span>
                While your existing trips can still be edited, all new trips you
                want to create will require you to{' '}
              </span>
              <span
                style={{
                  textDecoration: 'underline',
                  color: '#0d6efd',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  sessionStorage.setItem('scrollToPayment', 'true')
                  history.push('/profile')
                }}>
                {' '}
                upgrade your plan.
              </span>
            </div>
          </div>
        )}
      <Container classes={{ root: classes.contentRoot }} maxWidth={false}>
        <PageWrapper
          style={{
            margin: location.pathname.includes('workspace')
              ? determinePaddingWidth()
              : '0rem',
          }}>
          <MemoizedRouter routes={routes} />
          <Toaster position="bottom-center" />
          {openDialog.show && openDialog.type === modalTypes.ACTIVATE_ACCOUNT && (
            <MTDialog
              openDialog={openDialog.show}
              onCloseModal={() =>
                setOpenDialog({
                  show: false,
                  type: '',
                })
              }
              type={modalTypes.ACTIVATE_ACCOUNT}
              customStyle={{
                minHeight: '0px',
              }}
            />
          )}
        </PageWrapper>
      </Container>
    </>
  )

  return (
    <Container maxWidth={false} classes={{ root: classes.root }}>
      {appContent}
    </Container>
  )
}

export default App
