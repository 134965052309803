import { CSSProperties } from 'react'
import styled from '@emotion/styled'


interface Styles {
  dropdownContainer: CSSProperties
  dropdownButton: CSSProperties
  dropdownMenu: CSSProperties
  dropdownItem: CSSProperties
  dropdownItemInput: CSSProperties
  colorBox: CSSProperties
  [key: string]: CSSProperties // Index signature for dynamic keys
}


export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
`


export const style = (): Styles => {
  return {
    dropdownContainer: {
      position: 'relative',
      display: 'inline-block',
      margin: '5px',
    },
    dropdownButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px',
      cursor: 'pointer',
      border: '1px solid #AFAFAF',
      borderRadius: '5px',
      backgroundColor: 'white',
      maxHeight: '32px',
    },
    buttonText: {
      fontSize: '14px',
      margin: '0 8px',
      color: '#3A3A3A'
    },
    dropdownText: {
      fontSize: '14px',
      margin: 0,
      padding: 0
    },
    dropdownMenu: {
      position: 'absolute',
      width:'200px',
      top: '100%',
      left: 0,
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      padding: '10px',
      zIndex: 1000,
    },
    dropdownItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 0',
    },
    dropdownItemInput: {
      marginRight: '0',
      height: '16px',
      width: '16px'
    },
    colorBox: {
      width: '16px',
      height: '16px',
      margin: '3px 4px 3px 8px',
      border: '1px solid #ccc',
      borderRadius: '2px',
    },
    rotate: {
      transform: 'rotate(90deg)',
      transformOrigin: 'center' },
  }
}
