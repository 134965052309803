import React, { Fragment } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { CONSTANTS } from 'core/constants'
import PropTypes from 'prop-types'
import { isScreenSize } from 'utils/screenSzie'

import { DashboardCard as Card } from '../Card'
import { DashboardCarousel as Carousel } from '../Carousel'
import { styles } from './bodyContainerWithTrips.styles'

const useStyles = makeStyles(theme => styles(theme))

const BodyContainerWithTrips = ({ trips, getUserTrips }) => {
  const classes = useStyles()

  let smallScreen = isScreenSize(850)

  return (
    <Fragment>
      {trips && trips.length > 0 && (
        <div className={classes.bodyTitle}>
          <Typography variant="h2">
            <b>{CONSTANTS.DASHBOARD_TITLE}</b>
          </Typography>
        </div>
      )}
      <div className={classes.bodyContent}>
        {trips && trips[0] && (
          <Card trip={trips[0]} firstTrip={true} getUserTrips={getUserTrips} />
        )}
      </div>
      {trips &&
        trips.length > 1 &&
        (!smallScreen ? (
          <Carousel
            trips={trips.slice(1, trips.length)}
            getUserTrips={getUserTrips}
          />
        ) : (
          trips.map(trip => {
            return (
              <Card
                key={trip.uid}
                trip={trip}
                firstTrip={true}
                getUserTrips={getUserTrips}
              />
            )
          })
        ))}
    </Fragment>
  )
}

BodyContainerWithTrips.propTypes = {
  trips: PropTypes.array,
}

export default BodyContainerWithTrips
