import React, {useRef, useEffect, useCallback} from 'react'

import { css } from '@emotion/css'
import styled from '@emotion/styled'
import ReactQuill, { Quill as Q2 } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import debounce from 'lodash.debounce'

const ReactQuillStyled = styled(ReactQuill)`
  .ql-tooltip{
    border-radius: 10px;
    background-color: #424242;
    color: white;
    z-index: 1;
  }
  .ql-tooltip > input {
    background-color: #424242;
    border:0 !important;
    outline: none;
    color: white;
  }
  .ql-editor{
    max-height: 10rem;
  }
  .ql-snow .ql-tooltip a{
    color: white;
  }

  .ql-preview{
    text-decoration: underline !important
  }

  .ql-tooltip > input:focus{
    border: 0 !important;
    outline: none;
  }

  & > div:nth-of-type(1) {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  & > div:nth-of-type(2) {
    min-height: 100px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

type Props = {
  setFieldValue: (key: string, val: string) => void
  formikKey: string
  value: string
  canEdit: boolean
  placeholder?: string
}

const formats = ['bold', 'italic', 'underline', 'strike', 'link', 'list', 'bullet']

const Quill = ({
  setFieldValue,
  formikKey,
  value,
  canEdit,
  placeholder,
}: Props) => {

  const quillRef = useRef<Q2>(null)
  const isUpdating = useRef<boolean>(false)

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],       // Ensure 'link' option is present in the toolbar
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ]
    }
    }

  const handleTextChange = useCallback(() => {
    if (!isUpdating.current) {
      if(quillRef?.current){
        handleAutoHyperlinking(quillRef.current!.getEditor())
      }
    }
  }, [])

  const debouncedHandleTextChange = useCallback(debounce(handleTextChange, 100), [handleTextChange])

  useEffect(() => {
    const quill = quillRef.current!.getEditor()
    if (quill) {
      quill.on('text-change', debouncedHandleTextChange)
    }
    // Cleanup function to remove the listener
    return () => {
      if (quill) {
        quill.off('text-change', debouncedHandleTextChange)
      }
    }
  }, [debouncedHandleTextChange])


  interface quillCustomType{
    getSelection: () => string
    formatText: (arg_0: number, arg_1: number, arg2: string, arg: string) => void
    getContents: () => string
    setContents: (arg_0:string) => void
  }

  const handleAutoHyperlinking = (editor: Q2) => {
    if (!editor) return
    const currentSelection = editor.getSelection()
    isUpdating.current = true // Mark as updating
    const text = editor.getText()
    const urlRegex = /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})?(?::\d+)?(?:\/[^\s<>\[\]'"]*)?/gi
    let match
    while ((match = urlRegex.exec(text)) !== null) {
      let url = match[0].trim() // Remove leading and trailing spaces
      const { index } = match
      // Check if URL already has a protocol
      if (!/^(?:f|ht)tps?:\/\//i.test(url)) {
        // If not, prepend http://
        url = `http://${url}`
      }
      editor.formatText(index, match[0].length, 'link', url)
    }
    isUpdating.current = false // Reset updating flag

    // add protols if the manual tooltip urls are missing those
    const quillDelta = editor.getContents()
    if(quillDelta?.ops){
      for(const i of quillDelta?.ops){
        if(i?.attributes?.link){
          if(!i.attributes.link.startsWith('http')){
            i.attributes.link = 'http://' + i.attributes.link
            editor.setContents(quillDelta)
            if (currentSelection) {
              editor.setSelection(currentSelection)
            }
          }
        }
    }
  }
}

  return (
    <ReactQuillStyled
      ref={quillRef}
      theme="snow"
      value={value}
      onChange={val => setFieldValue(formikKey, val)}
      modules={modules}
      formats={formats}
      placeholder={placeholder ?? 'Create a note...'}
      readOnly={!canEdit}
      className={css({
        '& > div:nth-of-type(2)': {
          backgroundColor: !canEdit ? '#FAFAFA' : 'inherit',
        },
      })}
    />
  )
}

export default Quill
