import React from 'react'

import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { isScreenSize } from 'utils/screenSzie'

import { DashboardCard as Card } from '../Card'
import { styles, responsive } from './carousel.styles'

const useStyles = makeStyles(() => styles())

const DashboardCarousel = ({ trips, getUserTrips }) => {
  const classes = useStyles()
  const matches = isScreenSize(700)

  const tripsCard = trips.map(trip => {
    return (
      <Card trip={trip} carousel key={trip.uid} getUserTrips={getUserTrips} />
    )
  })
  return (
    <div className={classes.containerRoot}>
      <Carousel responsive={responsive} showDots={!matches}>
        {tripsCard.map((tripCard, ind) => (
          <div className={classes.carouselItem} key={ind}>
            {tripCard}
          </div>
        ))}
      </Carousel>
    </div>
  )
}

DashboardCarousel.propTypes = {
  trips: PropTypes.array,
}

export default DashboardCarousel
