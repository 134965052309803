import React, {useState} from 'react'

import { MTTooltip } from 'components'
import { isNull } from 'lodash'
import { useAppSelector } from 'store/hooks'
import { PollOptionVoters } from '../../layouts/ItemPopup/layouts/PollItemPopup/layouts/PollOptionVoters'
import { RemovePollOptions } from '../../layouts/ItemPopup/layouts/PollItemPopup/layouts/RemovePollOptions'

type User = {
  firstName: string
  lastName: string
  uid: string
  email: string
}

type Option = {
  title: string
  uid: string
  users: Array<User>
}

type Props = {
  options: Array<Option>
  onHandleOptionVote: (option: Option) => void
  tripMembersCount: number
  onHandleHover?: (option: Option) => void
  concatTitle?: boolean
  children?: React.ReactNode
  onOpenItemDeletePopupModal?: (option: Option) => void
  fullView?: boolean
}

const PollOptions = ({
  options,
  onHandleOptionVote,
  tripMembersCount,
  onHandleHover,
  concatTitle = false,
  children,
  onOpenItemDeletePopupModal,
  fullView = false,
}: Props) => {
  const { user } = useAppSelector(state => state.user)
  const { data: tripRoles } = useAppSelector(state => state.tripUserRoleSlice)
  const [optionHovered, setOptionHovered] = useState<string | null>(null)



  /**
   * Calculate how long the width of each option should be.
   * The formula is -> (# of votes / # of trip members) * 100.
   *
   * @param { Number } votes Number of option votes
   * @returns Number of votes per option
   */
  const calculatePercent = (votes: number) => {
    if (votes === 0 && tripMembersCount === 0) {
      return '0%'
    }
    const res = (votes / tripMembersCount) * 100
    return `${res}%`
  }

  /**
   * Handle hovering on an option
   * @param { Option } option poll option object
   */
  const showDeleteOnItemHover = (option: Option | null) => {
    option?.uid ? setOptionHovered(option?.uid) : setOptionHovered(null)
  }

  return (
    <>
      <article
        style={{ width: '100%' }}
        className={'animate__animated animate__fadeIn animate__faster poll'}>
        <ul className="answers">
          {!isNull(user) &&
            options &&
            options.length > 0 &&
            options.map(option => (
              <li
                key={option.uid}
                onMouseEnter={() => showDeleteOnItemHover(option)}
                onMouseLeave={() => showDeleteOnItemHover(null)}
                style={{
                  height: '38px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <div
                  style={{
                    border: '1px solid #DFDEDE',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: (fullView && !user?.is_tentative && !(tripRoles[0]?.role !== 'VIEWER')) ? '95%' : '100%',
                    height: '32px',
                  }}>
                  <div
                    className={
                      'animate__animated animate__fadeIn animate__faster result'
                    }
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={() => onHandleOptionVote(option)}
                    onMouseEnter={() => onHandleHover && onHandleHover(option)}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '12px',
                        padding: '0 12px',
                        background:
                          'linear-gradient(to right, #E5E5E5 ' +
                          calculatePercent(option.users.length) +
                          ', transparent ' +
                          calculatePercent(option.users.length) +
                          ')',
                        height: '100%',
                        alignItems: 'center',
                        transition: 'all 0.5s ease',
                        borderRadius: '10px',
                      }}>
                      <MTTooltip
                        title={
                          option.users.some(voter => voter.uid === user.uid)
                            ? 'Unvote'
                            : 'Vote'
                        }>
                        <div>
                          <span>
                            {concatTitle && option.title.length > 20
                              ? option.title.substring(0, 15).concat('..')
                              : option.title}
                          </span>
                        </div>
                      </MTTooltip>
                      {fullView && <PollOptionVoters options={option.users} />}
                    </div>
                  </div>
                </div>
                {fullView &&
                  !user?.is_tentative &&
                  tripRoles[0]?.role !== 'VIEWER' && (
                    <div
                      style={{
                        width: '4%',
                        marginLeft: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <RemovePollOptions
                        options={option}
                        onOpenItemDeletePopupModal={() => onOpenItemDeletePopupModal!(option)}
                        optionHovered={optionHovered}
                      />
                    </div>
                  )}
              </li>
            ))}
        </ul>
      </article>
    </>
  )
}

export default PollOptions
