import React, { useState, useEffect } from 'react'

import styled from '@emotion/styled'
import ControlPointIcon from '@material-ui/icons/ControlPoint'
import { modalTypes } from 'components/MTDialog/types'
import { Stars } from 'components/Stars'
import { useDialog } from 'contexts/DialogContext'
import { useGridOrientation } from 'contexts/GridOrientationContext'
import { CONSTANTS } from 'core/constants'
import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import {
  UserOwnedGooglePlacesForMap,
  TripGroup,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { Carousel } from 'react-responsive-carousel'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import {
  setMapInfoForSearchGridItem,
  updateUserOwnedPlacesArray,
  // showLocationItemMapInfo,
  // hideLocationItemMapInfo,
  setMapCenter,
  setMapZoom,
  updatePlacesArray,
  setScrolltoSearchedLocationOnMap,
} from 'store/slices/locationSearchResults'
import { addItemInTripGroup } from 'store/slices/tripGroupSlice'
import { tabletMobileScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { create } from 'api/tripLocationItem'

import CreateGroupModal from '../../../CreateGroupModal/modal'
import {
  Container,
  ImageContainer,
  Media,
  GroupButtonStyled,
  Body,
  Title,
} from './gridItem.style'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const GroupDropdown = styled.div`
  //margin-left: auto;
  z-index: 100;
  border-radius: 7px 0px 0px 7px;
  font-size: 1.4rem;
  //width: 100%;
  position: absolute;
  height: 35px;
  margin-top: 9px;
  //margin-right: 0px;
  border: none;
  &:focus {
    outline-width: 0;
  }
`

type LatDifference = {
  [k: number]: string
}

type Props = {
  key: number
  place: UserOwnedGooglePlacesForMap
  tripShortUid: string
  tripEndDate: string
  tripStartDate: string
  searchedTitle: string
  tripName: string
  tripDestination: string
}

type PlaceDataType = {
  business_status?: string
  color?: string
  formatted_address?: string
  html_attributions?: string[]
  icon?: string
  icon_background_color?: string
  icon_mask_base_uri?: string
  lat?: number
  lng?: number
  name?: string
  opening_hours?: Record<string, never> // Assuming opening_hours is an empty object
  photos?: string[]
  placeIsPartOfTrip?: boolean
  place_id?: string
  plus_code?: {
    compound_code?: string
    global_code?: string
  }
  rating?: number
  reference?: string
  types?: string[]
  user_ratings_total?: number
}

const GridItem = ({
  place,
  tripShortUid,
  tripEndDate,
  tripStartDate,
  searchedTitle,
  tripName,
  tripDestination,
}: Props) => {
  const dispatch = useAppDispatch()
  const { gridOrientation } = useGridOrientation()
  const { setOpenDialog } = useDialog()
  const [active, setActive] = useState(false)

  const { user } = useAppSelector(state => state.user)
  const { tripGroups } = useAppSelector(state => state.tripGroupSlice)
  const { userOwnedPlacesArray, places, mapZoom } = useAppSelector(
    state => state.locationSearchResults,
  )
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)
  const { data: tripRoles } = useAppSelector(state => state.tripUserRoleSlice)
  const [userOwnedPlaces, setUserOwnedPlaces] = useState(false)
  const [showAddButtons, setShowAddButton] = useState(false)
  const [tripGroup, setTripGroup] = useState<TripGroup | null>()
  const [selectedGroupTitle, setSelectedGroupTitle] = useState('')
  const [userOwnedPlaceGroup, setUserOwnedPlaceGroup] = useState('')
  const tabletMobileView = tabletMobileScreenSize()
  const [platform, setPlatform] = useState('Web')
  const coordinates = useAppSelector(state => state.geoLocation)

  useEffect(() => {
    const { userAgent } = navigator

    if (userAgent.includes('Android')) {
      setPlatform('Android')
    } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      setPlatform('iOS')
    } else {
      setPlatform('Web')
    }
  }, [])

  let inlineStyle = {}
  if (tabletMobileView) {
    inlineStyle = {
      width: '150px',
      flexGrow: '1',
      maxWidth: 'unset',
    }
  }

  /**
   * Add searched item to planning board
   */
  const onAddToBoard = async () => {
    if (!tripGroup) {
      sendNotification(`${CONSTANTS.TRIP_LOCATION_ITEM_SELECT_ALERT}`, 'error')
      return
    }
    if (user.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    const allGroupItems = tripGroup?.tripItems
    const lastItemsOrder =
      allGroupItems && allGroupItems.length > 0 ? allGroupItems?.length + 1 : 1

    let data = {
      google_place_id: place.place_id,
      destination: place.name,
      created_by: user.uid,
      user_uid: user.uid,
      short_uid: tripShortUid,
      title: place.name,
      google_place_title: place.name,
      lat: place.lat,
      lng: place.lng,
      grid_orientation: gridOrientation,
      is_tentative: !!user?.is_tentative,
      trip_group: tripGroup.uid,
      order: lastItemsOrder + 1,
      coordinates,
    }

    const currentDate = new Date()

    const currentDateBeforeTripStartDate =
      moment(currentDate).isBefore(tripStartDate)
    const currentDateAfterTripStartDate =
      moment(currentDate).isAfter(tripStartDate)

    let dates = {}
    if (currentDateBeforeTripStartDate) {
      dates = {
        start_date: new Date(tripStartDate),
        end_date: new Date(tripEndDate),
        start_time: new Date(tripStartDate),
        end_time: new Date(tripEndDate),
      }
    }

    if (currentDateAfterTripStartDate) {
      dates = {
        start_date: new Date(currentDate.setHours(0, 0, 0, 0)),
        end_date: new Date(currentDate.setHours(0, 0, 0, 0)),
        start_time: new Date(currentDate.setHours(0, 0, 0, 0)),
        end_time: new Date(currentDate.setHours(0, 0, 0, 0)),
      }
    }

    data = { ...data, ...dates }

    try {
      const res = await create(data)
      sendNotification(CONSTANTS.TRIP_ITEM_LOCATION, 'success')
      dispatch(
        updateUserOwnedPlacesArray([
          {
            place_id: place.place_id,
            color: '#4184FF',
            trip_group_uid: tripGroup.uid,
          },
        ]),
      )
      const payload = {
        groupUid: tripGroup,
        tripItem: res,
      }
      await dispatch(addItemInTripGroup(payload))
      dispatch(updatePlacesArray({ place_id: place.place_id }))
    } catch (error) {
      console.log('Error', error)
      sendNotification(CONSTANTS.ERROR_GENERAL, 'error')
    }
  }

  /**
   * When user's mouse enters the bounds of a location grid item,
   * show the marker place title in the map.
   */
  // const onHoverTripItem = () => {
  //   dispatch(
  //     showLocationItemMapInfo({
  //       placeId: place.place_id,
  //     }),
  //   )
  // }

  /**
   * When user's mouse leaves the bounds of a location grid item,
   * hide the marker place title in the map.
   */
  // const onUnHoverTripItem = () => {
  //   dispatch(
  //     hideLocationItemMapInfo({
  //       placeId: place.place_id,
  //     }),
  //   )
  // }

  /**
   * Display map info when searched item is clicked.
   */
  const onShowMapInfo = () => {
    dispatch(setScrolltoSearchedLocationOnMap(true))
    dispatch(setMapInfoForSearchGridItem({ placeId: place.place_id }))
    const placeObject = places.filter(pl => pl.place_id === place.place_id)

    mixpanel.track('Location Clicked', {
      'Location Item Title': placeObject[0]?.name,
      'Trip Name': tripName,
      'Trip Destination': tripDestination,
      'Search Title': searchedTitle,
      Platform: platform,
      $latitude: coordinates?.latitude,
      $longitude: coordinates?.longitude,
    })
    const latDifference: LatDifference = {
      13: '0.02',
      14: '0.008',
      15: '0.005',
      16: '0.003',
      17: '0.001',
    }
    dispatch(
      setMapCenter({
        lat: placeObject[0]?.lat - parseFloat(latDifference[mapZoom]),
        lng: placeObject[0]?.lng as number,
      }),
    )
    dispatch(setMapZoom(mapZoom >= 15 ? mapZoom : 15))
  }

  useEffect(() => {
    const locationPartOfUsersBoard = userOwnedPlacesArray.some(
      i => i.place_id === place.place_id,
    )
    setUserOwnedPlaces(locationPartOfUsersBoard)
    const filteredUserPlace = userOwnedPlacesArray.filter(
      array => array.place_id === place.place_id,
    )
    const tripGroup = tripGroups.filter(tripGroup => {
      if (filteredUserPlace[0] && filteredUserPlace[0].trip_group_uid) {
        return tripGroup.uid === filteredUserPlace[0].trip_group_uid
      }
    })
    if (tripGroup[0]) {
      setUserOwnedPlaceGroup(tripGroup[0].title)
    } else {
      setUserOwnedPlaceGroup('')
    }
  }, [userOwnedPlacesArray, place, tripGroups])

  return (
    <Container
      style={inlineStyle}
      onClick={onShowMapInfo}
      onMouseEnter={() => {
        //onHoverTripItem()
        setShowAddButton(true)
      }}
      onMouseLeave={() => {
        //onUnHoverTripItem()
        setShowAddButton(false)
      }}>
      <ImageContainer>
        <div style={{ zIndex: 10000 }}>
          {!tabletMobileView && showAddButtons && (
            <GroupDropdown>
              {userOwnedPlaceGroup ? (
                <DropdownToggle
                  caret
                  disabled
                  style={{
                    minWidth: '100px',
                    marginLeft: '2px',
                    marginTop: '1px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    height: '28px',
                    border: 'none',
                    color: '#686868',

                    borderRadius: '7px 0px 0px 7px',
                    background: '#F9F9F9',

                    maxWidth:
                      userOwnedPlaces || tripRoles[0]?.role === 'VIEWER'
                        ? '100px'
                        : '140px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  size="lg">
                  {userOwnedPlaceGroup}
                </DropdownToggle>
              ) : (
                <Dropdown
                  // style={{ minWidth: '100px' }}
                  size={'lg'}
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  direction={'down'}>
                  <div>
                    <DropdownToggle
                      caret
                      style={{
                        minWidth: '100px',
                        marginLeft: '2px',
                        marginTop: '1px',
                        fontFamily: 'Lato',
                        fontWeight: 700,
                        height: '28px',
                        border: 'none',
                        color: '#686868',

                        borderRadius: '7px 0px 0px 7px',
                        background: '#F9F9F9',

                        maxWidth: '105px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      size="lg">
                      {userOwnedPlaceGroup
                        ? userOwnedPlaceGroup
                        : selectedGroupTitle.length > 0
                        ? selectedGroupTitle
                        : 'Select Group'}
                    </DropdownToggle>
                  </div>

                  <DropdownMenu
                    style={{
                      marginTop: '6px',
                      fontSize: '1.5rem',
                      overflowX: 'hidden',
                      borderRadius: '10px',
                      position: 'fixed',
                      padding: '7px',
                    }}>
                    {!userOwnedPlaces &&
                      tripGroups.map(tripGroup => {
                        return (
                          <DropdownItem
                            style={{
                              marginTop: '6px',
                              overflowY: 'visible',
                              fontFamily: 'Lato',
                              fontWeight: 700,
                              whiteSpace: 'unset',
                              overflowWrap: 'anywhere',
                            }}
                            key={tripGroup.uid}
                            onClick={() => {
                              setTripGroup(tripGroup)
                              setSelectedGroupTitle(tripGroup.title)
                            }}>
                            {tripGroup.title}
                          </DropdownItem>
                        )
                      })}
                    <DropdownItem
                      style={{
                        fontSize: '1.2rem',
                        color: '#4287f5',
                        fontWeight: 'bold',
                        marginTop: '4px',
                      }}
                      onClick={() => {
                        setActive(true)
                        setTripGroup(null)
                        setSelectedGroupTitle('')
                      }}>
                      <ControlPointIcon /> Create Group
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </GroupDropdown>
          )}
        </div>

        <Carousel showThumbs={false} showStatus={false} showIndicators={false}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {place?.photos.length > 0 &&
            place?.photos?.slice(0, 1).map((slide, index) => {
              return (
                <Media
                  style={{
                    backgroundImage: `url(${slide})`,
                    backgroundRepeat: 'no-repeat, repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                  key={index}>
                  <div
                    style={{
                      display: 'flex',
                      transition: 'all .2s ease-in',
                      opacity: showAddButtons ? 1 : 0,
                    }}>
                    <div className="row">
                      <div
                        style={{
                          //whiteSpace: 'nowrap',
                          marginLeft: '60px',
                        }}>
                        {!tabletMobileView && showAddButtons && (
                          <GroupButtonStyled
                            disabled={
                              userOwnedPlaces || tripRoles[0]?.role === 'VIEWER'
                            }
                            onClick={onAddToBoard}>
                            {userOwnedPlaces
                              ? CONSTANTS.ALREADY_PART_OF_BOARD
                              : 'Save'}
                          </GroupButtonStyled>
                        )}
                      </div>
                    </div>
                  </div>
                </Media>
              )
            })}
        </Carousel>
      </ImageContainer>
      <Body>
        <Title>{place.name}</Title>
        <div><Stars rating={place.rating ?? 0}/></div>
      </Body>
      <CreateGroupModal
        active={active}
        setActive={setActive}
        currentTrip={tripShortUid}
        user={user}
      />
    </Container>
  )
}

export default GridItem
