import React, { useState } from 'react'

import { Select } from 'antd'
import type { FormikErrors, FormikValues } from 'formik'

import { ErrorField, InputStyled } from '../AuthStyles'

const { Option } = Select

const options = [
  'Events',
  'Friends or Family',
  'Google Search',
  'Facebook',
  'Instagram',
  'Linkedin',
  'Pintrest',
  'Tik Tok',
  'YouTube',
  'Other',
]

type Props = {
  formikKey: string
  setFieldValue: (key: string, value: string) => void
  handleChange: (key: string) => void
  errors: FormikErrors<FormikValues>
}
export const HeardAboutUs = ({
  formikKey,
  setFieldValue,
  errors,
  handleChange,
}: Props) => {
  const [otherOption, setOtherOption] = useState(false)
  const handleSelectChange = (value: string) => {
    const otherOptionSelected = value === options[options.length - 1]
    setOtherOption(otherOptionSelected)
    setFieldValue(formikKey, otherOptionSelected ? '' : value)
    handleChange(value)
  }

  return (
    <>
      <Select
        style={{
          width: '100%',
          marginBottom: '6px',
        }}
        onChange={handleSelectChange}
        dropdownStyle={{ zIndex: 2000 }}>
        {options.map((option, index) => (
          <Option key={index} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      <ErrorField>{errors.heard_about_us}</ErrorField>

      {otherOption && (
        <>
          <InputStyled
            required
            name={formikKey}
            placeholder="Tell us how..."
            maxLength={150}
          />
          <ErrorField>{errors.heard_about_us}</ErrorField>
        </>
      )}
    </>
  )
}
