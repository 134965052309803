/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import {
  TripItemAPI,
  TripsItemsHash,
  ColumnsObject,
  CurrentTrip,
  TripGroup,
  NoteTripGroupItem,
  LocationTripGroupItem,
  PollTripGroupItem,
} from './types'

/**
 * Example of how the grid would look like
 */
export const initialStateGrid = {
  tripsItems: {
    tripItem1: {
      id: 'tripItem1',
      content: 'Stanley Park',
    },
    tripItem2: {
      id: 'tripItem2',
      content: 'Capilano',
    },
    tripItem3: {
      id: 'tripItem3',
      content: 'Terra Nova',
    },
    tripItem4: {
      id: 'tripItem4',
      content: 'English Bay',
    },
    tripItem5: {
      id: 'tripItem5',
      content: 'Keg',
    },
    tripItem6: {
      id: 'tripItem6',
      content: 'Top Shanghai',
    },
    tripItem7: {
      id: 'tripItem7',
      content: 'Bottom Shanghai',
    },
  },
  columns: {
    column1: {
      id: 'column1',
      tripItemIds: ['tripItem1', 'tripItem6'],
    },
    column2: {
      id: 'column2',
      tripItemIds: ['tripItem2', 'tripItem7'],
    },
    column3: {
      id: 'column3',
      tripItemIds: ['tripItem3'],
    },
    column4: {
      id: 'column4',
      tripItemIds: ['tripItem4'],
    },
    column5: {
      id: 'column5',
      tripItemIds: ['tripItem5'],
    },
  },
  columnOrder: ['column1', 'column2', 'column3', 'column4', 'column5'],
}

type SetupPlanningBoardGridProps = {
  items: Array<TripItemAPI>
  trip: CurrentTrip
  count: number
}

const getColumns = (count = 1): ColumnsObject => {
  return [...Array(count)].reduce((prev, curr, index) => {
    return {
      ...prev,
      [`column${index + 1}`]: {
        id: `column${index + 1}`,
        tripItemIds: [],
      },
    }
  }, {})
}

/**
 * Get incoming trip items and process them into columns
 * to display on the grid.
 */
export const setupPlanningBoardGrid = ({
  items,
  trip,
  count,
}: SetupPlanningBoardGridProps) => {
  const _orginalColumns = getColumns(count)


  const tripsItems: TripsItemsHash = {}
  const sortedItems = items

  sortedItems.sort((a, b) => {
    if (a?.grid_position?.grid_row === b?.grid_position?.grid_row) {
      return a?.grid_position?.grid_col - b?.grid_position?.grid_col
    } else {
      return a?.grid_position?.grid_row - b?.grid_position?.grid_row
    }
  })
  // prepare trip items object of unique trip items ids and content
  sortedItems.forEach((item, index) => {
    switch (item.type) {
      case 'location':
        tripsItems[`tripItem${index + 1}`] = {
          id: `tripItem${index + 1}`,
          placeId: item.google_place_id,
          title: item.title,
          color: item.trip_location_item_metadata.color,
          note: item.note,
          startTime: item.start_time,
          endTime: item.end_time,
          startDate: item.start_date,
          endDate: item.end_date,
          uid: item.uid,
          lat: item.lat,
          lng: item.lng,
          type: item.type,
          createdBy: item.created_by,
          updatedBy: item.updated_by,
          trips_locations_items_tags: item.trips_locations_items_tags,
          doc: item.doc,
          order: item.order,
          metadata_uid: item.trip_location_item_metadata.uid,
          tripId: trip.id,
          position: item.grid_position,
          trip_group: item.trip_group,
        }
        break
      case 'note':
        tripsItems[`tripItem${index + 1}`] = {
          id: `tripItem${index + 1}`,
          type: item.type,
          title: item.title,
          uid: item.uid,
          description: item.description,
          color: item.trip_note_item_metadata.color,
          trips_notes_items_tags: item.trips_notes_items_tags,
          metadata_uid: item.trip_note_item_metadata.uid,
          doc: item.doc,
          order: item.order,
          position: item.grid_position,
          trip_group: item.trip_group,
        }
        break

      case 'poll':
        tripsItems[`tripItem${index + 1}`] = {
          id: `tripItem${index + 1}`,
          type: item.type,
          uid: item.uid,
          title: item.title,
          order: item.order,
          color: item.trip_poll_item_metadata.color,
          trips_polls_items_tags: item.trips_polls_items_tags,
          metadata_uid: item.trip_poll_item_metadata.uid,
          pollsOptions: item.trip_poll_item_options,
          tripMembersCount: trip.trips_members.length,
          position: item.grid_position,
          trip_group: item.trip_group,
        }
        break
      default:
        break
    }
  })

  // columns structure
  // by default the grid will intialize with
  // a large map and 4 columns
  const columns: ColumnsObject = _orginalColumns

  // by deafult, the grid starts off with 4 columns but can be
  // expanded to 6.
  // loop through incoming trip items and assign them to columns
  // columns range from 1-to-6 so if there are more than 6 items
  // the 7th and so on items will be pushed into the column arrays
  items.forEach((item, index) => {
    const { grid_position } = item
    const { grid_col, grid_row } = grid_position
    if (columns[`column${grid_col}`]) {
      columns[`column${grid_col}`].tripItemIds[grid_row - 1] = `tripItem${
        index + 1
      }`
    }
  })
  const createColumnOrders = (count: number): string[] => {
    return [...Array(count)].map((curr, index) => {
      return (curr = `column${index + 1}`)
    })
  }

  const columnOrder: string[] = createColumnOrders(count)

  const obj = {
    tripsItems,
    columns,
    columnOrder,
  }
  return obj
}

type StateTripItemColorObject = {
  uid: string
  color: string
}

export const parseTripItemColorsToState = (tripItems: (LocationTripGroupItem | NoteTripGroupItem | PollTripGroupItem) []) => {
  let res: Array<StateTripItemColorObject> = []
  res = tripItems.map((item : LocationTripGroupItem | NoteTripGroupItem | PollTripGroupItem) => {
    if (item.type === 'note') {
      return ({
        uid: item.uid,
        color: (item as NoteTripGroupItem).trip_note_item_metadata.color
      })
    }
    else if (item.type === 'poll') {
      return ({
        uid: item.uid,
        color: (item as PollTripGroupItem).trip_poll_item_metadata.color
      })
    }
    else {
      return ({
        uid: item.uid,
        color: (item as LocationTripGroupItem).trip_location_item_metadata.color
      })
    }
  })
  return res
}

export const getLocationItemsFromGroups = (
  tripGroups: Array<TripGroup> = [],
) => {
  return tripGroups
    .flatMap(group => group.tripItems)
    .filter(item => item?.type === 'location')
}
