/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react'

import DragHandleIcon from '@material-ui/icons/DragHandle'
import { MTDialog, MTButton } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { useGridOrientation } from 'contexts/GridOrientationContext'
import { CONSTANTS } from 'core/constants'
import { useWindowSize } from 'hooks/useWindowSize'
import { HTTPError } from 'ky'
import { isEmpty, isNull } from 'lodash'
import { ColorPalette } from 'pages/Workspace/layouts/PlanningBoard/components/ColorPalette'
import { create } from 'api/tripNoteItem'
import { create as PollCreate } from 'api/tripPollItem'

import {
  LocationTripGroupItem,
  TripGroupItem,
  UpdateGridItemProps,
  DeleteGridItemProps,
  NoteTripGroupItem,
  PollTripGroupItem,
  AddGridItemProps,
  TripNoteItem,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { Draggable } from 'react-beautiful-dnd'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import {
  removeUserOwnedPlacesArray,
  updateLocationItemColor,
} from 'store/slices/locationSearchResults'
import { add } from 'store/slices/popupEditGridItem'
import {
  updateTripGroup,
  updateColorOfTripGroupItem,
  unsetNewlyCreatedItem,
} from 'store/slices/tripGroupSlice'
import { updateTripItemColor } from 'store/slices/tripItemColor'
import {
  updateLocationColor,
  hideLocationItemMapInfo,
  showLocationItemMapInfo,
  deleteLocation,
} from 'store/slices/tripItemLocationsMap'
import { tripItemTypeSingular, tripItemTypes } from 'utils/itemTypes'
import { can, TRIP_ITEM_DELETE } from 'utils/permissions'
import { sendNotification } from 'utils/toast'

import {
  remove as deleteTripLocationItem,
  getSignedURL as getSignedURLTripLocationItem,
} from 'api/tripLocationItem'
import { update as tripLocationItemMetadataUpdate } from 'api/tripLocationItemMetadata'
import {
  getSignedURL as getSignedURLTripNoteItem,
  remove as deleteTripNoteItem,
} from 'api/tripNoteItem'
import { update as tripNoteItemMetadataUpdate } from 'api/tripNoteItemMetadata'
import { remove as deleteTripPollItem } from 'api/tripPollItem'
import { update as tripPollItemMetadataUpdate } from 'api/tripPollItemMetadata'
import {
  ColorIcon,
  PenIcon,
  TrashIcon,
  DuplicateIcon,
} from 'components/MTIcons'
import Tooltip from '@mui/material/Tooltip'
import { determineTripItemComponent, determineTripItemTags } from './helper'
import {
  PlanningBoardItemStyled,
  Header,
  Body,
  BodyBottom,
  Title,
  Footer,
} from './planningBoardItem.style'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomToolTip from 'components/CustomTooltip/CustomTooltip'
import { LocalConvenienceStoreOutlined } from '@material-ui/icons'
import { addItemInTripGroup } from 'store/slices/tripGroupSlice'
import { CircularProgress } from '@material-ui/core'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#3A3A3A !important',
    color: 'white !important',
    maxWidth: 220,
    borderRadius: '8px !important',
    padding: '8px 12px !important',
  },
}))(Tooltip)

type Res = {
  [key: string]: { color: string }
}
type Props = {
  tripItem: TripGroupItem
  index: number
  tripMembersCount: number
  bucket?: string
  tripShortUid: string
  onDeleteGridItem: ({ data, key }: DeleteGridItemProps) => void
  onUpdateGridItem: ({ data, key }: UpdateGridItemProps) => void
  onAddGridItem: (data: AddGridItemProps) => void
  disableDragOnGridItems: boolean
  isNewlyCreated: boolean
}

const PlanningBoardItem = ({
  tripItem,
  index,
  tripMembersCount,
  bucket,
  tripShortUid,
  isNewlyCreated,
  onDeleteGridItem,
  onUpdateGridItem,
  onAddGridItem,
  disableDragOnGridItems,
}: Props) => {
  const dispatch = useAppDispatch()
  const [docURL, setDocURL] = useState('')
  const [hover, setHover] = useState(false)
  const [colorPaletteAnchorEl, setColorPaletteAnchorEl] = useState(null)
  const { gridOrientation } = useGridOrientation()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [rerender, setRerender] = useState(false)
  const [isDuplicating, setIsDuplicating] = useState(false)
  const { setOpenDialog } = useDialog()
  const myRef = useRef<HTMLDivElement>(null)
  const coordinates = useAppSelector(state => state.geoLocation)
  const { width } = useWindowSize()

  const { user } = useAppSelector(state => state.user)
  const tripsRoles = useAppSelector(state => state.tripUserRoleSlice)

  const { tripGroups, newlyCreatedItem } = useAppSelector(
    state => state.tripGroupSlice,
  )

  const canEdit = can(tripsRoles?.data[0]?.role, TRIP_ITEM_DELETE)
  const { userOwnedLocations } = useAppSelector(
    state => state.tripItemLocationsMap,
  )
  const mapMarkerHovered = userOwnedLocations.filter(
    item => item.showMarkerMapInfo,
  )

  /**
   * Render trip item component based on type
   */
  const tripItemComponent = determineTripItemComponent({
    itemType: tripItem.type,
    tripMembersCount,
    tripItem,
    docURL,
    onUpdateGridItem,
  })

  const tripItemTags = determineTripItemTags(tripItem)

  /**
   * Modify grid item style when it is being dragged or hovered over.
   *
   * @param { boolean } isDragging item is dragged to new position
   * @param { CSSProperties } draggableStyle style of dragged item
   * @returns
   */
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    transition: ' all 0.2s ease',
    background: isDragging ? '#E5E5E5' : 'white',
    boxShadow:
      hover ||
      (!isEmpty(mapMarkerHovered) &&
        mapMarkerHovered[0].showMarkerMapInfo &&
        mapMarkerHovered[0].uid === tripItem.uid)
        ? '0px 5px 11px -1px rgba(0,0,0,0.3)'
        : '0px 5px 11px -1px rgba(0,0,0,0.1)',
    // styles we need to apply on draggables
    ...draggableStyle,
  })

  /**
   * Download any attached files to trip item.
   */
  const onDownloadDocument = useCallback(
    async (isMounted: boolean) => {
      const tripBucket = `${bucket}/${tripItem.uid}`
      const key = (tripItem as LocationTripGroupItem)?.doc
      const args = {
        bucket: tripBucket,
        key,
        user_uid: user.uid,
      }
      let res = null

      if (tripItem.type === 'location') {
        res = await getSignedURLTripLocationItem(args)
      } else {
        res = await getSignedURLTripNoteItem(args)
      }
      if (isMounted) {
        setDocURL(res.signedUrl)
      }
    },
    [tripItem, user?.uid, bucket],
  )

  /**
   * Open color palette popover
   * @param { Event } event event
   */
  const onHandleOpenColorPalette = (event: any) => {
    if (!canEdit) {
      return sendNotification(CONSTANTS.CHANGE_COLOR_PERMISSION_ERROR, 'error')
    }
    setColorPaletteAnchorEl(event.currentTarget)
  }

  /**
   * Close color palette popover
   */
  const onHandleCloseColorPalette = () => {
    if (width <= 1024) {
      setHover(true)
    } else {
      if (hover) setHover(false)
    }
    setColorPaletteAnchorEl(null)
  }

  const updateItemColorInRedux = async (color: string) => {
    let tripGroupOfThatItem: any = {}

    for (const tripGroup of tripGroups) {
      for (const tripGroupItem of tripGroup.tripItems) {
        if (tripGroupItem.uid === tripItem.uid) {
          tripGroupOfThatItem = tripGroup
          break
        }
      }
    }

    let newGroupItem: any = {}
    for (const groupItem of tripGroupOfThatItem.tripItems) {
      if (groupItem.uid === tripItem.uid) {
        newGroupItem = groupItem
        break
      }
    }

    const payload = {
      groupUid: tripGroupOfThatItem.uid,
      tripItemUid: newGroupItem.uid,
      color: color,
    }
    await dispatch(updateColorOfTripGroupItem(payload))
    setRerender(!rerender)
  }

  /**
   * Update trip item color
   * @param { String } color trip item color
   */
  const onUpdateItemColor = async (color: string) => {
    const copy: any = { ...tripItem }
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    try {
      let res: Res = { a: { color: '' } }
      let type
      let array_type

      const uid =
        (copy.type === 'note' &&
          (copy as NoteTripGroupItem).trip_note_item_metadata.uid) ||
        (copy.type === 'poll' &&
          (copy as PollTripGroupItem).trip_poll_item_metadata.uid) ||
        (copy.type === 'location' &&
          (copy as LocationTripGroupItem).trip_location_item_metadata.uid)

      const commonArgs = {
        color,
        user_uid: user.uid,
        uid: uid,
        short_uid: tripShortUid,
      }

      switch (tripItem.type) {
        case 'location':
          res = await tripLocationItemMetadataUpdate(
            (tripItem as LocationTripGroupItem).trip_location_item_metadata.uid,
            {
              ...commonArgs,
            },
          )
          type = tripItemTypeSingular.LOCATION
          array_type = tripItemTypes.TRIPS_LOCATIONS_ITEMS

          updateItemColorInRedux(color)
          break
        case 'poll':
          res = await tripPollItemMetadataUpdate(
            (tripItem as PollTripGroupItem).trip_poll_item_metadata.uid,
            {
              ...commonArgs,
            },
          )
          type = tripItemTypeSingular.POLL
          array_type = tripItemTypes.TRIPS_POLLS_ITEMS
          updateItemColorInRedux(color)
          break
        case 'note':
          res = await tripNoteItemMetadataUpdate(
            (tripItem as NoteTripGroupItem).trip_note_item_metadata.uid,
            {
              ...commonArgs,
            },
          )
          type = tripItemTypeSingular.NOTE
          array_type = tripItemTypes.TRIPS_NOTES_ITEMS
          updateItemColorInRedux(color)

          break
      }

      if (res[`trip_${type}_item_metadata`].color === color) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onUpdateGridItem({ data: res, key: array_type })
        onHandleCloseColorPalette()

        sendNotification(
          `${CONSTANTS.TRIP_LOCATION_ITEM_COLOR_UPDATE_SUCCESS}`,
          'success',
        )

        // update trip location item's marker color in map
        if (tripItem.type === 'location') {
          dispatch(
            updateLocationColor({
              color: res[`trip_${type}_item_metadata`]?.color,
              uid: tripItem.uid,
            }),
          )

          dispatch(
            removeUserOwnedPlacesArray({
              place_id: (tripItem as LocationTripGroupItem)?.placeId,
            }),
          )
        }

        // update grid item header color in real time
        dispatch(
          updateTripItemColor({
            color: res[`trip_${type}_item_metadata`]?.color,
            uid: tripItem.uid,
          }),
        )
        updateLocationItemColor({
          color: res[`trip_${type}_item_metadata`]?.color,
          place_id: (tripItem as LocationTripGroupItem)?.placeId,
        })
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Open edit modal for grid item
   */
  const onOpenItemEditPopupModal = () => {
    dispatch(
      add({
        type: tripItem.type,
        uid: tripItem.uid,
        open: true,
      }),
    )
  }

  /**
   * Delete trip item based on type.
   */
  const onHandleDeleteByType = async () => {
    switch (tripItem.type) {
      case 'location':
        onHandleDeleteLocationItem()
        break
      case 'note':
        onHandleDeleteNoteItem()
        break
      case 'poll':
        onHandleDeletePollItem()
        break
      default:
        break
    }
  }

  /**
   * Open delete modal for grid item
   */
  const onOpenItemDeletePopupModal = () => {
    if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    if (!canEdit)
      return sendNotification(`${CONSTANTS.DELETE_ITEM_DENY}`, 'error')
    setOpenDeleteDialog(true)
  }

  /**
   * Close delete dialogue
   */
  const onHandleCloseItemDeletePopupModal = () => {
    setOpenDeleteDialog(false)
  }

  /**
   * Handle poll deletion
   */
  const onHandleDeletePollItem = async () => {
    try {
      const res = await deleteTripPollItem({
        user_uid: user.uid,
        uid: tripItem.uid,
        short_uid: tripShortUid,
        grid_orientation: gridOrientation,
        coordinates,
      })

      let tripGroupOfThatItem: any = {}
      for (const tripGroup of tripGroups) {
        for (const tripGroupItem of tripGroup.tripItems) {
          if (tripGroupItem.uid === tripItem.uid) {
            tripGroupOfThatItem = tripGroup
            break
          }
        }
      }

      if (tripGroupOfThatItem && tripGroupOfThatItem.tripItems) {
        const updatedTripGroupItems = tripGroupOfThatItem.tripItems.filter(
          (groupItem: any) => {
            if (groupItem.uid !== tripItem.uid) {
              return tripItem
            }
          },
        )

        const newUpdatedGroup = {
          uid: tripGroupOfThatItem.uid,
          title: tripGroupOfThatItem.title,
          color: tripGroupOfThatItem.color,
          short_uid: tripGroupOfThatItem.short_uid,
          order: tripGroupOfThatItem.order,
          id: tripGroupOfThatItem.id,
          created_by: tripGroupOfThatItem.created_by,
          tripItems: updatedTripGroupItems,
        }

        await dispatch(updateTripGroup(newUpdatedGroup))
      }

      if (res) {
        sendNotification(
          `${CONSTANTS.TRIP_POLL_ITEM_DELETE_SUCCESS}`,
          'success',
        )
      }
      onDeleteGridItem({ data: res, key: tripItemTypes['TRIPS_POLLS_ITEMS'] })
      onHandleCloseItemDeletePopupModal()
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Handle location item deletion
   */
  const onHandleDeleteLocationItem = async () => {
    try {
      const res = await deleteTripLocationItem({
        user_uid: user.uid,
        uid: tripItem.uid,
        short_uid: tripShortUid,
        grid_orientation: gridOrientation,
        coordinates,
      })

      let tripGroupOfThatItem: any = {}
      for (const tripGroup of tripGroups) {
        for (const tripGroupItem of tripGroup.tripItems) {
          if (tripGroupItem.uid === tripItem.uid) {
            tripGroupOfThatItem = tripGroup
            break
          }
        }
      }

      if (tripGroupOfThatItem && tripGroupOfThatItem.tripItems) {
        const updatedTripGroupItems = tripGroupOfThatItem.tripItems.filter(
          (groupItem: any) => {
            if (groupItem.uid !== tripItem.uid) {
              return tripItem
            }
          },
        )

        const newUpdatedGroup = {
          uid: tripGroupOfThatItem.uid,
          title: tripGroupOfThatItem.title,
          color: tripGroupOfThatItem.color,
          short_uid: tripGroupOfThatItem.short_uid,
          order: tripGroupOfThatItem.order,
          id: tripGroupOfThatItem.id,
          created_by: tripGroupOfThatItem.created_by,
          tripItems: updatedTripGroupItems,
        }

        await dispatch(updateTripGroup(newUpdatedGroup))
      }

      if (res) {
        sendNotification(
          `${CONSTANTS.TRIP_LOCATION_ITEM_DELETE_SUCCESS}`,
          'success',
        )
      }

      onDeleteGridItem({
        data: res,
        key: tripItemTypes['TRIPS_LOCATIONS_ITEMS'],
      })
      dispatch(deleteLocation({ uid: tripItem.uid }))
      dispatch(
        removeUserOwnedPlacesArray({
          place_id: (tripItem as LocationTripGroupItem)?.google_place_id,
        }),
      )
      onHandleCloseItemDeletePopupModal()
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Handle note item deletion
   */
  const onHandleDeleteNoteItem = async () => {
    try {
      const res = await deleteTripNoteItem({
        user_uid: user.uid,
        uid: tripItem.uid,
        short_uid: tripShortUid,
        grid_orientation: gridOrientation,
        coordinates,
      })

      let tripGroupOfThatItem: any = {}
      for (const tripGroup of tripGroups) {
        for (const tripGroupItem of tripGroup.tripItems) {
          if (tripGroupItem.uid === tripItem.uid) {
            tripGroupOfThatItem = tripGroup
            break
          }
        }
      }

      if (tripGroupOfThatItem && tripGroupOfThatItem.tripItems) {
        const updatedTripGroupItems = tripGroupOfThatItem.tripItems.filter(
          (groupItem: any) => {
            if (groupItem.uid !== tripItem.uid) {
              return groupItem
            }
          },
        )

        const newUpdatedGroup = {
          uid: tripGroupOfThatItem.uid,
          title: tripGroupOfThatItem.title,
          color: tripGroupOfThatItem.color,
          short_uid: tripGroupOfThatItem.short_uid,
          order: tripGroupOfThatItem.order,
          id: tripGroupOfThatItem.id,
          created_by: tripGroupOfThatItem.created_by,
          tripItems: updatedTripGroupItems,
        }

        await dispatch(updateTripGroup(newUpdatedGroup))
      }

      if (res) {
        sendNotification(
          `${CONSTANTS.TRIP_NOTE_ITEM_DELETE_SUCCESS}`,
          'success',
        )
        onHandleCloseItemDeletePopupModal()
        onDeleteGridItem({ data: res, key: tripItemTypes['TRIPS_NOTES_ITEMS'] })
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * When user's mouse enters the bounds of a location grid item,
   * show the marker place title in the map.
   */
  const onHoverTripItem = () => {
    if (tripItem.type === 'location') {
      dispatch(
        showLocationItemMapInfo({
          uid: tripItem.uid,
        }),
      )
    }
  }

  /**
   * When user's mouse leaves the bounds of a location grid item,
   * hide the marker place title in the map.
   */
  const onUnHoverTripItem = () => {
    if (tripItem.type === 'location') {
      dispatch(
        hideLocationItemMapInfo({
          uid: tripItem.uid,
        }),
      )
    }
  }

  const duplicateItem = async () => {
    setIsDuplicating(true)
    if (user.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }
    const selectedGroup =
      tripGroups.find(t => {
        return t.tripItems.find(i => i.id === tripItem.id)
      }) ?? tripGroups[0]

    const allGroupItems = selectedGroup?.tripItems
    const lastItemsOrder =
      allGroupItems && allGroupItems.length > 0 ? allGroupItems?.length + 1 : 1

    const isNote = tripItem.type === 'note'
    const isPoll = tripItem.type === 'poll'

    // Common form data
    const formData = {
      title: '(Copy) ' + tripItem.title,
      description: isNote
        ? (tripItem as NoteTripGroupItem).description
        : tripItem.title,
      short_uid: tripShortUid,
      user_uid: user.uid,
      trip_group: selectedGroup?.uid,
      grid_orientation: gridOrientation.toString(),
      is_tentative: !!user.is_tentative,
      order: lastItemsOrder.toString(),
      ...coordinates,
      coordinates,
      color: isNote
        ? (tripItem as NoteTripGroupItem).trip_note_item_metadata.color
        : (tripItem as PollTripGroupItem).trip_poll_item_metadata.color,
      ...(isPoll && {
        poll_options: (tripItem as PollTripGroupItem).trip_poll_item_options,
      }),
    }

    try {
      const newItem = isNote
        ? await create(formData)
        : await PollCreate(formData)
      const payload = {
        groupUid: selectedGroup?.uid,
        tripItem: newItem,
      }
      await dispatch(addItemInTripGroup(payload))
      // onAddGridItem({
      //   data: newItem,
      //   key: isNote
      //     ? tripItemTypes['TRIPS_NOTES_ITEMS']
      //     : tripItemTypes['TRIPS_POLLS_ITEMS'],
      // })
      sendNotification(CONSTANTS.DUPLICATE_NEW_TRIP_ITEM, 'success')
      setIsDuplicating(false)
    } catch (error) {
      sendNotification(CONSTANTS.DUPLICATE_NEW_TRIP_ITEM_ERROR, 'error')
      setIsDuplicating(false)
    }
  }

  /**
   * Cancel poll deletion
   */
  const onHandleCancel = () => {
    onHandleCloseItemDeletePopupModal()
  }

  useEffect(() => {
    let isMounted = true
    if (
      tripItem &&
      !isNull(user) &&
      (tripItem.type === 'location' || tripItem.type === 'note')
    ) {
      onDownloadDocument(isMounted)
    }
    return () => {
      isMounted = false
    }
  }, [tripItem, onDownloadDocument, user])

  useEffect(() => {
    if (isNewlyCreated && newlyCreatedItem) {
      myRef.current &&
        myRef.current.scrollIntoView({
          behavior: 'smooth',
        })
      dispatch(unsetNewlyCreatedItem())
    }
  }, [isNewlyCreated, newlyCreatedItem, dispatch])

  useEffect(() => {
    if (width <= 1024) {
      setHover(true)
    } else {
      setHover(false)
    }
  }, [width])

  return (
    <Draggable
      draggableId={tripItem.id}
      index={index}
      isDragDisabled={disableDragOnGridItems}>
      {({ draggableProps, dragHandleProps, innerRef }, { isDragging }) => (
        <PlanningBoardItemStyled
          {...draggableProps}
          ref={innerRef}
          style={getItemStyle(isDragging, draggableProps.style)}
          onMouseEnter={() => {
            onHoverTripItem()
            setHover(true)
          }}
          onMouseLeave={() => {
            onUnHoverTripItem()
            setHover(false)
          }}>
          <Header
            {...dragHandleProps}
            ref={myRef}
            style={{
              backgroundColor:
                (tripItem.type === 'note' &&
                  (tripItem as NoteTripGroupItem).trip_note_item_metadata
                    .color) ||
                (tripItem.type === 'poll' &&
                  (tripItem as PollTripGroupItem).trip_poll_item_metadata
                    .color) ||
                (tripItem.type === 'location' &&
                  (tripItem as LocationTripGroupItem)
                    .trip_location_item_metadata.color) ||
                'red',
              //backgroundColor: tripItemColor?.data[tripItem.uid]?.color,

              cursor: disableDragOnGridItems ? 'not-allowed' : 'pointer',
            }}>
            <DragHandleIcon
              style={{
                fill: 'white ',
                opacity: hover ? '1' : '0',
                fontSize: '1.7rem',
                transition: 'all .25s cubic-bezier(0.4, 0, 1, 1)',
              }}
            />
          </Header>

          <Body
            onClick={() =>
              tripItem.type === 'location' && onOpenItemEditPopupModal()
            }>
            <div>
              <Title>{tripItem.title}</Title>
            </div>

            <BodyBottom>{tripItemComponent}</BodyBottom>
            <div>{tripItemTags}</div>
          </Body>

          <Footer
            style={{
              visibility: hover ? 'visible' : 'hidden',
              transition: 'opacity .25s cubic-bezier(0.4, 0, 1, 1)',
              opacity: hover ? 1 : 0,
            }}>
            <CustomToolTip
              showonClick={false}
              customStyles={{
                justifyContent: 'none',
                left: '12px',
                position: 'absolute',
                bottom: '5px',
                width: 'auto',
              }}
              title="Edit card">
              <MTButton
                title=""
                customIcon={<PenIcon />}
                onHandleClick={onOpenItemEditPopupModal}
                iconOnly={true}
              />
            </CustomToolTip>

            <CustomToolTip
              showonClick={false}
              customStyles={{
                justifyContent: 'none',
                left: '45px',
                position: 'absolute',
                bottom: '5px',
                width: 'auto',
              }}
              title={'Edit card color'}>
              <MTButton
                title=""
                customIcon={
                  <ColorIcon
                    fill={
                      (tripItem.type === 'note' &&
                        (tripItem as NoteTripGroupItem).trip_note_item_metadata
                          .color) ||
                      (tripItem.type === 'poll' &&
                        (tripItem as PollTripGroupItem).trip_poll_item_metadata
                          .color) ||
                      (tripItem.type === 'location' &&
                        (tripItem as LocationTripGroupItem)
                          .trip_location_item_metadata.color)
                    }
                  />
                }
                onHandleClick={onHandleOpenColorPalette}
                iconOnly={true}
              />
            </CustomToolTip>
            <CustomToolTip
              showonClick={false}
              title="Delete card"
              customStyles={{
                position: 'absolute',
                bottom: '5px',
                right: '1px',
              }}>
              <MTButton
                customIcon={<TrashIcon />}
                onHandleClick={onOpenItemDeletePopupModal}
                iconOnly={true}
              />
            </CustomToolTip>
            {tripItem.type !== 'location' && (
              <CustomToolTip
                showonClick={false}
                title="Duplicate card"
                customStyles={{
                  position: 'absolute',
                  bottom: '5px',
                  right: '30px',
                }}>
                <MTButton
                  disabled={isDuplicating}
                  customIcon={<DuplicateIcon />}
                  onHandleClick={duplicateItem}
                  iconOnly={true}
                />
              </CustomToolTip>
            )}
            {openDeleteDialog && (
              <MTDialog
                openDialog={openDeleteDialog}
                onCloseModal={onHandleCloseItemDeletePopupModal}
                onDeleteTripClickHandler={onHandleDeleteByType}
                onCancelClickHandler={onHandleCancel}
                itemToBeDeleted={CONSTANTS.CARD_TO_BE_DELETED}
                type={modalTypes.DELETE_ITEM}
              />
            )}
          </Footer>
          <ColorPalette
            onHandleCloseColorPalette={onHandleCloseColorPalette}
            colorPaletteAnchorEl={colorPaletteAnchorEl}
            onUpdateItemColor={onUpdateItemColor}
          />
        </PlanningBoardItemStyled>
      )}
    </Draggable>
  )
}

export default PlanningBoardItem
