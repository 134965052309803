import React from 'react'

import styled from '@emotion/styled'
import { Avatar, makeStyles } from '@material-ui/core'
import { isNil } from 'lodash'

import { styles } from './mTAvatar.style'

type MTAvatarProps = {
  name?: string
  photo?: string
  color?: string
  backgroundColor?: string
  displayName: string
  extraMembers?: number
  verticalAnimation?: boolean
  fontSize?: string
  height?: string
  width?: string
}

const MTAvatar = ({
  name,
  photo,
  color,
  backgroundColor,
  displayName,
  extraMembers,
  verticalAnimation,
  fontSize = '1.6rem',
  height = '38px',
  width = '38px',
}: MTAvatarProps) => {
  const styledProps = {
    height,
    width,
  }
  const useStyles = makeStyles(() => styles(styledProps))

  const AvatarStyled = styled(Avatar)`
    font-size: ${fontSize};
    font-weight: bold;
  `
  const classes = useStyles()
  const customColor = {
    backgroundColor: backgroundColor ? backgroundColor : '#DDF1FF',
    color: !isNil(color) ? color : '#242424',
  }

  const displayData =
    displayName && displayName !== ''
      ? displayName
      : typeof extraMembers !== 'undefined'
      ? `+${extraMembers}`
      : null

  return (
    <AvatarStyled
      style={{...customColor, border: '1px solid white'}}
      alt={name}
      src={photo}
      classes={{ root: classes.root }}
      className={`${
        typeof verticalAnimation === 'undefined' ? 'member-list-item' : ''
      } member-list-item--shadow`}>
      {displayData}
    </AvatarStyled>
  )
}

export default MTAvatar
