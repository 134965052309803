import ky from 'ky'

const baseURL = process.env.REACT_APP_API_BASE_URL

/**
 * Wrapper for Ky HTTP library.
 *
 * Documentation: https://github.com/sindresorhus/ky
 *
 * @param {string} url route
 * @param {object} data body | json - data to send to api
 * @param {string} method HTTP method
 * @param {string | object<string, string | number | boolean> | Array<Array<string | number | boolean>> | URLSearchParams} searchParams search parameters
 */
export const request = async({ url, data, method, searchParams }) => {
    const reqObj = {}

    if (typeof data !== 'undefined') {
        if (data instanceof FormData) {
            reqObj.body = data
        } else {
            reqObj.json = data
        }
    }
    if (typeof searchParams !== 'undefined') reqObj.searchParams = searchParams

    let URL = null
    if (process.env.NODE_ENV === 'development') {
        URL = `${baseURL}${url}`
    } else {
        URL = `${url}`
    }

    const response = await ky(URL, {
        method,
        ...reqObj,
        credentials: 'include',
        timeout: 60000,
        hooks: {
            afterResponse: [
                async(_request, _options, response) => {
                    const e = await response.json()

                    if (e.message === 'jwt expired') {
                        let newUrl = null
                        if (process.env.NODE_ENV === 'development') {
                            newUrl = `${process.env.REACT_APP_API_BASE_URL}/api/auth/renew_token`
                        } else {
                            newUrl = '/api/auth/renew_token'
                        }
                        let res = await ky.post(newUrl, {
                            json: {
                                uid: e.uid,
                            },
                            credentials: 'include',
                            hooks: {
                                afterResponse: [
                                    async(_request, _options, response) => {
                                        const ee = await response.json()
                                        if (
                                            ee.message ===
                                            'Refresh Token expired! Please login again!'
                                        ) {
                                            // edge case: if the refresh token expires, redirect user to the login page
                                            // This operation erases localstorage data related to the user's login.
                                            window.localStorage.clear()
                                            window.location.replace('/?display_login=true')
                                            return ee.message
                                        }
                                    },
                                ],
                            },
                        })

                        const resObj = {}
                        const { url, method } = _request
                        const { json, body, searchParams } = _options

                        if (typeof body !== 'undefined' && body instanceof FormData) {
                            resObj.body = body
                        } else if (typeof searchParams !== 'undefined') {
                            resObj.searchParams = searchParams
                        } else {
                            resObj.json = json
                        }

                        // retry the original request
                        res = await ky(url, {
                            method,
                            ...resObj,
                            credentials: 'include',
                        })

                        return res
                    }
                },
            ],
        },
    })
    const res = await response.json()
    return res
}
