import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Field } from 'formik'
import { cssVariables } from 'theme'

export const PopupContainer = styled.div`
  display: grid;
`

export const SmallerVoteContainer = styled.div`
  //margin-left: 10px;
`

export const ErrorsMsg = styled.div`
  font-size: 1.3rem;
  color: red;
`

export const AnswerField = styled(Field)`
  font-size: 12px;
  width: 94%;
  height: 38px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 3px;
  border: none;
  background-color: #f3faff;
  border-radius: 10px;
  &:focus {
    outline-width: 0;
  }
`

export const AddAnswerButton = styled(Button)`
  margin-top: 0.5rem;
  font-size: 1.3rem;
  color: ${cssVariables.colors.primaryBlue};
  padding: 1rem 0;
`

export const OptionCancelButton = styled.div`
  position: absolute;
  right: 0px;
  top: 3px;
  z-index: 1500;
  cursor: pointer;
  display: inline-flex; /* Use flex to center content vertically */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 2rem;
  width: 2rem;
  padding: 20px; /* Increase this padding to enlarge the clickable area */
`

export const Icon = styled(CloseIcon)`
  font-size: 16px;
  line-height: 1;
`
export const AddAnswerButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NewOptionsContainer = styled.div`
  marginleft: 0.3rem;
`
