/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { WebSharp } from '@material-ui/icons'
import { MTButton } from 'components'
import { CONSTANTS } from 'core/constants'
import { Formik, Field } from 'formik'
import { useQuery } from 'hooks/useQuery'
import itly from 'itly'
import { HTTPError } from 'ky'
import { isEmpty, isNil, isNull } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { GooglePlacesSearchBar } from 'pages/CreateTrip/components/GooglePlacesSearchBar'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetAction, resetUserStore } from 'store'
import { useAppSelector, useGeolocation, CoordsObject } from 'store/hooks'
import { setUserTripsCount } from 'store/slices/userPlanSlice'
import { setUser } from 'store/slices/userSlice'
import { cssVariables } from 'theme'
import { isScreenSize, tabletMobileScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { create } from 'api/trip'
import { createTentative as createTentativeUser } from 'api/user'
import { getUserPayment } from 'api/userPlans'

import { DatePicker } from './components/DatePicker'
import {
  Title,
  FormStyled,
  Label,
  Row,
  DatesContainer,
  ButtonGroup,
} from './createTripCard.style'
import { validateDestination } from './helper'

export type LatLng = [number, number]

export type GoogleSearchQuery = {
  destination: string
  place_id: string
  lat: number
  lng: number
  sw_bounds: LatLng
  ne_bounds: LatLng
}

type TripData = {
  startDate: Date
  endDate: Date
  googleSearchQuery: GoogleSearchQuery
}

const CreateTripForm = () => {
  const { user } = useAppSelector(state => state.user)
  const userTripsCount = useAppSelector(
    state => state.userPlanSlice.userTripsCount,
  )
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)
  const history = useHistory()
  const query = useQuery()
  const dispatch = useDispatch()
  const refresh_token_error_message = query.get('refresh_token_error_message')
  const [loading, setLoading] = useState(false)

  const [startDateVisual, setStartDateVisual] = useState(new Date())
  const [endDateVisual, setEndDateVisual] = useState(
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
  )

  let cardTitleHeadingSize = '2.9rem'
  let formPadding = '1rem 4rem'
  let isTabletView = false
  const tabletMobileViewGeneral = tabletMobileScreenSize()

  const isScreenSizeEquals1100 = isScreenSize(1100)
  const isScreenSizeEquals950 = isScreenSize(950)
  const isScreenSizeEquals750 = isScreenSize(750)
  const isScreenSizeEquals600 = isScreenSize(600)
  const coordinates = useAppSelector(state => state.geoLocation)

  if (isScreenSizeEquals1100) {
    cardTitleHeadingSize = '2.7rem'
    formPadding = '1rem 2rem'
  }
  if (isScreenSizeEquals950) {
    isTabletView = true
    cardTitleHeadingSize = '2.5rem'
    formPadding = '1rem 2rem'
  }
  if (isScreenSizeEquals750) {
    isTabletView = true
    cardTitleHeadingSize = '2.2rem'
    formPadding = '1rem 0.5rem'
  }
  if (isScreenSizeEquals600) {
    isTabletView = true
    cardTitleHeadingSize = '2.0rem'
    formPadding = '1rem 0rem'
  }

  /**
   * Handle trip creation
   * @param {object} tripData Trip create data
   */
  const handleCreateTrip = async (tripData: TripData) => {
    if (typeof tripData !== 'undefined') {
      const { googleSearchQuery } = tripData
      const { destination, place_id, lat, lng, sw_bounds, ne_bounds } =
        tripData.googleSearchQuery

      if (isEmpty(googleSearchQuery) || !destination) {
        return sendNotification(
          'Please search for a destination and select one from the dropdown!',
          'error',
        )
      }

      try {
        setLoading(true)
        let data = {
          title: `Trip to ${destination}`,
          destination,
          google_place_id: place_id,
          lat,
          lng,
          sw_bounds,
          ne_bounds,
          startDate: tripData.startDate,
          endDate: tripData.endDate,
          coordinates,
        }
        let tentativeUser = null

        // if user state is empty or user is tentative
        // erase the user store before creating a new trip.
        // this is also required if a new tab is opened
        // to reduce errors from potentially empty
        // user redux states
        if (isNull(user.firstName) || user?.is_tentative) {
          resetUserStore(dispatch)

          tentativeUser = await createTentativeUser({
            activate_account: false,
            is_tentative: true,
          })

          data = {
            ...data,
            ...{
              user_uid: tentativeUser.data.uid,
              createdBy: tentativeUser.data.uid,
            },
          }
        } else {
          data = {
            ...data,
            ...{
              createdBy: user.uid,
              user_uid: user.uid,
              uid: user.uid,
            },
          }
        }

        if (
          user.firstName &&
          !user?.is_tentative &&
          userPlan?.plan?.name === CONSTANTS.FREE_PLAN &&
          userTripsCount >= 1
        ) {
          sendNotification(
            'Please purchase your plan to create more trip boards',
            'error',
          )
          return
        }

        const createdTrip = await create(data)
        // if trip was created successfully in the database then get the trip member
        // who created it and assign in state and navigate to planning board
        if (
          (createdTrip && createdTrip.short_uid) ||
          createdTrip.data.short_uid
        ) {
          if (!isNil(tentativeUser?.success)) {
            dispatch(setUser(tentativeUser?.data))

            itly.userCreated({
              is_tentative: !!tentativeUser?.success,
              user_uid: tentativeUser?.data?.uid,
            })
          }

          itly.tripCreated({
            is_tentative: !!tentativeUser?.success,
            destination,
          })

          const tripShortUid =
            createdTrip && createdTrip.data
              ? createdTrip.data.short_uid
              : createdTrip.short_uid

          await dispatch(setUserTripsCount(userTripsCount + 1))
          history.push(`/workspace/${tripShortUid}`, {
            trip_id: createdTrip.trip_id,
          })
        }
        sendNotification(CONSTANTS.TRIP_CREATION_SUCCESS, 'success')
      } catch (error) {
        const { message } =
          (await (error as HTTPError)?.response?.json()) || error
        sendNotification(message, 'error')
        console.log('error occured: ', error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (!!refresh_token_error_message) {
      dispatch(resetAction())
      sendNotification(CONSTANTS.EXPIRED_REFRESH_TOKEN_ERROR, 'error')
      // hack: clear query string after showing message
      history.push('/create-trip')
    }
  }, [refresh_token_error_message, dispatch, history])

  useEffect(() => {
    mixpanel.track_pageview({
      page: '/create-trip',
      $latitude: coordinates?.latitude,
      $longitude: coordinates?.longitude,
    })
  }, [])

  return (
    <>
      <Title style={{ fontSize: cardTitleHeadingSize }} gutterBottom>
        {CONSTANTS.CREATE_TRIP_PAGE_CREATE_NEW_TRIP}
      </Title>
      <Formik
        initialValues={{
          startDate: new Date(),
          endDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
          googleSearchQuery: {
            destination: '',
            place_id: '',
            lat: 0,
            lng: 0,
            sw_bounds: [0, 0] as LatLng,
            ne_bounds: [0, 0] as LatLng,
          },
        }}
        onSubmit={async ({ startDate, endDate, googleSearchQuery }) => {
          await handleCreateTrip({
            startDate,
            endDate,
            googleSearchQuery,
          })
        }}>
        {({ submitForm, errors, touched, setFieldValue }) => (
          <FormStyled
            style={{ padding: formPadding }}
            data-testid="create-trip-form"
            noValidate>
            <Label>{CONSTANTS.CREATE_NEW_TRIP_LABEL_DESTINATION}</Label>
            <Field
              component={GooglePlacesSearchBar}
              id="googleSearchQuery"
              name="googleSearchQuery"
              autoFocus={!tabletMobileViewGeneral}
              validate={validateDestination}
              errors={errors}
              touched={touched}
              inputPlaceholder={CONSTANTS.CREATE_NEW_TRIP_INPUT_PLACEHOLDER}
            />
            {!isTabletView ? (
              <Row>
                <Label>{CONSTANTS.CREATE_NEW_TRIP_LABEL_DATES}</Label>
                <DatesContainer>
                  <DatePicker
                    setFieldValue={setFieldValue}
                    formikKey="startDate"
                    startDateVisual={startDateVisual}
                    setStartDateVisual={setStartDateVisual}
                    endDateVisual={endDateVisual}
                    setEndDateVisual={setEndDateVisual}
                    displayDate={startDateVisual}
                  />
                  <DatePicker
                    setFieldValue={setFieldValue}
                    formikKey="endDate"
                    startDateVisual={startDateVisual}
                    setStartDateVisual={setStartDateVisual}
                    endDateVisual={endDateVisual}
                    setEndDateVisual={setEndDateVisual}
                    displayDate={endDateVisual}
                  />
                </DatesContainer>
              </Row>
            ) : (
              <div style={{ marginTop: '1rem' }}>
                <Label>{CONSTANTS.CREATE_NEW_TRIP_LABEL_DATES}</Label>
                <Row>
                  <DatesContainer>
                    <DatePicker
                      fullWidth
                      setFieldValue={setFieldValue}
                      formikKey="startDate"
                      startDateVisual={startDateVisual}
                      setStartDateVisual={setStartDateVisual}
                      endDateVisual={endDateVisual}
                      setEndDateVisual={setEndDateVisual}
                      displayDate={startDateVisual}
                    />
                  </DatesContainer>
                </Row>
                <Row>
                  <DatesContainer>
                    <DatePicker
                      fullWidth
                      setFieldValue={setFieldValue}
                      formikKey="endDate"
                      startDateVisual={startDateVisual}
                      setStartDateVisual={setStartDateVisual}
                      endDateVisual={endDateVisual}
                      setEndDateVisual={setEndDateVisual}
                      displayDate={endDateVisual}
                    />
                  </DatesContainer>
                </Row>
              </div>
            )}

            <ButtonGroup>
              <MTButton
                data-testid="trip-search-button"
                onHandleClick={submitForm}
                disabled={loading}
                customStyles={{
                  background: loading
                    ? '#85b0ff'
                    : cssVariables.colors.primaryBlue,
                  color: 'white',
                  width: '100%',
                  height: '4rem',
                  borderRadius: '16px',
                  fontSize: '1.4rem',
                  textTransform: 'capitalize',
                  marginTop: '2rem',
                }}
                title={CONSTANTS.CREATE_TRIP_PAGE_START_PLANNING}
              />
            </ButtonGroup>
          </FormStyled>
        )}
      </Formik>
    </>
  )
}

export default CreateTripForm
