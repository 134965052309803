export const styles = () => {
    return {
        containerRoot: {
            padding: 0,
        },
        carouselItem: {
            padding: 30,
        },
    }
}

export const responsive = {
    desktop: {
        breakpoint: { max: 3920, min: 1024 },
        items: 3,
        slidesToSlide: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
    },
}