/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback, useRef } from 'react'

import Grid from '@material-ui/core/Grid'
import { UnsplashImageCredit } from 'components'
import { useDialog } from 'contexts/DialogContext'
import { GridOrientationProvider } from 'contexts/GridOrientationContext'
import { PauseProvider } from 'contexts/PauseContext'
import { CONSTANTS } from 'core/constants'
import { useWindowSize } from 'hooks/useWindowSize'
import { isNull, isUndefined } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { Hero } from 'pages/Workspace/layouts/Hero'
import { useHistory } from 'react-router-dom'
import { Tabs, TabPanel, Tab, TabList } from 'react-tabs'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setWorkspaceTabIndex } from 'store/slices/tripItemLocationsMap'
import { isScreenSize } from 'utils/screenSzie'
import { fetchUnsplashImageData } from 'utils/unsplash'

import { TentativeUserSaveDialog } from 'pages/Workspace/layouts/TentativeUserSaveDialog'
import RouteLeavingGuard from 'router/RouteLeavingGuard'

import { Explore } from './layouts/Explore'
import { PlanningBoard } from './layouts/PlanningBoard'
import { CurrentTrip } from './layouts/PlanningBoard/types'
import { Container } from './workspace.style'

import './workspace.scss'
import 'react-tabs/style/react-tabs.css'

const Workspace = () => {
  const { height } = useWindowSize()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { openDialog, setOpenDialog } = useDialog()
  const { user } = useAppSelector(state => state.user)
  const [currentTrip, setCurrentTripCallback] = useState<CurrentTrip>()
  const [skipGuard, setSkipGuard] = useState(false)
  const [unsplashImage, setUnsplashImage] = useState(null)
  const [mapExpanded, setMapExpanded] = useState<boolean>(false)
  const [unsplashLink, setUnsplashLink] = useState<JSX.Element>()
  const [isPlanningBoardTabSelected, setIsPlanningBoardTabSelected] =
    useState(true)
  const coordinates = useAppSelector(state => state.geoLocation)
  const getUnsplashImage = useCallback(async () => {
    const { imageUrl, linkUrl } = await fetchUnsplashImageData()
    setUnsplashImage(imageUrl)
    setUnsplashLink(linkUrl)
  }, [])

  // edge case: if user is signed in to the app but then goes and clears cookies
  // the request below should first check if user_uid is present in local storage
  // before initiatiating any sort of request to the backend. If it so happens that
  // cookies/localstorage were cleared then redirect user to the dashboard and
  // make them signin in again to regenerate their localstorage data and a new set
  // of cookies.
  if (isNull(user?.uid)) {
    history.push('/create-trip')
    return null
  }

  useEffect(() => {
    if (isNull(user?.uid)) {
      history.push('/create-trip')
    }
    getUnsplashImage()
  }, [getUnsplashImage, history, user])

  let planningBoardTabTitle = CONSTANTS.TABS_PLANNING_BOARD
  let itenaryTabTitle = CONSTANTS.TABS_ITINERARY

  const isScreenSizeEquals500 = isScreenSize(500)
  const isScreenSizeEquals310 = isScreenSize(310)

  if (isScreenSizeEquals500) {
    planningBoardTabTitle = 'Planning'
    itenaryTabTitle = 'Itinerary'
  }
  if (isScreenSizeEquals310) {
    planningBoardTabTitle = 'Plan'
  }

  const tripFormRef = useRef(null)

  useEffect(() => {
    mixpanel.track_pageview({
      page: '/workspace',
      $latitude: coordinates?.latitude,
      $longitude: coordinates?.longitude,
    })
  }, [])
  return (
    <Container>
      <Tabs onSelect={index => dispatch(setWorkspaceTabIndex(index))}>
        <Grid
          container
          style={{
            backgroundImage: `url(${unsplashImage})`,
            backgroundSize: '100%',
          }}
          spacing={2}>
          <Grid className="p-0" item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{ marginTop: '30px', marginLeft: '30px' }}
              ref={tripFormRef}>
              {currentTrip && currentTrip?.trips_members?.length > 0 && (
                <Hero
                  currentTrip={currentTrip}
                  setCurrentTripCallback={setCurrentTripCallback}
                />
              )}
            </div>
            <TabList
              className="react-tabs__tab-list--workspace"
              style={{
                display: 'flex',
                backgroundSize: '100%',
              }}>
              <Grid container /* spacing={24} */>
                <Grid item xs={12}>
                  <div style={{ display: 'flex' }}>
                    <Tab
                      onClick={() => setIsPlanningBoardTabSelected(true)}
                      className="react-tabs__tab"
                      selectedClassName="react-tabs__tab--selected">
                      {planningBoardTabTitle}
                    </Tab>
                    <Tab
                      onClick={() => setIsPlanningBoardTabSelected(false)}
                      className="react-tabs__tab"
                      selectedClassName="react-tabs__tab--selected">
                      {CONSTANTS.TABS_EXPLORE}
                    </Tab>
                  </div>
                </Grid>
              </Grid>
            </TabList>
          </Grid>
        </Grid>

        <PauseProvider>
          <GridOrientationProvider>
            <TabPanel>
              <PlanningBoard
                isPlanningBoardTabSelected={isPlanningBoardTabSelected}
                setCurrentTripCallback={setCurrentTripCallback}
                screenHeight={height}
                mapExpanded={mapExpanded}
                setMapExpanded={setMapExpanded}
                setSkipGuard={setSkipGuard}
                tripFormRef={tripFormRef}
              />
            </TabPanel>
            <TabPanel>
              {currentTrip && (
                <Explore
                  currentTrip={currentTrip}
                  mapExpanded={mapExpanded}
                  setMapExpanded={setMapExpanded}
                />
              )}
            </TabPanel>
            <TabPanel />
          </GridOrientationProvider>
        </PauseProvider>
      </Tabs>
      {!isUndefined(user?.is_tentative) && !skipGuard && (
        <RouteLeavingGuard
          userUid={user?.uid}
          shortUid={currentTrip?.short_uid}
          navigate={(path: string) => history.push(path)}
          shouldBlockNavigation={(location: any) =>
            (!location.pathname.includes('workspace') && !location.pathname.includes('signin') && !location.pathname.includes('signup'))
          }
        />
      )}
      {openDialog.show && (
        <TentativeUserSaveDialog
          open={openDialog.show}
          setOpenTentativeUserDialog={setOpenDialog}
          type={openDialog.type}
        />
      )}
      <UnsplashImageCredit
        unsplashLink={unsplashLink}
        rightOffset={32}
        topOffset={10}
      />
    </Container>
  )
}

export default Workspace
