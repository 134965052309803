/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useCallback, useState } from 'react'

import { NativeSelect } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CONSTANTS } from 'core/constants'
import { Member } from 'pages/Workspace/layouts/MembersListRow/components/Member'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useAppSelector } from 'store/hooks'
import { can, TRIP_CHANGE_ROLE } from 'utils/permissions'
import { sendNotification } from 'utils/toast'
import { useHistory } from 'react-router-dom'

import { update, remove } from 'api/tripMember'
import { find } from 'api/tripMemberInvite'

import { InvitedMembers } from './components/InvitedMembers'
import { styles } from './membersList.styles'
import CustomToolTip from 'components/CustomTooltip/CustomTooltip'

const useStyles = makeStyles(theme => styles(theme))

const MembersList = ({
  currentTrip,
  setFetchTripOnInvite,
  fetchTripOnInvite,
  setCurrentTripCallback,
  invitedMembers,
  setInvitedMembers,
}) => {
  const classes = useStyles()
  const { user } = useSelector(state => state.user)
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)
  const canEditRole = can(currentTrip.trips_roles[0].role, TRIP_CHANGE_ROLE)
  const history = useHistory()

  /**
   * Update trip member's role to viewer or editor
   *
   * @param {String} role new role
   * @param {String} member_uid trip member ui
   */
  const onUpdateTripMemberRole = async (role, member_uid, user_uid = null) => {
    role = role.target.value
    if (role === 'Remove') {
      if (user_uid === user.user_uid) {
        return await onRemoveTripMember(member_uid).then(() => {
          history.push('/')
        })
      } else {
        return await onRemoveTripMember(member_uid)
      }
    }
    const editors = invitedMembers
      .map(member => {
        return member.role === 'EDITOR'
      })
      .filter(Boolean)

    const handlePurchasePlanClick = () => {
      sessionStorage.setItem('scrollToPayment', 'true')
      toast.remove()
      history.push('/profile') // Navigate to the purchase plan page
    }

    if (role === 'Editor') {
      if (userPlan.plan.name === CONSTANTS.FREE_PLAN && editors.length >= 2) {
        sendNotification(
          <span>
            Please{' '}
            <span
              style={{
                color: '#0d6efd',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={handlePurchasePlanClick}>
              purchase plan
            </span>{' '}
            to add more Editors!
          </span>,
          'error',
        )
        return
      }
      if (
        userPlan.plan.name === CONSTANTS.PAY_PER_TRIP &&
        editors.length >= 5
      ) {
        sendNotification(
          <span>
            Please{' '}
            <span
              style={{
                color: '#0d6efd',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={handlePurchasePlanClick}>
              purchase plan
            </span>{' '}
            to add more Editors!
          </span>,
          'error',
        )
        return
      }
    }
    try {
      const tripMemberUpdatedRole = await update({
        user_uid: user.uid,
        uid: member_uid,
        short_uid: currentTrip.short_uid,
        role,
      })

      if (tripMemberUpdatedRole.role.toLowerCase() === role.toLowerCase()) {
        const updated_trip_members = [...currentTrip.trips_members]
        updated_trip_members[
          currentTrip.trips_members.findIndex(m => m.uid == member_uid)
        ].role = role.toUpperCase()
        setCurrentTripCallback({
          ...currentTrip,
          trips_members: updated_trip_members,
        })
        sendNotification(CONSTANTS.TRIP_MEMBER_ROLE_CHANGE_SUCCESS, 'success')
      }
    } catch (error) {
      sendNotification(CONSTANTS.ERROR_GENERAL, 'error')
    }
  }

  const onRemoveTripMember = async member_uid => {
    try {
      const res = await remove({
        uid: member_uid,
        short_uid: currentTrip.short_uid,
        user_uid: user.uid,
        run_on_trip_member_removal_only: true,
      })

      if (res.deleted === true) {
        sendNotification(
          CONSTANTS.COLLABORATORS_MODAL_MEMBER_REMOVE_MEMBER_SUCCESS,
          'success',
        )
        setCurrentTripCallback({
          ...currentTrip,
          trips_members: currentTrip.trips_members.filter(
            t => t.uid != member_uid,
          ),
        })
      }
    } catch (error) {
      sendNotification(
        CONSTANTS.COLLABORATORS_MODAL_MEMBER_REMOVE_MEMBER_ERROR,
        'error',
      )
    }
  }

  //Handle all abnormal users in the system - There are users in the system having no email, this creates issues
  const membersWithEmail = currentTrip.trips_members
    .filter(member => member.user_email)
    .sort((a, b) => {
      return a?.user_first_name?.localeCompare(b?.user_first_name) ?? true
    })

  let all_roles = ['Editor', 'Viewer', 'Remove']
  let roles = ['Viewer', 'Remove']
  let AdminRole = ['Admin']
  return (
    <>
      {!_.isNil(membersWithEmail) && !user?.is_trip_creator ? (
        <div
          id="customstylingdiv"
          style={{ maxHeight: '70dvh', overflow: 'auto', marginBottom:'10px' }}
          >
          <div data-testid="trip-members-list">
            {invitedMembers?.length > 0 && (
              <InvitedMembers
                invitedMembers={invitedMembers}
                currentTrip={currentTrip}
                setInvitedMembers={setInvitedMembers}
              />
            )}
            <p className={classes.descriptionText}>
              {' '}
              {membersWithEmail.length}{' '}
              {membersWithEmail.length > 1 ? 'members' : 'member'} in this trip
            </p>
            {membersWithEmail.map(member => {
              const displayName =
                member.user_first_name && member.user_last_name
                  ? `${member?.user_first_name} ${member?.user_last_name}`
                  : member.user_email
              return (
                <div
                  key={member.member_uid}
                  className={classes.memberContainer}
                  data-testid="trip-member-row">
                  <div className={classes.memberDetailsContainer}>
                    <Member
                      key={Math.floor(Math.random() * 100)}
                      name={`${member?.user_first_name} ${member?.user_last_name}`}
                      displayName={displayName.substring(0, 1).toUpperCase()}
                    />
                    <CustomToolTip title={displayName}>
                      <p className={classes.memberName}>{displayName}</p>
                    </CustomToolTip>
                  </div>
                  <CustomToolTip title="Edit role" showonClick={false}>
                    <select
                      key={member.member_uid}
                      id={member.member_uid}
                      data-testid="trip-member-role-select"
                      disableUnderline={true}
                      disabled={
                        member.role === 'ADMIN' ||
                        (!canEditRole && member.user_uid !== user.user_uid)
                      }
                      classes={{ select: classes.select }}
                      className={classes.membersRolesDropdown}
                      name="memberRole"
                      defaultValue={
                        member.role.charAt(0) +
                        member.role.slice(1).toLowerCase()
                      }
                      value={
                        member.role.charAt(0) +
                        member.role.slice(1).toLowerCase()
                      }
                      onChange={e => {
                        onUpdateTripMemberRole(e, member.uid, member.user_uid)
                      }}>
                      {(member.role === 'ADMIN'
                        ? [...AdminRole]
                        : currentTrip.trips_roles[0].role === 'VIEWER' &&
                          member.user_uid === user.user_uid
                        ? [...roles]
                        : [...all_roles]
                      ).map(item => (
                        <option
                          key={item}
                          value={item}
                          className={classes.option}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </CustomToolTip>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default MembersList
