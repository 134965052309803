import React from 'react'

import { Dialog, makeStyles } from '@material-ui/core'
import { MTButton } from 'components/MTButton'
import { CONSTANTS } from 'core/constants'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppSelector, useGeolocation, CoordsObject } from 'store/hooks'
import { cssVariables } from 'theme'
import { paddleCheckout } from 'utils/paddle'
import { isScreenSize } from 'utils/screenSzie'

import { getUserPayment } from 'api/userPlans'
import { PlusCircleIcon, AlertCircleIcon, XIcon } from 'components/MTIcons'

import Plans from '../../layouts/Auth/layouts/Profile/subscription/Plans'
import { closeButtonStyle } from '../MTDialog/helper'
import {
  style,
  Header,
  HeaderTitle,
  HR,
  Body,
} from '../MTDialog/mTDialog.style'
import { useWindowSize } from 'hooks/useWindowSize'

type StartTripButtonProps = {
  title: string
  bgCol?: string
  textCol: string
  width: number | string
}

const StartTripButton = ({
  title,
  bgCol = cssVariables.colors.primaryBlue,
  textCol,
  width,
}: StartTripButtonProps) => {
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const location = useLocation()
  const userPlan = useAppSelector(state => state.userPlanSlice.userPlan)
  const { user } = useAppSelector(state => state.user)
  const otherPlans = useAppSelector(state => state.userPlanSlice.otherPlans)
  const extraSmallScreen = isScreenSize(350)
  const smallScreen = isScreenSize(600)
  const styledProps = {
    padding: extraSmallScreen ? 15 : 25,
    margin: extraSmallScreen ? '15px' : '32px',
  }
  const dispatch = useDispatch()
  const coordinates = useAppSelector(state => state.geoLocation)
  const {width: screenWidth}  = useWindowSize()
  const useStyles = makeStyles(() => style(styledProps))
  const classes = useStyles()
  const userTripsCount = useAppSelector(
    state => state.userPlanSlice.userTripsCount,
  )

  const onHandleClick = async () => {
    // alert(`userTripsCount is ---- ${userTripsCount}`)
    if (userTripsCount < 1) {
      history.push('/create-trip', { from: location.pathname })
      return
    }
    if (userPlan?.plan?.name === CONSTANTS.FREE_PLAN && userTripsCount >= 1) {
      handleOpen()
      return
    }
    if (userPlan?.plan?.name === CONSTANTS.PAY_PER_TRIP) {
      const data = {
        uid: user.uid,
        is_recurring: false,
      }
      const payment = await getUserPayment(data)
      if (payment && Object.keys(payment).length > 0) {
        history.push('/create-trip', { from: location.pathname })
        return
      } else {
        paddleCheckout(
          userPlan?.plan?.name,
          userPlan?.plan?.uid,
          user.uid,
          otherPlans,
          userPlan?.plan?.paddlePriceId,
          history,
          dispatch,
          user.email,
          user.firstName+' '+user.lastName,
          null,
          true,
          false,
          coordinates,
        )
        return
      }
    }
    if (
      userPlan?.plan?.name === CONSTANTS.MONTHLY_SUBSCRIPTION ||
      userPlan?.plan?.name === CONSTANTS.ANNUAL_SUBSCRIPTION
    ) {
      history.push('/create-trip', { from: location.pathname })
    }
  }

  return (
    <>
      <MTButton
        customStyles={{
          backgroundColor: bgCol,
          color: textCol,
          width,
          boxShadow: 'none',
        }}
        onHandleClick={onHandleClick}
        title={title}
        customIcon={<PlusCircleIcon stroke="white" />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          maxWidth: '100% !important',
        }}
        classes={{
          paperScrollPaper: classes.paperScrollPaper,
          paper: classes.container,
          paperWidthSm: screenWidth > 700 ? classes.paperCustomWidthLg : classes.paperCustomWidthLgMobile,
        }}>
        {/* <Header style={{ display: 'smallScreen ? 'grid' : 'inherit'' }}> */}
        {/* <MTButton
            customStyles={{...closeButtonStyle}}
            onHandleClick={handleClose}
            customIcon={<XIcon />}
          /> */}
        <Header style={{ display: 'flex', flexWrap: 'wrap'}}>
          {/* <AlertCircleIcon stroke="#E6CB4B" /> */}
          <HeaderTitle
            style={{ fontSize: smallScreen ? '1.8rem' : '2rem' }}>
            Upgrade your plan
          </HeaderTitle>
          <div style={closeButtonStyle}>
            <MTButton
              // customStyles={{...closeButtonStyle}}  const {width}  = useWindowSize()
              customStyles={{backgroundColor: 'white', boxShadow: 'none'}}
              onHandleClick={handleClose}
              customIcon={<XIcon />}
            />
          </div>
        </Header>
        <HR></HR>
        <Body>
          <div style={{ minHeight: 'fit-content' }}>
            <strong>
              To plan a new trip aside from your existing free trip, please
              purchase your plan
            </strong>
            <Plans
              handleClose={handleClose}
              otherPlans={otherPlans}
              isPopup={true}
            />
          </div>
        </Body>
      </Dialog>
      <div className="checkout-container"></div>
    </>
  )
}

export default StartTripButton
