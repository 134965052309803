/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useRef, useState } from 'react'

import { MenuItem } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { MTDialog } from 'components'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { Formik, Form } from 'formik'
import { DatePicker } from 'formik-antd'
import { HTTPError } from 'ky'
import { isNil } from 'lodash'
import moment from 'moment'
import type { Moment } from 'moment'
import { MembersListRow } from 'pages/Workspace/layouts/MembersListRow'
import { useHistory } from 'react-router-dom'
import { resetUserStore } from 'store'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import { setUserTripsCount } from 'store/slices/userPlanSlice'
import { setDocumentTitle } from 'utils/documentTitle'
import {
  can,
  TRIP_TITLE_EDIT,
  TRIP_DATE_START_EDIT,
  TRIP_DATE_END_EDIT,
  TRIP_DELETE,
} from 'utils/permissions'
import { isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { update, remove } from 'api/trip'
import { CalendarIcon } from 'components/MTIcons'

import { CurrentTrip } from '../PlanningBoard/types'
import { updateDates } from './helper'
import {
  CustomTextField,
  ParentContainer,
  HeroTop,
  Title,
  HeroMenuButton,
  Icon,
  MenuStyled,
  Container,
  TripDates,
  DatesForm,
  DateContainer,
  Span,
  MembersList,
} from './hero.style'

export type HeroUpdateProps = {
  startDate: Moment
  endDate: Moment
}

type Props = {
  currentTrip: CurrentTrip
  setCurrentTripCallback: (trip: any) => void
}

const Hero = ({ currentTrip, setCurrentTripCallback }: Props) => {
  const dateFormat = 'MMM DD, YYYY'
  const history = useHistory()
  const { user } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const titleRef = useRef<HTMLInputElement>(null)
  const userTripsCount = useAppSelector(
    state => state.userPlanSlice.userTripsCount,
  )
  const canEditTripTitle = can(
    currentTrip?.trips_roles[0]?.role,
    TRIP_TITLE_EDIT,
  )
  const canEditTripStartDate = can(
    currentTrip?.trips_roles[0]?.role,
    TRIP_DATE_START_EDIT,
  )
  const canEditTripEndDate = can(
    currentTrip?.trips_roles[0]?.role,
    TRIP_DATE_END_EDIT,
  )
  const canDeleteTrip = can(currentTrip?.trips_roles[0]?.role, TRIP_DELETE)

  const [openDialog, setOpenDialog] = useState(false)
  const [startDateVisual, setStartDateVisual] = useState<Date | string | null>(
    currentTrip?.start_date,
  )
  const [endDateVisual, setEndDateVisual] = useState<Date | string | null>(
    currentTrip?.end_date,
  )
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const largeScreen = isScreenSize(1100, true)

  const tabletViewScreenStarts = isScreenSize(750, true)
  const tabletViewScreenEnds = isScreenSize(1099)

  const smallScreenStarts = isScreenSize(150, true)
  const smallScreenEnds = isScreenSize(749)
  const coordinates = useAppSelector(state => state.geoLocation)

  /**
   * Open menu
   * @param { Object } event
   */
  const onHandleOpenMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget)
  }

  /**
   * Close menu
   */
  const onHandleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  /**
   * Close delete dialogue
   */
  const onCloseModal = () => {
    setOpenDialog(false)
  }

  /**
   * Cancel poll deletion
   */
  const onHandleCancel = () => {
    onCloseModal()
  }

  /**
   * Show delete modal
   */
  const onHandleShowDeleteTripModal = () => {
    onHandleCloseMenu()

    if (!canDeleteTrip)
      return sendNotification(`${CONSTANTS.DELETE_ITEM_DENY}`, 'error')
    setOpenDialog(true)
  }

  /**
   * Delete trip handler
   */
  const onHandleDeleteTrip = async () => {
    try {
      const res = await remove({
        user_uid: user.uid,
        short_uid: currentTrip.short_uid,
        coordinates,
      })
      if (res) {
        sendNotification(`${CONSTANTS.TRIP_DELETE_SUCCESS}`, 'success')
        onCloseModal()
        const dropCookies = user?.is_tentative || !user?.firstName
        resetUserStore(dispatch, !!dropCookies)
        // alert(`Trip count from hero--   ${userTripsCount - 1} `)

        await dispatch(setUserTripsCount(userTripsCount - 1))
        history.push('/create-trip')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Update trip title.
   * @param {String} title trip title
   * @param {String} destination trip destination
   */
  const onHandleUpdateTitle = async (title: string, destination: string) => {
    // if title is blank or undefined, create default title
    if (typeof title === 'undefined' || title === '') {
      title = `Trip to ${destination}`
    }

    try {
      const res = await update({
        uid: currentTrip.uid,
        title,
        user_uid: user.uid,
        short_uid: currentTrip.short_uid,
      })
      if (res) {
        setDocumentTitle(title)
        if (titleRef) titleRef?.current?.blur()
        sendNotification(CONSTANTS.HERO_TITLE_SUCCESSFULLY_UPDATED, 'success')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  /**
   * Update trip start and end dates.
   * @param {Dates} data trip start and end date
   */
  const onHandleUpdateDates = async (data: HeroUpdateProps) => {
    const { startDate, endDate } = data

    try {
      const res = await update({
        uid: currentTrip.uid,
        start_date: startDate,
        end_date: endDate,
        user_uid: user.uid,
        short_uid: currentTrip.short_uid,
      })

      if (res) {
        sendNotification(CONSTANTS.HERO_DATES_SUCCESSFULLY_UPDATED, 'success')
      }
    } catch (error) {
      const e = await (error as HTTPError)?.response?.json()
      const message = e?.message || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  return (
    <Grid item xl={5} lg={5} md={6} sm={8} xs={11}>
      <ParentContainer
        style={
          {
            //position: 'absolute',
          }
        }>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Formik
            enableReinitialize
            initialValues={{
              title: currentTrip.title || '',
            }}
            onSubmit={async ({ title }) => {
              await onHandleUpdateTitle(title, currentTrip.destination)
            }}>
            {({ handleSubmit, values, handleChange }) => (
              <Form
                noValidate
                onKeyDown={e => {
                  if ((e.charCode || e.keyCode) === 13) {
                    e.preventDefault()
                    handleSubmit()
                  }
                }}>
                <HeroTop>
                  <Grid container spacing={2}>
                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                      <Title
                        component={CustomTextField}
                        disabled={canEditTripTitle ? false : true}
                        spellCheck={false}
                        name="title"
                        placeholder="Trip Title"
                        innerRef={titleRef}
                        inputProps={{
                          maxLength: 100,
                        }}
                        onChange={handleChange('title')}
                        value={values.title || ''}
                        helperText={
                          values.title.length === 100
                            ? 'Title cannot be longer than 100 characters!'
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      {!user?.is_tentative && (
                        <>
                          {/* <HeroMenuButton onClick={onHandleOpenMenu} /> */}
                          <HeroMenuButton onClick={onHandleOpenMenu}>
                            <Icon />
                          </HeroMenuButton>
                          <MenuStyled
                            MenuListProps={{
                              style: {
                                padding: 0,
                              },
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            anchorEl={menuAnchorEl}
                            disableScrollLock
                            disableRestoreFocus
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={onHandleCloseMenu}
                            elevation={3}>
                            <MenuItem onClick={onHandleShowDeleteTripModal}>
                              Delete Trip
                            </MenuItem>
                          </MenuStyled>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </HeroTop>
              </Form>
            )}
          </Formik>

          <Container>
            <TripDates>
              {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}> */}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    startDate: moment(currentTrip.start_date),
                    endDate: moment(currentTrip.end_date),
                  }}
                  onSubmit={async data => await onHandleUpdateDates(data)}>
                  {({ values, setFieldValue, handleSubmit }) => (
                    <Fragment>
                      <DatesForm>
                        <DateContainer>
                          {/* <div style={{ marginRight: 5 }}>
                        <CalendarIcon />
                      </div> */}
                          <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              {largeScreen && (
                                <div>
                                  <Grid id="top-row" container>
                                    <div
                                      style={{ marginRight: 5, marginTop: 6 }}>
                                      <CalendarIcon />
                                    </div>
                                    <Grid
                                      style={{ marginRight: '3px' }}
                                      item
                                      xs={3}>
                                      <DatePicker
                                        name="startDate"
                                        value={moment(startDateVisual)}
                                        format={dateFormat}
                                        disabled={!canEditTripStartDate}
                                        onChange={startDate => {
                                          if (isNil(startDate))
                                            startDate = moment(
                                              currentTrip.start_date,
                                            )
                                          return updateDates({
                                            updatedValue: 'startDate',
                                            startDate: startDate,
                                            endDate: values.endDate,
                                            setFieldValue,
                                            setStartDateVisual,
                                            setEndDateVisual,
                                            handleSubmit,
                                          })
                                        }}
                                      />
                                    </Grid>
                                    <div>
                                      <Span>-</Span>
                                    </div>
                                    <Grid item xs={3}>
                                      <DatePicker
                                        name="endDate"
                                        value={moment(endDateVisual)}
                                        format={dateFormat}
                                        disabled={!canEditTripEndDate}
                                        onChange={endDate => {
                                          if (isNil(endDate))
                                            endDate = moment(
                                              currentTrip.end_date,
                                            )
                                          return updateDates({
                                            updatedValue: 'endDate',
                                            startDate: values.startDate,
                                            endDate: endDate,
                                            setFieldValue,
                                            setStartDateVisual,
                                            setEndDateVisual,
                                            handleSubmit,
                                          })
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={4}
                                      style={{
                                        maxWidth: '100%',
                                        flexGrow: 1,
                                        marginTop: '10px',
                                      }}>
                                      <div className="float-right">
                                        <MembersList>
                                          <MembersListRow
                                            currentTrip={currentTrip}
                                            setCurrentTripCallback={
                                              setCurrentTripCallback
                                            }
                                          />
                                        </MembersList>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}

                              {/* For 699 to 450  Row Start  */}

                              {tabletViewScreenStarts && tabletViewScreenEnds && (
                                <Grid id="top-row" container>
                                  <Grid item xs={6}>
                                    <Grid id="top-row" container>
                                      <Grid item xs={12}>
                                        <DatePicker
                                          name="startDate"
                                          value={moment(startDateVisual)}
                                          format={dateFormat}
                                          className="mb-2"
                                          style={{
                                            width: '-webkit-fill-available',
                                          }}
                                          disabled={!canEditTripStartDate}
                                          onChange={startDate => {
                                            if (isNil(startDate))
                                              startDate = moment(
                                                currentTrip.start_date,
                                              )
                                            return updateDates({
                                              updatedValue: 'startDate',
                                              startDate: startDate,
                                              endDate: values.endDate,
                                              setFieldValue,
                                              setStartDateVisual,
                                              setEndDateVisual,
                                              handleSubmit,
                                            })
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid id="bottom-row" container>
                                      <Grid item xs={12}>
                                        <DatePicker
                                          name="endDate"
                                          value={moment(endDateVisual)}
                                          format={dateFormat}
                                          style={{
                                            width: '-webkit-fill-available',
                                          }}
                                          disabled={!canEditTripEndDate}
                                          onChange={endDate => {
                                            if (isNil(endDate))
                                              endDate = moment(
                                                currentTrip.end_date,
                                              )
                                            return updateDates({
                                              updatedValue: 'endDate',
                                              startDate: values.startDate,
                                              endDate: endDate,
                                              setFieldValue,
                                              setStartDateVisual,
                                              setEndDateVisual,
                                              handleSubmit,
                                            })
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ marginTop: '10px' }}>
                                    <MembersList>
                                      <MembersListRow
                                        currentTrip={currentTrip}
                                        setCurrentTripCallback={
                                          setCurrentTripCallback
                                        }
                                      />
                                    </MembersList>
                                  </Grid>
                                </Grid>
                              )}
                              {/* Row end */}

                              {/* Row start */}
                              {smallScreenStarts && smallScreenEnds && (
                                <Grid id="top-row" container>
                                  <Grid item xs={12}>
                                    <Grid id="top-row" container>
                                      <Grid item xs={12}>
                                        <DatePicker
                                          name="startDate"
                                          value={moment(startDateVisual)}
                                          format={dateFormat}
                                          className="mb-2"
                                          style={{
                                            width: '-webkit-fill-available',
                                          }}
                                          disabled={!canEditTripStartDate}
                                          onChange={startDate => {
                                            if (isNil(startDate))
                                              startDate = moment(
                                                currentTrip.start_date,
                                              )
                                            return updateDates({
                                              updatedValue: 'startDate',
                                              startDate: startDate,
                                              endDate: values.endDate,
                                              setFieldValue,
                                              setStartDateVisual,
                                              setEndDateVisual,
                                              handleSubmit,
                                            })
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid id="bottom-row" container>
                                      <Grid item xs={12}>
                                        <DatePicker
                                          name="endDate"
                                          value={moment(endDateVisual)}
                                          format={dateFormat}
                                          style={{
                                            width: '-webkit-fill-available',
                                          }}
                                          disabled={!canEditTripEndDate}
                                          onChange={endDate => {
                                            if (isNil(endDate))
                                              endDate = moment(
                                                currentTrip.end_date,
                                              )
                                            return updateDates({
                                              updatedValue: 'endDate',
                                              startDate: values.startDate,
                                              endDate: endDate,
                                              setFieldValue,
                                              setStartDateVisual,
                                              setEndDateVisual,
                                              handleSubmit,
                                            })
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MembersList className="mt-2">
                                      <MembersListRow
                                        currentTrip={currentTrip}
                                        setCurrentTripCallback={
                                          setCurrentTripCallback
                                        }
                                      />
                                    </MembersList>
                                  </Grid>
                                </Grid>
                              )}
                              {/* Row End */}
                            </Grid>
                          </Grid>
                        </DateContainer>
                      </DatesForm>
                    </Fragment>
                  )}
                </Formik>
              </Grid>
            </TripDates>
          </Container>

          {openDialog && (
            <MTDialog
              openDialog={openDialog}
              onCloseModal={onCloseModal}
              onDeleteTripClickHandler={onHandleDeleteTrip}
              onCancelClickHandler={onHandleCancel}
              itemToBeDeleted={CONSTANTS.TRIP_TO_BE_DELETED}
              type={modalTypes.DELETE_ITEM}
            />
          )}
        </Grid>
      </ParentContainer>
    </Grid>
  )
}

export default Hero
