// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast'

type Type = 'success' | 'error'

export const sendNotification = (message: string, type: Type) => {
  const style = {
    boxShadow: '2px 5px 41px -7px rgba(0,0,0,0.47)',
    fontSize: '1.8rem',
  }

  type === 'success'
    ? toast.success(message, { style, duration: 7000 })
    : type === 'error'
    ? toast.error(message, { style, duration: 7000 })
    : toast(message)
}
