/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, createRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone'
import { MTButton, MTTooltip } from 'components'
import { isEmpty, isNull } from 'lodash'
import { Member } from 'pages/Workspace/layouts/MembersListRow/components/Member'
import CollaboratorsModal from 'pages/Workspace/layouts/MembersListRow/layouts/CollaboratorsModal/CollaboratorsModal'
import { TripLocationItem } from 'pages/Workspace/layouts/PlanningBoard/types'
import { cssVariables } from 'theme'

import { SendIcon } from 'components/MTIcons'

import { MembersPopover } from './layouts/MembersPopover'
import { styles, CollabModalIcon, MembersList } from './membersListRow.styles'
const useStyles = makeStyles(() => styles())

type BackgroundColor = {
  [key: number]: string
}

const backgroundColors: BackgroundColor = {
  0: '#ECF3FF',
  1: '#FEF0F2',
  2: '#FFFCEE',
  3: '#E2FFEC',
}

type Props = {
  currentTrip?: any
  setCurrentTripCallback?: (trip: any) => void
  tripItem?: TripLocationItem
  tripMembers?: any
  setFieldValue?: (key: string, value: string) => void
  canEdit?: boolean
  showEditButton?: boolean
  showEditIconOnly?: boolean
}

const MembersListRow = ({
  currentTrip = null,
  tripItem,
  tripMembers = null,
  setFieldValue,
  canEdit,
  showEditButton = true,
  showEditIconOnly = false,
  setCurrentTripCallback,
}: Props) => {
  const [openCollaboratorsModal, setOpenCollaboratorsModal] = useState(false)
  const [membersPopoverAnchorEl, setMembersPopoverAnchorEl] =
    useState<HTMLDivElement | null>(null)
  const membersPopoverOpen = Boolean(membersPopoverAnchorEl)
  const classes = useStyles()
  const membersListRef = createRef<HTMLDivElement>()
  let memberModalList

  const getEmailUsername = (email: string) => {
    const atIndex = email.indexOf('@')
    if (atIndex !== -1) {
      return email.slice(0, atIndex)
    } else {
      // Return the entire email if "@" is not found (or handle as needed)
      return email
    }
  }
  if (!isEmpty(currentTrip) && !isEmpty(currentTrip?.trips_members)) {
    memberModalList = currentTrip?.trips_members
      .filter((member: any, idx: number) => idx < 4)
      .map((member: any, idx: number) => {
        // if (!isNull(member?.user_first_name)) {
        let title =
          member?.user_first_name && member?.user_last_name
            ? `${member?.user_first_name} ${member?.user_last_name}`
            : undefined

        if (!title && member?.user_email) {
          title = getEmailUsername(member?.user_email)
        }
        if (!title) {
          //invalid user
          return
        }

        let displayName = member?.user_first_name?.substring(0, 1).toUpperCase()
        if (!displayName) {
          displayName = member?.user_email?.substring(0, 1).toUpperCase()
        }
        return (
          <MTTooltip title={title} key={idx}>
            <Member
              backgroundColor={
                backgroundColors[idx % currentTrip.trips_members.length]
              }
              name={member?.user_first_name}
              displayName={displayName}
            />
          </MTTooltip>
        )
        // }
      })
  }
  if (
    tripItem &&
    !isEmpty(tripItem?.trips_locations_items_members) &&
    tripItem?.trips_locations_items_members.length > 0
  ) {
    memberModalList = tripItem.trips_locations_items_members
      .filter((member, idx) => idx < 5)
      .map((member, idx) => {
        if (
          member.users &&
          member.users.length > 0 &&
          !isNull(member?.users[0]?.firstName)
        ) {
          return (
            <MTTooltip
              title={`${member?.users[0]?.firstName} ${member?.users[0]?.lastName}`}
              key={idx}>
              <Member
                backgroundColor={
                  backgroundColors[
                    idx % currentTrip?.trips_locations_items_members.length
                  ]
                }
                name={member?.users[0]?.firstName || ''}
                displayName={
                  !isNull(member?.users[0]?.firstName)
                    ? member?.users[0]?.firstName.substring(0, 1).toUpperCase()
                    : ''
                }
              />
            </MTTooltip>
          )
        }
      })
  }

  const onOpenModalBasedOnType = () => {
    if (!!currentTrip) setOpenCollaboratorsModal(true)
    if (!!tripItem && canEdit)
      setMembersPopoverAnchorEl(
        membersPopoverAnchorEl ? null : membersListRef.current,
      )
  }

  const handleClosePopover = () => {
    setMembersPopoverAnchorEl(null)
  }

  return (
    <MembersList ref={membersListRef}>
      {memberModalList}
      {currentTrip && currentTrip.trips_members.length > 4 && (
        <Member
          extraMembers={currentTrip.trips_members.length - 4}
          backgroundColor="#E5E5E5"
          color="#757575"
          displayName=""
        />
      )}
      {tripItem && tripItem.trips_locations_items_members.length > 4 && (
        <Member
          extraMembers={tripItem.trips_locations_items_members.length - 4}
          backgroundColor="#E5E5E5"
          color="#757575"
          displayName=""
        />
      )}
      {showEditButton && (
        <MTTooltip title={'Share trip with other travel-mates!'}>
          <MTButton
            title="Invite"
            onHandleClick={onOpenModalBasedOnType}
            customIcon={<SendIcon stroke={cssVariables.colors.primaryBlue} />}
            customStyles={{
              backgroundColor: '#ECF3FF',
              color: cssVariables.colors.primaryBlue,
              width: '100px',
              marginLeft: '15px',
              boxShadow: 'none',
            }}
          />
        </MTTooltip>
      )}
      {showEditIconOnly && (
        <MTTooltip title={'Edit travel-mates for this location'}>
          <CollabModalIcon
            classes={{
              colorDefault: classes.colorDefault,
              root: classes.root,
            }}>
            <EditTwoToneIcon
              fontSize="large"
              onClick={onOpenModalBasedOnType}
              style={{ color: '#3A3A3A' }}
            />
          </CollabModalIcon>
        </MTTooltip>
      )}
      {tripItem && (
        <MembersPopover
          membersPopoverAnchorEl={membersPopoverAnchorEl}
          membersPopoverOpen={membersPopoverOpen}
          handleClosePopover={handleClosePopover}
          tripItemMembers={tripItem.trips_locations_items_members}
          tripMembers={tripMembers}
          setFieldValue={setFieldValue}
        />
      )}
      <CollaboratorsModal
        open={openCollaboratorsModal}
        setOpen={setOpenCollaboratorsModal}
        currentTrip={currentTrip}
        setCurrentTripCallback={setCurrentTripCallback}
      />
    </MembersList>
  )
}

export default MembersListRow
