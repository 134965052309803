import React from 'react'

import { isEmpty } from 'lodash'
import { useAppSelector } from 'store/hooks'

import {
  Attachment,
  FileCopyIconStyled,
  NoteContainer,
  FileName,
} from '../TripLocationItem/tripLocationItem.style'

type Data = {
  placeId: string
  doc: string
  endDate: Date
  endTime: Date
  startDate: Date
  startTime: Date
  googlePhoto: string
  note: string
  description: string
}

type Props = {
  data: Data
  docURL: string
}

const TripNoteItem = ({ docURL, data }: Props) => {
  const { user } = useAppSelector(state => state.user)

  return (
    <>
      {!isEmpty(data.description) && (
        <NoteContainer dangerouslySetInnerHTML={{ __html: data.description }} />
      )}
      {data.doc !== '' && data.doc != null && (
        <Attachment>
          <FileCopyIconStyled />
          <FileName
            href={docURL}
            target="_blank"
            rel="noopener noreferrer"
            download>
            {data.doc.length > 30
              ? data.doc.substring(0, 30).concat('...')
              : data.doc}
          </FileName>
        </Attachment>
      )}
    </>
  )
}

export default TripNoteItem
