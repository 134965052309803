import React from 'react'

import styled from '@emotion/styled'
import { MTButton } from 'components'
import {
  RedButtonStyleProps,
  TransparentButtonStyleProps,
} from 'components/MTDialog/helper'

import { TrashIcon } from 'components/MTIcons'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`

type DeleteItemProps = {
  onDeleteTripClickHandler?: () => void
  onCancelClickHandler?: () => void
  redButtonStyle: RedButtonStyleProps
  transparentButtonStyle: TransparentButtonStyleProps
}

const DeleteItem = ({
  onDeleteTripClickHandler,
  onCancelClickHandler,
  redButtonStyle,
  transparentButtonStyle,
}: DeleteItemProps) => {
  return (
    <>
      <Container>
        <MTButton
          customStyles={redButtonStyle}
          onHandleClick={() => {
            if (onDeleteTripClickHandler) onDeleteTripClickHandler()
          }}
          title="Delete"
          customIcon={<TrashIcon stroke="white" />}
        />
        <MTButton
          customStyles={transparentButtonStyle}
          onHandleClick={() => {
            if (onCancelClickHandler) onCancelClickHandler()
          }}
          title="Cancel"
        />
      </Container>
    </>
  )
}

export default DeleteItem
