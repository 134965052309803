import React, { Fragment, useState } from 'react'

import styled from '@emotion/styled'
import { CONSTANTS } from 'core/constants'
import { Button, Form as ReactForm, FormGroup } from 'reactstrap'
import { useAppDispatch, useGeolocation } from 'store/hooks'
import { addTripGroup } from 'store/slices/tripGroupSlice'
import { sendNotification } from 'utils/toast'

import { create as tripGroupCreate } from 'api/tripGroups'

//import CustomModal from './CreateGroupModal/modal'
import {
  ModalBlock,
  ModalBody,
  ModalClose,
  ModalContainer,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from './modal.styles'
const InputStyle = styled.input`
  margin-top: 45px;
  font-family: Lato;
  font-weight: bold;
  font-size: 20px;
  margin-left: 17px;
  width: 413px;
  margin-bottom: 35px;
  border: 0;
  font-size: 2em;
  outline: 0;
  background: transparent;
  padding-bottom: 4px;
  border-bottom: 1px solid #8f8f8f;
  ::placeholder {
    color: #8f8f8f;
  }
`

const CustomModal = ({ title, children, active, hideModal }) => {
  return (
    <Fragment>
      {active && (
        <ModalBlock>
          <ModalOverlay onClick={() => hideModal()}></ModalOverlay>
          <ModalContainer>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>

              <ModalClose onClick={() => hideModal()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="12pt"
                  viewBox="0 0 329.26933 329"
                  width="19pt">
                  <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
                </svg>
              </ModalClose>
            </ModalHeader>
            <div>
              <hr />
            </div>
            <ModalBody>{children}</ModalBody>
            {/* <ModalFooter>{footer}</ModalFooter> */}
          </ModalContainer>
        </ModalBlock>
      )}
    </Fragment>
  )
}

export default function CreateGroupModal(props) {
  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(true)
  const coordinates = useGeolocation()

  const createGroup = async event => {
    event.preventDefault()
    if (!event.target?.new_group_field?.value) {
      return
    }
    try {
      const response = await tripGroupCreate({
        title: event.target?.new_group_field?.value,

        short_uid: props.currentTrip,
        color: 'blue',
        created_by: props.user.uid,
        coordinates,
      })
      if (response) {
        dispatch(addTripGroup(response.data))
        sendNotification(`${CONSTANTS.TRIP_GROUP_CREATE_SUCCESS}`, 'success')
      }
    } catch (error) {
      sendNotification('Error occured while creating new group!', 'error')
    }
    props.setActive(false)
    setIsDisabled(true)
  }
  return (
    <div>
      <CustomModal
        active={props.active}
        hideModal={() => props.setActive(false)}
        title="Create Group">
        <ReactForm
          style={{
            marginTop: '10px',
          }}
          onSubmit={createGroup}>
          <FormGroup>
            <div style={{ display: 'flex' }}>
              <svg
                width="60"
                height="60"
                style={{ marginBottom: '12px' }}
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect width="60" height="60" rx="6" fill="#ECF3FF" />
                <rect
                  x="28.5"
                  y="18"
                  width="3"
                  height="24"
                  rx="1.5"
                  fill="#3A3A3A"
                />
                <rect
                  x="18"
                  y="31.5"
                  width="3"
                  height="24"
                  rx="1.5"
                  transform="rotate(-90 18 31.5)"
                  fill="#3A3A3A"
                />
              </svg>

              <InputStyle
                type="text"
                onChange={e => {
                  if (e.target.value.length > 0) {
                    setIsDisabled(false)
                  } else {
                    setIsDisabled(true)
                  }
                }}
                name="new_group_field"
                placeholder="Group Name"
                style={{ marginTop: 12 }}
              />
            </div>
          </FormGroup>
          <Button
            type="submit"
            primary
            disabled={isDisabled}
            style={{
              fontWeight: 'bold',
              outlineColor: 'red',
              fontSize: '1.7rem',
              background: '#007bff',
              float: 'right',
              width: '100px',
              height: '40px',
              border: 'none',
              borderRadius: '9px',
            }}>
            Create
          </Button>
        </ReactForm>
      </CustomModal>
    </div>
  )
}
