import React from 'react'

import { DatePicker as DatePickerAntD } from 'formik-antd'
import { isNil } from 'lodash'
import moment from 'moment'

import { updateDates } from './helper'

type DatePickerProps = {
  fullWidth?: boolean
  formikKey: string
  startDateVisual: Date
  endDateVisual: Date
  displayDate: Date
  setFieldValue: (key: string, val: Date) => void
  setStartDateVisual: (date: Date) => void
  setEndDateVisual: (date: Date) => void
}

const DatePicker = ({
  fullWidth = false,
  formikKey,
  startDateVisual,
  endDateVisual,
  displayDate,
  setFieldValue,
  setStartDateVisual,
  setEndDateVisual,
}: DatePickerProps) => {
  const dateFormat = 'MMM DD, YYYY'

  return (
    <DatePickerAntD
      name={formikKey}
      style={fullWidth ? { width: '100%' } : {}}
      value={moment(displayDate)}
      format={dateFormat}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(date: any) => {
        if (isNil(date) && formikKey === 'startDate') date = new Date()
        if (isNil(date) && formikKey === 'endDate')
          date = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
        return updateDates({
          date,
          formikKey,
          startDate: startDateVisual,
          endDate: endDateVisual,
          setFieldValue,
          setStartDateVisual,
          setEndDateVisual,
        })
      }}
    />
  )
}

export default DatePicker
