import React from 'react'

import { MTAvatar } from 'components/MTAvatar'
import { CONSTANTS } from 'core/constants'
import { isNull } from 'lodash'

import {
  VoteContainer,
  VotesList,
  Voters,
  ListItemContainer,
} from './pollOptionVoters.style'
import CustomToolTip from 'components/CustomTooltip/CustomTooltip'

type User = {
  firstName: string
  lastName: string
  email: string
}

type Props = {
  options: Array<User>
}


const backgroundColors: string [] = [
 '#ECF3FF',
 '#FEF0F2',
 '#FFFCEE',
 '#E2FFEC'
]


const PollOptionVoters = ({ options }: Props) => {
  const height = '24px'
  const width = '24px'
  const fontSize = '12px'

  let custom_voter_to_render = options
  let extra_users = null
  if(custom_voter_to_render.length > 4){
    custom_voter_to_render = options.slice(0, 4)
    extra_users = options.slice(4, options.length)
  }
  return (
    <VoteContainer onClick={(e) => e.stopPropagation()}>
      <VotesList>
        <ListItemContainer>
          <Voters style={{ marginRight: '15px' }}>
            <span>
              {options.length}{' '}
              {options.length == 1
                ? CONSTANTS.SINGLE_VOTE_UNIT
                : CONSTANTS.MULTIPLE_VOTES_UNIT}
            </span>
          </Voters>
          <div style={{ display: 'flex', justifyContent: 'flex-end', maxHeight: 'fit-content' }}>
            {custom_voter_to_render.map((user, idx) => (
              <CustomToolTip
                key={idx}
                title = {(user.firstName && user.lastName) ? (user.firstName + ' ' + user.lastName) : user.email}
                customStyles={{ marginLeft: '-7px' }}>
                <div>
                <MTAvatar
                  fontSize={fontSize}
                  height={height}
                  width={width}
                  key={idx}
                  backgroundColor = {backgroundColors[idx  % backgroundColors.length]}
                  displayName={
                    !isNull(user.firstName)
                      ? user.firstName.substring(0, 1).toLocaleUpperCase()
                      : ''
                  }
                  verticalAnimation={false}
                />
                </div>
              </CustomToolTip>
            ))}
            { extra_users &&
            <CustomToolTip
              key={options.length}
              title={extra_users.map(u => (u.firstName && u.lastName) ? (u.firstName + ' ' + u.lastName) : u.email)}
              customStyles={{ marginLeft: '-7px' }}>
              <div onClick={(e) => e.stopPropagation()}>
              <MTAvatar
                fontSize={extra_users.length > 9 ? '9px' : fontSize}
                height={height}
                width={width}
                key={options.length}
                backgroundColor = '#E5E5E5'
                displayName={'+' + extra_users.length}
                verticalAnimation={false}
              />
              </div>
            </CustomToolTip>
}
          </div>
        </ListItemContainer>
      </VotesList>
    </VoteContainer>
  )
}

export default PollOptionVoters
