/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { LocationData } from 'pages/Workspace/layouts/Map/layouts/MapInfo/MapInfo'
import {
  TripLocationItem,
  TripMember,
} from 'pages/Workspace/layouts/PlanningBoard/types'
import { tabletMobileScreenSize } from 'utils/screenSzie'

import { ItemPopupFormikValues } from '../../ItemPopup'
import { LocationItemPopupDetails } from './layouts/LocationItemPopupDetails'
import { LeftContainer, LocationItemContainer, MapContainer } from './locationItemPopup.style'
import { Map } from 'pages/Workspace/layouts/Map'

type Props = {
  setFieldValue: (key: any, val: any) => void
  handleChange: (val: string) => void
  setMyFiles: (files: Array<File>) => void
  values: ItemPopupFormikValues
  tripItem: TripLocationItem
  tripMembers: TripMember[]
  myFiles: Array<File>
  docURL: string | null
  canEdit: boolean
  selectedPlace: LocationData
  placePhotos: Array<string>
}

const LocationItemPopup = ({
  setFieldValue,
  values,
  handleChange,
  tripItem,
  tripMembers,
  docURL,
  myFiles,
  setMyFiles,
  canEdit,
  selectedPlace,
  placePhotos,
}: Props) => {
  const tabletMobileView = tabletMobileScreenSize()

  return (
    <LocationItemContainer>
      <LeftContainer>
        <LocationItemPopupDetails
          setFieldValue={setFieldValue}
          values={values}
          handleChange={handleChange}
          tripItem={tripItem}
          tripMembers={tripMembers}
          docURL={docURL}
          myFiles={myFiles}
          setMyFiles={setMyFiles}
          canEdit={canEdit}
        />
      </LeftContainer>
      {tabletMobileView && (
        <MapContainer>
          <Map
            mapExpanded={false}
            isLocationItem={true}
            locationData={selectedPlace}
            showMapInfo={true}
            showExpandButton={false}
          />
        </MapContainer>
      )}
    </LocationItemContainer>
  )
}

export default LocationItemPopup
