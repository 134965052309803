import { usernameRegex, passwordRegex } from 'utils/validators'
import * as Yup from 'yup'

export const signUpValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required.'),
  lastName: Yup.string().trim().required('Required.'),
  username: Yup.string()
    .min(7, 'Too Short.')
    .max(32, 'Too Long.')
    .required(
      'Username can only contain letters, numbers, dashes and underscores and should be 7 - 32 characters.',
    )
    .matches(
      usernameRegex,
      'Username can only contain letters, numbers, dashes and underscores and should be min. 7 characters.',
    ),
  email: Yup.string().email('Invalid email').required('Required'),
  heard_about_us: Yup.string().trim().required('Required.'),

  password: Yup.string()
    .min(8, 'Too Short.')
    .max(32, 'Too Long.')
    .required(
      'Password must be min. 8 characters and contain a mix of uppercase, lowercase and special characters.',
    )
    .matches(
      passwordRegex,
      'Password must be min. 8 characters and contain a mix of uppercase, lowercase and special characters.',
    ),
})
