import React, { useEffect, useState } from 'react'

import {
  makeStyles,
  Card,
  Typography,
  CardContent,
  CardMedia,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { MTDialog } from 'components/MTDialog'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { Member } from 'pages/Workspace/layouts/MembersListRow/components/Member'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  useAppDispatch,
  useAppSelector,
  useGeolocation,
  CoordsObject,
} from 'store/hooks'
import { setUserTripsCount } from 'store/slices/userPlanSlice'
import { getGooglePlaceInformation } from 'utils/googlePlace'
import { can, TRIP_DELETE } from 'utils/permissions'
import { isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { remove } from 'api/trip'

import {
  HeroMenuButton,
  Icon,
} from '../../../Workspace/layouts/Hero/hero.style'
import { styles } from './card.styles'
import { formatDates, updatedAt, fallBackImage } from './helper'

const useStyles = makeStyles(() => styles())

const DashboardCard = ({
  trip,
  carousel = false,
  firstTrip = false,
  getUserTrips,
}) => {
  const classes = useStyles()
  const { startDate, endDate } = formatDates(trip)
  const userTripsCount = useAppSelector(
    state => state.userPlanSlice.userTripsCount,
  )
  const dispatch = useAppDispatch()

  const lastModified = updatedAt(trip)
  const destinationName =
    trip?.title.length > 40
      ? trip?.title.substring(0, 40).concat('..')
      : trip?.title

  const canDeleteTrip = !_.isEmpty(trip.trips_roles)
    ? can(trip?.trips_roles[0].role, TRIP_DELETE)
    : false
  const { user } = useSelector(state => state.user)

  const [placeHeroPhoto, setPlaceHeroPhoto] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const coordinates = useGeolocation()

  let smallScreen = isScreenSize(850)

  let allMembers
  if (trip.trips_members) {
    allMembers = trip.trips_members.map((member, ind) => {
      let displayName = member?.user_first_name?.substring(0, 1)
      if (!displayName) {
        displayName = member?.user_email
          ? member?.user_email?.substring(0, 1)
          : ''
      }
      if (!displayName) {
        //invalid user
        return
      }
      return (
        <Member
          key={ind}
          verticalAnimation={false}
          image={member.image}
          name={`${displayName}`}
          displayName={`${displayName}`}
        />
      )
    })
  }

  /**
   * Open menu
   * @param { Object } event
   */
  const onHandleOpenMenu = event => {
    setMenuAnchorEl(event.currentTarget)
  }

  /**
   * Close menu
   */
  const onHandleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  /**
   * Show delete modal
   */
  const onHandleShowDeleteTripModal = () => {
    onHandleCloseMenu()
    if (!canDeleteTrip)
      return sendNotification(`${CONSTANTS.DELETE_ITEM_DENY}`, 'error')
    setOpenDialog(true)
  }

  /**
   * Close delete dialogue
   */
  const onCloseModal = () => {
    setOpenDialog(false)
  }

  /**
   * Cancel poll deletion
   */
  const onHandleCancel = () => {
    onCloseModal()
  }

  /**
   * Delete trip handler
   */
  const onHandleDeleteTrip = async () => {
    try {
      const res = await remove({
        user_uid: user.uid,
        short_uid: trip.short_uid,
        coordinates,
      })
      if (res) {
        sendNotification(`${CONSTANTS.TRIP_DELETE_SUCCESS}`, 'success')
        onCloseModal()

        const trips = await getUserTrips()
        // alert(`Trip count --   ${trips?.length || userTripsCount - 1} `)
        await dispatch(setUserTripsCount(trips?.length || userTripsCount - 1))
      }
    } catch (error) {
      const { message } = (await error?.response?.json()) || error

      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  const renderMenu = () => {
    return (
      <>
        {/* <MoreHorizIcon
          onClick={onHandleOpenMenu}
          className={classes.heroMenuButton}
        /> */}
        <HeroMenuButton
          className={classes.heroMenuButton}
          onClick={onHandleOpenMenu}>
          <Icon />
        </HeroMenuButton>
        <Menu
          MenuListProps={{
            style: {
              padding: 0,
            },
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          className={classes.menu}
          anchorEl={menuAnchorEl}
          disableScrollLock
          disableRestoreFocus
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={onHandleCloseMenu}
          elevation={3}>
          <MenuItem onClick={onHandleShowDeleteTripModal}>Delete Trip</MenuItem>
        </Menu>
      </>
    )
  }

  useEffect(() => {
    if (trip && trip.google_place_id) {
      getGooglePlaceInformation({
        place_id: trip.google_place_id,
        setPlaceHeroPhoto,
      })
    }
  }, [setPlaceHeroPhoto, trip])

  return (
    <Card
      className={`${classes.root} ${
        smallScreen && 'mobile-responsive-dashboard-trip-card'
      } `}>
      <Link
        to={{
          pathname: `/workspace/${trip.short_uid}`,
          state: { trip_id: trip.trip_id },
        }}>
        <CardMedia
          src={placeHeroPhoto ? placeHeroPhoto : fallBackImage}
          image={placeHeroPhoto ? placeHeroPhoto : fallBackImage}
          title={destinationName}
          style={{
            height: !smallScreen ? (carousel ? '22rem' : '33rem') : '25rem',
          }}
        />
      </Link>

      <CardContent>
        <div
          className={classes.cardContent}
          style={{
            flexDirection: carousel ? 'column' : 'row',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: !firstTrip ? 'row' : 'column',
            }}>
            <div className={classes.tripInfoLeft}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className={classes.destinationName}
                style={{
                  height: carousel ? '6rem' : 'auto',
                  lineHeight: '1.2',
                }}>
                {destinationName}
              </Typography>

              <div className={classes.destinationDatesContainer}>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.destinationDates}>
                  {startDate} - {endDate}
                </Typography>
              </div>

              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.destinationLastModified}>
                {CONSTANTS.DASHBOARD_LAST_MODIFIED} {lastModified}
              </Typography>
            </div>
            {!firstTrip && <div className={classes.menu}>{renderMenu()}</div>}
          </div>

          <div
            style={{
              display: carousel ? 'inline' : 'flex',
              flexDirection: firstTrip ? 'column' : 'row',
              alignItems: carousel ? 'flex-start' : 'flex-end',
            }}>
            {firstTrip && <div className={classes.menu}>{renderMenu()}</div>}
            <div className={classes.tripInfoRightBtm}>
              {!smallScreen && (
                <div className={classes.members}>{allMembers}</div>
              )}
            </div>
          </div>
        </div>

        {smallScreen && (
          <div className="row">
            <div className={classes.members}>{allMembers}</div>
          </div>
        )}
      </CardContent>

      <MTDialog
        openDialog={openDialog}
        onCloseModal={onCloseModal}
        onDeleteTripClickHandler={onHandleDeleteTrip}
        onCancelClickHandler={onHandleCancel}
        itemToBeDeleted={CONSTANTS.TRIP_TO_BE_DELETED}
        type={modalTypes.DELETE_ITEM}
      />
    </Card>
  )
}

DashboardCard.propTypes = {
  trips: PropTypes.array,
  carousel: PropTypes.bool,
}
export default DashboardCard
