import styled from '@emotion/styled'
import { Container, Typography, Card } from '@material-ui/core'
import { Form } from 'formik'

export const ContainerStyled = styled(Card)`
  width: 40%;
  padding: 2rem;
  border-radius: 12px;
`
export const Title = styled(Typography)`
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 2.9rem;
  margin: 1.3rem 0;
`
export const FormStyled = styled(Form)`
  padding: 1rem 4rem;
`
export const Label = styled.p`
  font-weight: bold;
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
`
export const Row = styled.div`
  margin-top: 1rem;
  width: 100%;
`
export const DatesContainer = styled(Container)`
  display: flex;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  & > div {
    width: 49%;
    border-radius: 12px;
  }
`
export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`
