import styled from '@emotion/styled'

export const LocationItemContainer = styled.div`
  display: grid;
  flex-direction: row;
  grid-column-start: 1;
  grid-colum-nend: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  margin: 5px;
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

export const MapContainer = styled.div`
  overflow: auto;
  height: 20rem;
  min-height: 20rem;
  padding: 5px;
`
