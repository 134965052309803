import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useWindowSize } from 'hooks/useWindowSize'

type CustomToolTipProps = {
  title?: string | string[]
  html?: React.ReactNode
  children: React.ReactNode
  customStyles?: React.CSSProperties
  showonClick?: boolean
}

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#3A3A3A !important',
    color: 'white !important',
    maxWidth: 220,
    minHeight: '36px',
    borderRadius: '8px !important',
    padding: '8px 12px !important',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
}))(Tooltip)

const CustomToolTip = ({
  html,
  children,
  customStyles,
  title,
  showonClick = true
}: CustomToolTipProps) => {
  let tooltipContent
  const {width} = useWindowSize()
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const isMobileDevice = (width < 720)
  const handleClick = e => {
    if(!isMobileDevice ){
      e.preventDefault()
      e.stopPropagation()
      setIsTooltipOpen(true)
    }
    else if(isMobileDevice && showonClick){
      e.preventDefault()
      e.stopPropagation()
      setIsTooltipOpen(true)
    }
  }

  const handleTooltipClose = () => {
    setIsTooltipOpen(false)
  }

  if (title && typeof title === 'string') {
    tooltipContent = (
      <HtmlTooltip
        open={isTooltipOpen}
        onMouseEnter={handleClick}
        onMouseLeave={handleTooltipClose}
        onFocus={handleClick}
        onClick={handleClick}
        title={
          <div>
            <p style={{ fontSize: '12px', margin: 0 }}>{title}</p>
          </div>
        }>
        <div onClick={handleClick}>{children}</div>
      </HtmlTooltip>
    )
  } else if (title && Array.isArray(title)) {
    tooltipContent = (
      <HtmlTooltip
        open={isTooltipOpen}
        onMouseEnter={handleClick}
        onMouseLeave={handleTooltipClose}
        onFocus={handleClick}
        onClick={handleClick}
        title={
          <ul style={{ padding: 0, margin: 0 }} onClick={handleClick}>
            {title.map((t, k) => {
              return (
                <li
                  key={k}
                  style={{ listStyleType: 'none', padding: '5px', margin: 0 }}>
                  <p style={{ fontSize: '12px', margin: '2px' }}>{t}</p>
                </li>
              )
            })}
          </ul>
        }>
        <div onClick={handleClick}>{children}</div>
      </HtmlTooltip>
    )
  } else if (html) {
    tooltipContent = (
      <HtmlTooltip
        title={html}
        open={isTooltipOpen}
        onMouseEnter={handleClick}
        onFocus={handleClick}
        onMouseLeave={handleTooltipClose}
        onClick={handleClick}>
        <div style={{ margin: 0, padding: 0 }} onClick={handleClick}>{children}</div>
      </HtmlTooltip>
    )
  } else {
    tooltipContent = <div>{children}</div> // If no title or html is provided
  }

  return <div style={customStyles} onClick={(e) => e.stopPropagation()}>{tooltipContent}</div>
}

export default CustomToolTip
