import React, { useEffect, useState } from 'react'

import { MTDialog } from 'components/MTDialog'
import { modalTypes } from 'components/MTDialog/types'
import { CONSTANTS } from 'core/constants'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { resetUserStore } from 'store'
import { useGeolocation } from 'store/hooks'
import { sendNotification } from 'utils/toast'

import { remove } from 'api/trip'

const RouteLeavingGuard = ({
  navigate,
  shouldBlockNavigation,
  userUid,
  shortUid,
}) => {
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.user)
  const coordinates = useGeolocation()
  /**
   * Block navigation if user wishes to remain on the same page.
   * @param {*} nextLocation
   * @returns boolean
   */
  const handleBlockedNavigation = nextLocation => {
    if(openDialog){
      return true
    }
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setOpenDialog(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  /**
   * Close dialog and redirect user to signup modal.
   */
  const handleConfirmNavigationClick = () => {
    setOpenDialog(false)
    setConfirmedNavigation(true)
  }

  const onCloseModal = () => {
    setOpenDialog(false)
  }
  /**
   * Delete trip handler
   */
  const onHandleDeleteTrip = async () => {
    try {
      const res = await remove({
        user_uid: userUid,
        short_uid: shortUid,
        is_tentative: user.is_tentative,
        coordinates,
      })

      if (res) {
        sendNotification(`${CONSTANTS.TRIP_DELETE_SUCCESS}`, 'success')
        handleConfirmNavigationClick()
      }
    } catch (error) {
      const { message } = (await error?.response?.json()) || error
      sendNotification(message, 'error')
      console.log('error occured: ', error)
    }
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation?.pathname) {
      navigate(lastLocation.pathname)
      const dropCookies = user?.is_tentative || !user?.firstName
      resetUserStore(dispatch, dropCookies)
    }
  }, [
    confirmedNavigation,
    lastLocation,
    setOpenDialog,
    navigate,
    dispatch,
    user,
  ])

  return (
    <>
      <Prompt when={true} message={handleBlockedNavigation} />
      {openDialog && (
        <MTDialog
          openDialog={openDialog}
          onCloseModal = {onCloseModal}
          onDeleteTripClickHandler={onHandleDeleteTrip}
          itemToBeDeleted={CONSTANTS.TRIP_TO_BE_DELETED}
          type={modalTypes.SIGN_UP_TO_SAVE_TRIP}
          isTripCreator={user?.is_trip_creator}
        />
      )}
    </>
  )
}
export default RouteLeavingGuard
