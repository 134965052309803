import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'

export const PlanningBoardItemStyled = styled.div`
  border-width: 1px;
  border-color: black;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.4, 0, 1, 1);
  margin-bottom: 20px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 12px;
`

export const Body = styled.div`
  padding: 1rem 1rem;
`

export const BodyBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const Title = styled(Typography)`
  font-size: 1.7rem;
  font-weight: bold;
  overflow-wrap: break-word;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.7rem 1.5rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #e1e1e1;
  position: relative;
`
