import React, { useState, useRef } from 'react'

import { makeStyles, Button, Select } from '@material-ui/core'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { CONSTANTS } from 'core/constants'
import itly from 'itly'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { can, TRIP_INVITE_MEMBER_VIEWER } from 'utils/permissions'
import { isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'
import { invite } from 'api/tripMemberInvite'
import { style } from './invitation.style'
import { useWindowSize } from 'hooks/useWindowSize'

const useStyles = makeStyles(theme => style(theme))

const Invitation = ({
  currentTrip,
  setFetchTripOnInvite,
  invitedMembers,
  setInvitedMembers,
}) => {
  const { user } = useSelector(state => state.user)
  const history = useHistory()
  const canInviteViewer = can(
    currentTrip.trips_roles[0].role,
    TRIP_INVITE_MEMBER_VIEWER,
  )
  const { setOpenDialog } = useDialog()
  const coordinates = useAppSelector(state => state.geoLocation)
  const classes = useStyles()
  const invitationFormRef = useRef(null)
  let roles = ['Editor', 'Viewer']
  const [invitationtype, setInvitationType] = useState(roles[0])
  const [email, setEmail] = useState('')
  roles = !canInviteViewer ? roles.slice(1) : roles
  const {width} = useWindowSize()
  let label = 'Insert traveler email..'
  const isScreenSizeEquals320 = isScreenSize(320)

  if (isScreenSizeEquals320) {
    label = 'Insert email...'
  }

  /**
   * Invite someone to a trip as a trip member.
   * @param { email } email
   */
  const handleSendInvite = async ({ email, invitedMemberRole }) => {
    if (user?.is_tentative && user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_INVITE_USER,
      })
    } else if (user?.is_tentative && !user?.is_trip_creator) {
      return setOpenDialog({
        show: true,
        type: modalTypes.SIGN_UP_TO_AS_NON_ADMIN_TO_EDIT_TRIP,
      })
    }

    const currentlyLoggedinUserEmail = currentTrip.trips_roles[0].user_email
    const currentlyLoggedinUserRole = currentTrip.trips_roles[0].role
    const tripAdmin = currentTrip.trips_members.filter(
      mem => mem.role === 'ADMIN',
    )
    const memberInviteObject = {
      user_uid: user.uid,
      short_uid: currentTrip.short_uid,
      invited_member_email: email,
      invited_member_role: invitedMemberRole,
      inviting_trip_member_user_role: currentlyLoggedinUserRole,
      inviting_trip_member_name: `${user.firstName} ${user.lastName}`,
      trip_admin_email: tripAdmin[0].user_email,
      is_tentative: user?.is_tentative,
      coordinates,
    }

    // prevent from inviting yourself
    if (email === currentlyLoggedinUserEmail) {
      return sendNotification(
        CONSTANTS.COLLABORATORS_MODAL_INVITATION_TO_SELF_ERROR,
        'error',
      )
      // return false
    }

    const tripMembers = currentTrip.trips_members.filter(
      member => member.email !== currentlyLoggedinUserEmail,
    )

    const memberAlreadyPartOfTrip = tripMembers.some(
      member => member.user_email === email,
    )

    // prevent from inviting anyone else who is part of the trip
    if (memberAlreadyPartOfTrip) {
      return sendNotification(
        CONSTANTS.COLLABORATORS_MODAL_MEMBER_ALREADY_PART_OF_TRIP_ERROR,
        'error',
      )
    }

    const memberAlreadyInvited = invitedMembers.filter(m => m.email === email)
    // prevent from inviting anyone else who is part of the trip
    if (memberAlreadyInvited.length > 0) {
      return sendNotification(
        CONSTANTS.COLLABORATORS_MODAL_MEMBER_ALREADY_INVITED_ERROR,
        'error',
      )
    }

    const handlePurchasePlanClick = () => {
      sessionStorage.setItem('scrollToPayment', 'true')
      toast.remove()
      history.push('/profile') // Navigate to the purchase plan page
    }

    try {
      const res = await invite(memberInviteObject)
      if (res.success) {
        itly.userInvitedToTrip({
          type: invitedMemberRole.toUpperCase(),
        })
        sendNotification(CONSTANTS.TRIP_MEMBER_INVITATION_SUCCESS, 'success')
      }
      setFetchTripOnInvite(true)
      setEmail('')
      setInvitedMembers([
        ...invitedMembers,
        { email, role: invitedMemberRole, trip_uid: currentTrip.uid },
      ])
    } catch (error) {
      const { message } = (await error?.response?.json()) || error
      if (message && message === CONSTANTS.PURCHASE__PLAN_TO_ADD_EDITORS) {
        sendNotification(
          <span>
            Please{' '}
            <span
              style={{
                color: '#0d6efd',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={handlePurchasePlanClick}>
              purchase plan
            </span>{' '}
            to add more Editors!
          </span>,
          'error',
        )
      } else {
        sendNotification(message, 'error')
      }
      console.log('error occured: ', error)
    }
  }

  return (
    <div className={classes.container} data-testid="invitation-container">
      <div className={classes.formElement}>
        <span className={classes.dropdownLabel}>
          {CONSTANTS.COLLABORATORS_MODAL_INVITE_TRAVEL_TO_BE}
        </span>
        <select
          data-testid="invitation-role-select"
          disableUnderline={true}
          native
          name="invitedMemberRole"
          className={classes.select}
          classes={{
            select: classes.select,
          }}
          onChange={e => setInvitationType(e.target.value)}
          inputProps={{ disabled: canInviteViewer ? true : false }}
          value={invitationtype}>
          {roles.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div className={classes.emailInputContainer}>
        <form
          ref={invitationFormRef}
          className = {classes.emailInputContainerForm}
          onSubmit={async e => {
            e.stopPropagation()
            e.preventDefault()
            await handleSendInvite({
              invitedMemberRole: invitationtype,
              email,
            })
          }}>
          <input
            type="email"
            required
            style={width < 700 ? { fontSize: '1.3rem' } : {}}
            placeholder={'Insert traveler\'s email address...'}
            value={email}
            className={classes.emailInputForm}
            onChange={e => setEmail(e.target.value)}
          />
          <input
            type="submit"
            style={width < 700 ? { fontSize: '1.3rem' } : {}}
            className={classes.submitBtn}
            value={CONSTANTS.COLLABORATORS_MODAL_SEND_BUTTON}
          />
        </form>
      </div>
    </div>
  )
}

export default Invitation
