import styled from '@emotion/styled'
import { cssVariables } from 'theme'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-size: 3.9rem;
  font-weight: bold;
  text-align: center;
  color: ${cssVariables.colors.secondaryDarkGrey};
`

export const ErrorMessage = styled.p`
  width: 75%;
`

export const ErrorLogo = styled.img`
  width: 40%;
`
