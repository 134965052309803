/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/named */
import React, { useState, Fragment } from 'react'

import { CircularProgress } from '@material-ui/core'
import {
  FormikProps,
  FormikTouched,
  FormikErrors,
  FieldInputProps,
} from 'formik'
import { isUndefined } from 'lodash'
import { LatLng } from 'pages/CreateTrip/layouts/CreateTripCard/CreateTripCard'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'

import {
  SearchBarInput,
  SuggestionsContainer,
  SuggestionsItem,
  LoadingSpinner,
  LocationOnOutlinedIconStyled,
} from './googlePlacesSearchBar.style'

type GooglePlacesSearchBarProps = {
  inputPlaceholder: string
  field: FieldInputProps<any>
  form: FormikProps<any>
  errors: FormikErrors<any>
  touched: FormikTouched<any>
}
const GooglePlacesSearchBar = ({
  inputPlaceholder,
  field,
  form,
  errors,
  touched,
  ...props
}: GooglePlacesSearchBarProps) => {
  const [search, setSearch] = useState('')
  const { setFieldValue } = form

  /**
   * Select place suggestion from drop down.
   * @param {Array} userInput selected suggestion
   */
  const handleSelect = async (userInput: string) => {
    const result = await geocodeByAddress(userInput)
    const { place_id, geometry, formatted_address } = result[0]
    const destination = formatted_address
    const lat = geometry.location.lat()
    const lng = geometry.location.lng()
    const { bounds } = geometry

    const lngDiff = 0.159558449999997265
    const latDiff = 0.15083600000000104

    let swBounds: LatLng = [0, 0]
    let neBounds: LatLng = [0, 0]

    if (isUndefined(bounds)) {
      swBounds = [lat - lngDiff, lng - lngDiff]
      neBounds = [lat + latDiff, lng + latDiff]
    } else {
      const NELat = bounds.getNorthEast().lat()
      const NELng = bounds.getNorthEast().lng()

      const SWLat = bounds.getSouthWest().lat()
      const SWLng = bounds.getSouthWest().lng()

      swBounds = [SWLat, SWLng]
      neBounds = [NELat, NELng]
    }

    const data = {
      destination,
      place_id,
      lat,
      lng,
      sw_bounds: swBounds,
      ne_bounds: neBounds,
    }

    setFieldValue('googleSearchQuery', data)
    setSearch(destination)
  }

  /**
   * Search value.
   * @param {string} search search value string
   */
  const handleChange = (search: string) => {
    setSearch(search)
  }

  return (
    <Fragment>
      <PlacesAutocomplete
        value={search}
        onChange={handleChange}
        onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <SearchBarInput
              {...field}
              {...props}
              {...getInputProps({ placeholder: inputPlaceholder })}
            />
            <SuggestionsContainer>
              {loading && (
                <LoadingSpinner>
                  <CircularProgress />
                </LoadingSpinner>
              )}
              {suggestions.map(suggestion => (
                <Fragment key={suggestion.placeId}>
                  {suggestion && suggestion.placeId && (
                    <SuggestionsItem {...getSuggestionItemProps(suggestion)}>
                      <LocationOnOutlinedIconStyled />
                      {suggestion.description}
                    </SuggestionsItem>
                  )}
                </Fragment>
              ))}
            </SuggestionsContainer>
          </div>
        )}
      </PlacesAutocomplete>
      {errors.googleSearchQuery && touched.googleSearchQuery ? (
        <span style={{ color: 'red', marginLeft: '10px' }}>
          {errors.googleSearchQuery}
        </span>
      ) : null}
    </Fragment>
  )
}

export default GooglePlacesSearchBar
