/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { PreviewTags } from 'pages/Workspace/layouts/PlanningBoard/layouts/ItemPopup/layouts/PopupTags/components/PreviewTags'
import { UpdateGridItemProps } from 'pages/Workspace/layouts/PlanningBoard/types'

import { TripLocationItem } from './layouts/TripLocationItem'
import { TripNoteItem } from './layouts/TripNoteItem'
import { TripPollItem } from './layouts/TripPollItem'

type Props = {
  itemType: string
  tripItem: any
  docURL: string
  tripMembersCount: number
  onUpdateGridItem: ({ data, key }: UpdateGridItemProps) => void
}
export const determineTripItemComponent = ({
  itemType,
  tripMembersCount,
  tripItem,
  docURL,
  onUpdateGridItem,
}: Props) => {
  let component
  switch (itemType) {
    case 'location':
      component = <TripLocationItem data={tripItem} docURL={docURL} />
      break
    case 'note':
      component = <TripNoteItem data={tripItem} docURL={docURL} />
      break
    case 'poll':
      component = (
        <TripPollItem
          tripItem={tripItem}
          pollsOptions={tripItem.trip_poll_item_options}
          tripMembersCount={tripMembersCount}
          onUpdateGridItem={onUpdateGridItem}
        />
      )
      break
  }

  return component
}

export const determineTripItemTags = (item: any) => {
  let tripItemTags = []

  if (item) {
    switch (item.type) {
      case 'location':
        tripItemTags = item.trips_locations_items_tags
        break
      case 'note':
        tripItemTags = item.trips_notes_items_tags
        break
      case 'poll':
        tripItemTags = item.trips_polls_items_tags
        break
    }

    //tripItemTags.sort((a: any, b: any) => a.id - b.id)
    const tags = <PreviewTags tags={tripItemTags} />

    return tags
  }
}
