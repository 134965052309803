import React from 'react'

import { isEmpty } from 'lodash'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setEndingIndex as stateSetEndingIndex } from 'store/slices/locationSearchResults'
import { tabletMobileScreenSize } from 'utils/screenSzie'

import { GridItem } from '../GridItem'
import { Container, LoadMoreButton } from './searchResults.style'

type Props = {
  tripShortUid: string
  tripStartDate: string
  tripEndDate: string
  searchedTitle: string
  tripName: string
  tripDestination: string
}

const SearchResults = ({
  tripShortUid,
  tripStartDate,
  tripEndDate,
  searchedTitle,
  tripName,
  tripDestination,
}: Props) => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(state => state.locationSearchResults)
  const { places, endingIndex } = state
  const tabletMobileView = tabletMobileScreenSize()

  let inlineStyle = {}
  if (tabletMobileView) {
    inlineStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '10px',
      justifyContent: 'space-between',
      marginRight: 'unset',
    }
  }
  /**
   * Load more search results. Increments the index in the redux store
   * and returns it to the UI.
   */
  const onLoadMorePlaces = () => {
    dispatch(stateSetEndingIndex())
  }

  return (
    <>
      <Container style={inlineStyle}>
        {places &&
          places.length > 0 &&
          places
            .slice(0, endingIndex)
            .map(place => (
              <GridItem
                key={place.place_id}
                place={place}
                tripShortUid={tripShortUid}
                tripStartDate={tripStartDate}
                tripEndDate={tripEndDate}
                searchedTitle={searchedTitle}
                tripName={tripName}
                tripDestination={tripDestination}
              />
            ))}
      </Container>
      <LoadMoreButton
        style={{ display: !isEmpty(places) ? 'block' : 'none' }}
        variant="contained"
        disabled={places.length === endingIndex}
        onClick={onLoadMorePlaces}>
        Load More..
      </LoadMoreButton>
    </>
  )
}

export default SearchResults
