import React from 'react'

import { ListItem, CloseIcon } from './tag.style'

type Props = {
  tag: string
  removeTags?: (tag: string) => void
  immutable?: boolean
  displayColor? : string
}

const Tag = ({ tag, removeTags, immutable = false, displayColor = ''}: Props) => {
  return (
    <ListItem>
      {
        displayColor && <div style={{ height: '10px', width: '10px', backgroundColor: displayColor, borderRadius: '3px' }}/>
      }
      <div>{tag}</div>
      {!immutable && (
        <CloseIcon
          className={'material-icons'}
          onClick={() => {
            if (removeTags) removeTags(tag)
          }}>
          close
        </CloseIcon>
      )}
    </ListItem>
  )
}

export default Tag
