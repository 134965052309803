import React, { useState } from 'react'

import { MTButton } from 'components/MTButton'
import { MTLogo } from 'components/MTLogo'
import { StartTripButton } from 'components/StartTripButton'
import { CONSTANTS } from 'core/constants'
import { useQuery } from 'hooks/useQuery'
import { isNull } from 'lodash'
import { useAppSelector } from 'store/hooks'
import { cssVariables } from 'theme'
import { isScreenSize } from 'utils/screenSzie'

import { UserIcon } from 'components/MTIcons'

import { NavAuthMenu } from './NavAuthMenu'
import {
  AppBarStyled,
  ToolbarStyled,
  ToolbarItemLogo,
  ToolbarButtonGroup,
  AuthButtonGroup,
} from './navBar.style'
import { useHistory } from 'react-router'

const NavBar = () => {
  const query = useQuery()
  const history = useHistory()
  const { user } = useAppSelector(state => state.user)

  let planNewTripText = CONSTANTS.START_TRIP
  const smallScreen = isScreenSize(600)
  const showUserIcon = !isScreenSize(320)
  const isScreenSizeEquals350 = isScreenSize(350)
  const isScreenSizeEquals310 = isScreenSize(310)
  if (isScreenSizeEquals350) {
    planNewTripText = 'New Trip'
  }
  if (isScreenSizeEquals310) {
    planNewTripText = 'Plan'
  }

  return (
    <AppBarStyled position="absolute" elevation={0}>
      <ToolbarStyled style={smallScreen ? { paddingRight: '0px' } : {}}>
        <ToolbarItemLogo style={smallScreen ? { marginLeft: '0px' } : {}}>
          <MTLogo />
        </ToolbarItemLogo>
        <ToolbarButtonGroup>
          {!isNull(user?.username) && <NavAuthMenu />}
          {(isNull(user?.firstName) || user?.is_tentative) && (
            <AuthButtonGroup>
              <MTButton
                title="Login"
                onHandleClick={() => history.push('/signin')}
                customStyles={{
                  color: cssVariables.colors.secondaryDarkGrey,
                  boxShadow: 'none',
                  backgroundColor: 'white',
                  width: 'auto',
                }}
              />
              <MTButton
                title="Sign Up"
                customIcon={showUserIcon && <UserIcon stroke="#4184FF" />}
                onHandleClick={() => history.push('/signup')}
                customStyles={{
                  backgroundColor: '#ECF3FF',
                  color: cssVariables.colors.primaryBlue,
                  boxShadow: 'none',
                  width: 'auto',
                }}
              />
            </AuthButtonGroup>
          )}

          {(!window.location.href.includes('/create-trip')
            && !window.location.href.includes('/signin')
            && !window.location.href.includes('/signup')
            && !window.location.href.includes('/forgot-password')
            && !window.location.href.includes('/reset_password'))
            && !user?.is_tentative && (
              <StartTripButton
                title={planNewTripText}
                textCol="white"
                width="auto"
              />
            )}
        </ToolbarButtonGroup>
      </ToolbarStyled>
    </AppBarStyled>
  )
}

export default NavBar
