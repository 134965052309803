import React, { useEffect, useState } from 'react'

import { Grid, LinearProgress } from '@material-ui/core'
import { modalTypes } from 'components/MTDialog/types'
import { useDialog } from 'contexts/DialogContext'
import { CONSTANTS } from 'core/constants'
import { Formik } from 'formik'
import { useQuery } from 'hooks/useQuery'
import itly from 'itly'
import { HTTPError } from 'ky'
import { TermsOfService } from 'layouts/Auth/components/TermsOfService'
import { isEmpty } from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useAppSelector } from 'store/hooks'
import { tabletMobileScreenSize, isScreenSize } from 'utils/screenSzie'
import { sendNotification } from 'utils/toast'

import { register } from 'api/auth'

import {
  SignInForm,
  SignInInput,
  SignInHeader,
  Title,
  SubTitle,
  FormStyled,
  Label,
  ErrorField,
  SignUpFooter,
  SignUpButtonLink,
  InputStyled,
  SubmitButtonStyled,
} from '../AuthStyles'
import { HeardAboutUs } from './HeardAboutUs'
import { signUpValidationSchema } from './helper'
import { useHistory } from 'react-router'


type SignUpDataObject = {
  firstName: string
  lastName: string
  email: string
  username: string
  password: string
  heard_about_us: string
}

const SignUp = () => {
  const query = useQuery()
  const history = useHistory()
  const { setOpenDialog } = useDialog()
  const [platform, setPlatform] = useState('Web')
  const { user } = useAppSelector(state => state.user)
  let fontSize = '3rem'
  const smallScreen = isScreenSize(650)
  const smallScreenEquals900 = isScreenSize(900)
  if (smallScreenEquals900) {
    fontSize = '2rem'
  }

  const tabletMobileViewGeneral = tabletMobileScreenSize()
  const coordinates = useAppSelector(state => state.geoLocation)

  /**
   * Register a new user
   * @param { SignUpDataObject } userInfo
   */
  const handleRegister = async (userInfo: SignUpDataObject) => {
    console.log({user})
    try {
      if (user?.is_tentative) {
        userInfo = {
          ...userInfo,
          ...{ is_tentative: user?.is_tentative ?? 'false', uid: user?.uid },
        }
      }

      itly.userHeardAboutUsFromWhere({
        from: userInfo.heard_about_us,
      })

      const signUpPayload = {
        coordinates,
        ...userInfo,
      }
      const res = await register(signUpPayload)
      if (res) {
        if (!user?.is_tentative) {
          itly.userCreated({
            is_tentative: false,
            user_uid: res?.uid,
          })
        }

        itly.userRegistered({
          is_tentative: !!user.is_tentative,
          user_uid: user?.uid ? user?.uid : undefined,
        })
        setOpenDialog({
          show: true,
          type: modalTypes.ACTIVATE_ACCOUNT,
        })
        history.push('/signin')
      }
    } catch (error) {
      const res = await (error as HTTPError)?.response?.json()
      const errorMsg = !isEmpty(res) ? res.message : CONSTANTS.ERROR_GENERAL
      sendNotification(errorMsg, 'error')
    }
  }

  useEffect(() => {
    // Handle redirection if user is already logged in
    if (user.username) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    mixpanel.track('Sign Up Started', {
      Platform: platform,
      $latitude: coordinates?.latitude,
      $longitude: coordinates?.longitude,
    })
  }, [user.activated, user.uid, platform])

  useEffect(() => {
    const { userAgent } = navigator

    if (userAgent.includes('Android')) {
      setPlatform('Android')
    } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      setPlatform('iOS')
    } else {
      setPlatform('Web')
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100%',
      }}>
      <SignInForm
        style={{ width: '100%', maxWidth: '1400px', height: 'fit-content' }}>
        <SignInInput>
          <SignInHeader>
            <Title style={{ fontSize }}>{CONSTANTS.SIGN_UP_HEADER_TITLE}</Title>
            <SubTitle>{CONSTANTS.SIGN_UP_HEADER_SUBTITLE}</SubTitle>
          </SignInHeader>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              username: '',
              heard_about_us: '',
            }}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={signUpValidationSchema}
            onSubmit={async ({
              firstName,
              lastName,
              email,
              password,
              username,
              heard_about_us,
            }) => {
              await handleRegister({
                firstName,
                lastName,
                email,
                password,
                username,
                heard_about_us,
              })
            }}>
            {({ isSubmitting, errors, setFieldValue, handleChange }) => (
              <FormStyled noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Label>First Name</Label>
                    <InputStyled
                      name="firstName"
                      required
                      autoFocus={!tabletMobileViewGeneral}
                    />
                    <ErrorField>{errors.firstName}</ErrorField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Label>Last Name</Label>
                    <InputStyled required name="lastName" />
                    <ErrorField>{errors.lastName}</ErrorField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Label>Email</Label>
                    <InputStyled required name="email" type="email" />
                    <ErrorField>{errors.email}</ErrorField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Label>Username</Label>
                    <InputStyled required name="username" type="username" />
                    <ErrorField>{errors.username}</ErrorField>
                  </Grid>
                  <br />
                  <Grid item xs={12} sm={12}>
                    <Label>Password</Label>
                    <InputStyled required type="password" name="password" />
                    <ErrorField>{errors.password}</ErrorField>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Label>How did you hear about us</Label>
                    <HeardAboutUs
                      formikKey="heard_about_us"
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      errors={errors}
                    />
                  </Grid>
                  {isSubmitting && <LinearProgress />}
                  <SubmitButtonStyled disabled={isSubmitting}>
                    {CONSTANTS.SIGN_UP}
                  </SubmitButtonStyled>
                </Grid>
              </FormStyled>
            )}
          </Formik>
          <SignUpFooter>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <SignUpButtonLink onClick={() => history.push('/signin')}>
                  {CONSTANTS.ALREADY_HAVE_ACCOUNT_SIGN_IN}
                </SignUpButtonLink>
              </Grid>
            </Grid>
          </SignUpFooter>
        </SignInInput>
        <TermsOfService />
      </SignInForm>
    </div>
  )
}

export default SignUp
