import styled from '@emotion/styled'
import { Field } from 'formik'
import { motion } from 'framer-motion'


type ContainerProps = {
  tabletMobileView: boolean
}


export const Container = styled(motion.div)<ContainerProps>`
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  max-width: ${props => (props.tabletMobileView ? '100vw' : '70vw')};
  align-items: start;
  row-gap: 15px;
`
export const Spinner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const TripGroup = styled(Field)`
  margin-left: auto;
  border-radius: 7px 0px 0px 7px;
  font-size: 1.4rem;
  width: 120px;
  height: 35px;
  margin-top: 9px;
  //margin-right: 5px;
  border: none;
  &:focus {
    outline-width: 0;
  }

  background: #f9f9f9;
  > option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
  > option:first-child {
    border: 2px solid red;
  }
`

export const TripGroupLocation = styled.select`
  //border-radius: 10px;
  border-radius: 7px 0px 0px 7px;
  font-size: 1.4rem;
  width: 50%;
  height: 29px;
  margin-top: 10px;
  margin-right: 22px;
  border: none;
  &:focus {
    outline-width: 0;
  }

  &:hover {
    background-color: #f3faff;
  }
  > option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`

export const TripGroupLocationOnMaps = styled.select`
  //border-radius: 10px;
  margin-left: auto;

  border-radius: 7px 0px 0px 7px;
  font-size: 1.4rem;
  width: 30%;
  height: 29px;
  margin-top: 10px;
  margin-right: 0;
  border: none;
  &:focus {
    outline-width: 0;
  }

  &:hover {
    background-color: #f3faff;
  }
  > option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`
export const BoardContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 5px;
`
