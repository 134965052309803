export const styles = () => {
  return {
    root: {
      margin: '2em auto',
      boxShadow: '0px 10px 5px 2px rgba(0,0,0,0.02)',
      borderRadius: '20px',
      transition: 'all 0.2s ease-out',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0px 10px 14px 2px rgba(0,0,0,0.07)',
      },
    },
    media: {
      height: 354,
      cursor: 'pointer',
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tripInfo: {
      display: 'flex',
      flexDirection: 'row',
    },
    tripInfoLeft: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    destinationName: {
      fontSize: '2.2rem',
      fontWeight: 'bold',
    },
    destinationDatesContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '1rem',
    },
    destinationDates: {
      fontSize: '1.8rem',
    },
    destinationStatus: {
      paddingLeft: '4rem',
      fontSize: '1.5rem',
    },
    destinationLastModified: {
      fontSize: '1.2rem',
      marginTop: '1rem',
    },
    members: {
      display: 'flex',
      padding: '1em 0.5em 0 0',
      '& > div': {
        margin: '0 .2rem 0 0',
      },
    },
    menu: {
      cursor: 'pointer',
    },
  }
}
